/* ==========================================================================
   #LOGOS
   ========================================================================== */

/**
 * A Place to keep and size all your logos according to where they are being
 * displayed.
 */

.c-logo {
  display: inline-block;

  img,
  .c-svg {
    width: 100%;
    height: 100%;
  }

}


.c-logo--resmed {
  width:  var(--aura-c-logo-resmed-width-mobile, 65px);
  height: var(--aura-c-logo-resmed-height-mobile, 49px);
  
  @include mq($from: tablet) {
    width:  var(--aura-c-logo-resmed-width-tablet, 91px);
    height: var(--aura-c-logo-resmed-height-tablet, 68px);
  }

  .page-footer & {
    width:  var(--aura-c-logo-resmed-width-footer, 52px);
    height: var(--aura-c-logo-resmed-height-footer, 40px);

    svg {
      fill: $color-white;
    }
  }

}