/* ==========================================================================
  #SECTIONS
  ========================================================================== */

$o-section-mobile-top-spacing:      $spacing-large !default;
$o-section-mobile-bottom-spacing:   $spacing-large !default;
$o-section-top-spacing:             $spacing-large + $spacing-small !default;
$o-section-bottom-spacing:          $spacing-large + $spacing-small !default;

$o-section-divider-border-color:    $color-border !default;
$o-section-divider-border-size:     1px !default;
// Spacing between content and the bottom border
$o-section-divider-mobile-border-buffer:   $spacing-default !default;
$o-section-divider-border-buffer:          $spacing-large !default;

$o-section-home-top-spacing:        $spacing-large !default;
$o-section-home-bottom-spacing:     $spacing-large !default;

$o-section-tiny-spacing:            $spacing-tiny !default;
$o-section-small-spacing:           $spacing-small !default;
$o-section-large-spacing:           $spacing-large !default;
$o-section-huge-spacing:            $spacing-xx-large !default;


/* Use the `o-section` object to define the content area of a page, or to break
 * the page up into multiple sections.

  <div class="o-wrapper">

    <section class="o-section o-section--divider">
        [...]
    </section>

    <section class="o-section">
        [...]
    </section>

  </div>

 * If your page design requires a background color to span the full width of the
 * viewport, add a `.u-fill--` color class to the `.o-wrapper` element.


    <div class="o-wrapper u-fill--neutral-ulight">
      <section class="o-section">
        [...]
      </section>
    </div>

 *
 */

/**
 * 1. Mobile first top padding and bottom margin for your section. Try to apply
 *    margins in one direction only.
 *    See: https://csswizardry.com/2012/06/single-direction-margin-declarations/
 * 2. Clear any floats that might be in the section.
 * 3. Nice big top and bottom padding @tablet and above.
 */

.o-section {
  position: relative;
  padding-top:    var(--aura-o-section-mobile-block-start, $o-section-mobile-top-spacing); /* [1] */
  padding-bottom: var(--aura-o-section-mobile-block-end, $o-section-mobile-bottom-spacing); /* [1] */

  background-color: var(--aura-color-background, $color-background);
  @include clearfix(); /* [2] */


  @include mq($from: tablet) {
    padding-top:    var(--aura-o-section-block-start, $o-section-top-spacing); /* [3] */
    padding-bottom: var(--aura-o-section-block-end, $o-section-bottom-spacing); /* [3] */

  }


  &.has-side-nav {

    @include mq($until: tablet) {
      padding-top: var(--aura-o-section-has-side-nav-block-start, 0);
    }

  }

}





/* Content dividers
   ========================================================================== */
/**
 * Section divider with a horizontal bottom border that stretches past the
 * default left and right padding of the `.o-wrapper` object. Use only when
 * `o-section` is inside of an `.o-wrapper` object.
 *
 * 1. Push the container outside of the wrapper padding to extend the top border.
 * 2. Add left and right padding to bring the content back in line with the page.
 * 3. Add bottom padding to separate the content from the bottom border.
 */
.o-section--divider {
  padding-bottom: $o-section-divider-mobile-border-buffer; /* [3] */
  border-bottom:  $o-section-divider-border-size solid $o-section-divider-border-color;
  margin-bottom: $o-section-mobile-bottom-spacing;

  @include mq($from: tablet) {
    padding-bottom: $o-section-divider-border-buffer;
    margin-bottom: $o-section-bottom-spacing;
  }

  &:last-of-type {
    margin-bottom: 0;
    border-bottom: 0;
  }


  @include mq($from: x-wide) {
    /**
    * Wrapper size variations - change the horizontal spacing based on the wrapper
    * element.
    */
    .o-wrapper & {
      margin-right: -$spacing-default; /* [1] */
      margin-left:  -$spacing-default; /* [1] */
      padding-right: $spacing-default; /* [2] */
      padding-left:  $spacing-default; /* [2] */
    }

    .o-wrapper--tiny & {
      margin-right: -$spacing-tiny;
      margin-left:  -$spacing-tiny;
      padding-right: $spacing-tiny;
      padding-left:  $spacing-tiny;
    }

    .o-wrapper--small & {
      margin-right: -$spacing-small;
      margin-left:  -$spacing-small;
      padding-right: $spacing-small;
      padding-left:  $spacing-small;
    }

    .o-wrapper--large & {
      margin-right: -$spacing-large;
      margin-left:  -$spacing-large;
      padding-right: $spacing-large;
      padding-left:  $spacing-large;
    }

    .o-wrapper--huge & {
      margin-right: -$spacing-xx-large;
      margin-left:  -$spacing-xx-large;
      padding-right: $spacing-xx-large;
      padding-left:  $spacing-xx-large;
    }
  }
}



// Add a top border to a section at mobile screen sizes.
.o-section--mobile {
  border-top: $o-section-divider-border-size solid $o-section-divider-border-color;

  @include mq($from: tablet) {
      border: none;
  }

}


.o-section--home {

  @include mq($from: desktop) {
    padding-top:   $o-section-home-top-spacing;
    padding-bottom: $o-section-home-bottom-spacing;
  }

}


.o-section--admin {
  padding-top: $spacing-default;
  padding-bottom: $spacing-large;
}

.o-section--secondary {
  padding-top: $spacing-large;
  padding-bottom: $spacing-large;
  background-color: $color-background-alt;
  border-top-style: solid;
  border-top-width:  $border-width-thin;
  border-top-color: var(--aura-color-border, $color-border);
}


.o-section--back-btn-bottom {
  margin-top:  $o-section-divider-mobile-border-buffer;
  padding-top: $o-section-divider-mobile-border-buffer;
  padding-bottom: 0;
  border-top:  $o-section-divider-border-size solid $o-section-divider-border-color;
}



/* Size variants
   ========================================================================== */
.o-section--flush {
  padding-top:   0;
  padding-bottom: 0;
}

.o-section--flush-top {
  padding-top: 0;
}

.o-section--flush-bottom {
  padding-bottom: 0;
}

.o-section--tiny {
  padding-top:   $o-section-tiny-spacing;
  padding-bottom: $o-section-tiny-spacing;
}

.o-section--small {
  padding-top:   $o-section-small-spacing;
  padding-bottom: $o-section-small-spacing;
}

.o-section--large {
  padding-top:   $o-section-large-spacing;
  padding-bottom: $o-section-large-spacing;
}

.o-section--huge {
  padding-top:   $o-section-huge-spacing;
  padding-bottom: $o-section-huge-spacing;
}


/* Decoration variants
   ========================================================================== */
.o-section--border-bottom {
  margin-bottom: $o-section-mobile-bottom-spacing;
  padding-bottom: 0;

  > .o-wrapper {
    border-bottom:  $o-section-divider-border-size solid $o-section-divider-border-color;
    padding-bottom: $o-section-divider-mobile-border-buffer;
  }

  @include mq($from: tablet) {
    margin-bottom: $o-section-bottom-spacing;

    > .o-wrapper {
      padding-bottom: $o-section-divider-border-buffer;
    }
  }

}
