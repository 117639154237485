/* ========================================================================
   Shadow
   ======================================================================== */
/**
 * Shadow tokens

  type: box-shadow
  category: shadow
  cssProperties:
  - box-shadow

 */

/**
 * ATTENTION
 *
 * Usage:
 * Until the design token pipeline is in place, find the file
 *  `_themes.custom-tokens.scss` 
 * in the themes folder and rename it to 
 *  `_themes.custom-{product-name}-tokens.scss`
 *
 * You will use this file to override !default token values as needed.
 */


/**
 * DO NOT EDIT ANYTHING BELOW 
 * Override default token values in YOUR custom tokens file.
 */

$shadow-default:    0 2px 5px  rgba($resmed-brand-gray-palette-200, 0.6) !default;
$shadow-hover:      0 4px 15px rgba($resmed-brand-gray-palette-400, 0.6) !default;

$page-head-shadow:  0 3px 8px 1px rgba(0,0,0, 0.2) !default;
$shadow-drop-down:  0 1px 16px -3px rgba(0,0,0, 0.3) !default;

$card-shadow:       $shadow-default !default;
$card-shadow-hover: $shadow-hover !default;

$button-shadow-size:                        3px !default;
$button-shadow-opacity:                     $opacity-3 !default;
$button-shadow-focus:                       0 0 0 $button-shadow-size rgba($color-background-primary, $button-shadow-opacity) !default;
$button-outline-inset-shadow-hover:         0 0 0 $button-border-width $color-background-primary-hover inset !default;
$button-neutral-shadow:                     0 0 0 $button-shadow-size rgba($button-neutral-color-background, $button-shadow-opacity) !default;
$button-outline-neutral-inset-shadow-hover: 0 0 0 $button-border-width $button-neutral-color-background-hover inset !default;
$button-inverse-shadow:                     0 0 0 $button-shadow-size rgba($button-inverse-color-background, $button-shadow-opacity) !default;
$button-outline-inverse-inset-shadow-hover: 0 0 0 $button-border-width $button-outline-inverse-color-border-hover inset !default;

$pagination-shadow-focus: 0 0 0 $button-shadow-size $resmed-brand-gray-palette-300 !default;

$popover-shadow:          0px 6px 26px -6px rgba(0, 0, 0, 0.3) !default;
$tooltip-shadow:          0px 3px 12px -1px rgba(0, 0, 0, 0.6) !default;
$tooltip-inverse-shadow:  0px 3px 12px -1px rgba(255, 255, 255, 0.3) !default;
