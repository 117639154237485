/* ==========================================================================
   #FOOTER
   ========================================================================== */
// Site Footer


/** Private framework-only tokens **/
$c-footer-padding-top:      $spacing-default !default;
$c-footer-padding-bottom:   $spacing-default !default;

/**
 * 1. By default, the text is centered (mobile first).
 * 2. Reset some defaults for text elements we might use in the footer
 * 3. Line up the footer text with the ResMed logo in this particular layout.
 */
.page-footer {
  padding-top:      var(--aura-c-footer-padding-block-start, $c-footer-padding-top);
  padding-right:    var(--aura-c-footer-padding-inline-end, 0);
  padding-bottom:   var(--aura-c-footer-padding-block-end, $c-footer-padding-bottom);
  padding-left:     var(--aura-c-footer-padding-inline-start, 0);
  background-color: var(--aura-c-footer-color-background, $footer-color-background);
  text-align: center; /* [1] */


  /* stylelint-disable selector-list-comma-newline-after */
  p {
    margin-bottom: 0; /* [2] */
  }

  p, ul, li {
    color: var(--aura-c-footer-text-color, $footer-color-text); /* [2] */
  }

  p, a, ul, li {
    @include font-size($font-size-footer); /* [2] */
    font-weight: var(--aura-c-footer-font-weight, $font-weight-footer);
  }

  /* stylelint-enable selector-list-comma-newline-after */

  a {

    @include all-anchor-states() {
      color: var(--aura-c-footer-link-text-color, $footer-color-link-text); /* [2] */
    }

    &:hover {
      text-decoration: none;  /* [2] */
    }

  }

  @include mq($from: tablet) {
    text-align: left;

    .o-layout__item {
      vertical-align: bottom; /* [3] */
      margin-bottom: 0; /* [3] */
      line-height: 1; /* [3] */
    }

  }

}


.page-footer__copyright {
  padding-bottom: $spacing-default;
  text-align: center;

  @include mq($from: tablet) {
    padding-top: 0;
    padding-bottom: 0;
    text-align: right;
  }

  p span {
    @include mq($until: tablet) {
      display: block;
    }
  }

}




/* Footer link list
   ========================================================================== */
.page-footer__links {
  padding-top:    var(--aura-c-footer-links-padding-block-start-mobile, $c-footer-padding-top);
  padding-bottom: var(--aura-c-footer-links-padding-block-end-mobile, $c-footer-padding-bottom);
  text-align: center;

  @include mq($from: desktop) {
    padding-top:    var(--aura-c-footer-links-padding-block-start, 0);
    padding-bottom: var(--aura-c-footer-links-padding-block-end, 0);
    text-align: left;
  }

  &.page-footer__links-item:first-child {

    @include mq($from: tablet) {
      margin-left: 0;
    }

  }

}


.page-footer__links-item {
  display: inline-block;
  margin-left:  var(--aura-c-footer-links-spacing-inline-start-mobile, $spacing-tiny);
  margin-right: var(--aura-c-footer-links-spacing-inline-end-mobile, $spacing-tiny);
  
  @include mq($from: tablet) {
    margin-left:  var(--aura-c-footer-links-spacing-inline-start-tablet, $spacing-small);
    margin-right: var(--aura-c-footer-links-spacing-inline-end-tablet, $spacing-small);
  }

  @include mq($from: desktop) {
    margin-left:  var(--aura-c-footer-links-spacing-inline-start, 0);
    margin-right: var(--aura-c-footer-links-spacing-inline-end, $spacing-default);
    margin-bottom: 0;
  }

}



/* Variant
   ========================================================================== */
/**
 * 1. Override the max-width on the `.o-wrapper` element so the footer will span
 *    the width of the viewport while keeping the outer gutters.
 * 2. Keep the ouside gutters in case it is being removed on the `.o-wrapper` 
 *    element at x-wide breakpoint.
 */
.page-footer--full {

  .o-wrapper {
    width: 100% !important;
    max-width: 100% !important; /* [1] */
    padding-right: var(--aura-c-footer-full-padding-inline-end, $spacing-default); /* [2] */
    padding-left:  var(--aura-c-footer-full-padding-inline-start, $spacing-default); /* [2] */
  }

}

.page-footer--border-top {
  border-top-style: solid;
  border-top-width: var(--aura-c-page-footer-border-width, $page-footer-border-width);
  border-top-color: var(--aura-c-page-footer-border-color, $page-footer-color-border);
}
