/* ==========================================================================
  #TYPOGRAPHY
  ========================================================================== */
@use 'sass:math';
// Set the default fonts to be used on specific elements;
// Font variables found in settings.typography

/* Page
   ========================================================================== */
/**
 *
 * 1. Set the default `font-size` and `line-height` for the entire project,
 *    sourced from our default variables. The `font-size` is calculated to exist
 *    in ems, the `line-height` is calculated to exist unitlessly.
 */

html {
  font-size: math.div($font-size-body, 16px) * 1em; /* [1] */
  line-height: math.div($global-line-height, $font-size-body); /* [1] */
}

html,
body {
  font-family: var(--aura-font-family, $font-family);
  font-weight: var(--aura-font-weight-body, $font-weight-body);
  color: var(--aura-color-text-default, $color-text-default);
}



/* Headings
   ========================================================================== */
/* stylelint-disable selector-list-comma-newline-after */
h1, h2, h3, h4, h5, h6 {

  &, > * {
    font-family: var(--aura-font-family-heading, $font-family-heading);
    color: var(--aura-color-text-default, $color-text-default);
    text-rendering: optimizeLegibility;
  }
  
}
/* stylelint-enable selector-list-comma-newline-after */


h1 {

  &, > * {
    @include font-size($font-size-h1);
    font-weight: var(--aura-font-weight-h1, $font-weight-h1);
  }
 
}

h2 {

  &, > * {
    @include font-size($font-size-h2);
    font-weight: var(--aura-font-weight-h2, $font-weight-h2);
  }
 
}

h3 {

  &, > * {
    @include font-size($font-size-h3);
    font-weight: var(--aura-font-weight-h3, $font-weight-h3);
  }
 
}

h4 {

  &, > * {
    @include font-size($font-size-h4);
    font-weight: var(--aura-font-weight-h4, $font-weight-h4);
  }

}

h5 {

  &, > * {
    @include font-size($font-size-h5);
    font-weight: var(--aura-font-weight-h5, $font-weight-h5);
  }

}


h6 {

  &, > * {
    @include font-size($font-size-h6);
    font-weight: var(--aura-font-weight-h6, $font-weight-h6);
  }

}


/* Text elements
   ========================================================================== */

b,
strong {
  font-weight: var(--aura-strong-font-weight, $font-weight-bold);
}


a {
  transition: $text-color-transition;
  text-decoration: none;

  &:link {
    color: var(--aura-color-text-link, $color-text-link);
  }

  &:visited {
    color: var(--aura-color-text-visited, $color-text-link-visited);
  }

  &:hover {
    color: var(--aura-color-text-hover, $color-text-link-hover);
    text-decoration: underline;
  }

  &:active {
    color: var(--aura-color-text-active, $color-text-link-active);
  }

  &:hover,
  &:active {
    cursor: pointer;
  }

}


cite {
  display: inline-block;
  margin: var(--aura-cite-margin-block, $spacing-small) 0;
  padding: var(--aura-cite-padding-inline, $spacing-tiny);
  border-radius: $border-radius-small;
  color: var(--aura-cite-text-color, $color-text-dark);
  @include font-size($font-size-caption, 1);
  font-weight: $font-weight-caption;
  font-style: italic;
  background-color: var(--aura-cite-color-background, $color-background-gray-lighter);
}

// In-browser text highlighting
@include selection {
  background-color: var(--aura-color-background-selection, $color-background-selection);
  color: var(--aura-color-text-selection, $color-text-selection);
  text-shadow: none;
}


// Override /generic/_generic.shared.scss and user-agent defaults
address,
fieldset {
  font: inherit;
  margin-bottom: 0;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}

caption {
  @include font-size($font-size-caption);
  font-weight: $font-weight-caption;
}

code {
  font-family: $font-family-monospace;
}