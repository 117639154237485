/* ==========================================================================
   #HEADINGS
   ========================================================================== */

$heading-page-margin-block-end-mobile:    $spacing-large !default;
$heading-page-margin-block-end:           $spacing-large + $spacing-small !default;


/**
 * Define some heading styles to modify `h1` through `h6` elements.
 *
 * Related files:
 * `settings/settings.typography.scss`
 * `elements/elements.typography.scss`
 */


[class^="c-heading-"],
[class*=" c-heading-"] {
  margin-bottom: var(--aura-c-heading-margin-block-end, $spacing-default);
  color: var(--aura-c-heading-text-color, currentColor);
  font-weight: var(--aura-c-heading-font-weight, $font-weight-normal);
}


// The page's title, typically your h1.
.c-heading-page {
  margin-bottom: var(--aura-c-heading-page-margin-block-end-mobile, $heading-page-margin-block-end-mobile);
  @include font-size($font-size-page-heading);
  color: var(--aura-c-heading-page-text-color, currentColor);
  
  @include mq($from: tablet) {
    margin-bottom: var(--aura-c-heading-page-margin-block-end, $heading-page-margin-block-end);
  }

}


// // Heading for a section of content within a page.
// .c-heading-main {
//   color: $c-heading-main-color;
//   font-weight: $font-weight-bold;
//   line-height: 1.15;
// }


// .c-heading-section {
//   color: $c-heading-section-color;
//   font-weight: $font-weight-medium;
//   @include font-size(24px);
// }

// .c-heading-parent {
//   margin-bottom: $spacing-tiny;
//   text-transform: uppercase;
//   @include font-size(12px);
// }

// .c-heading-icon {
//   position: relative;
//   margin: 0;
//   text-align: center;
//   color: $c-heading-page-color;

//   .c-svg {
//       display: block;
//       margin: 0 auto;
//       @include font-size(100px);
//       color: $color-black;
//   }

// }




/**
 * A sub-heading that might appear under a `c-heading-main` heading.
 *
 * 1. Add extra space between the previous section of content.
 */
.c-heading-sub {
  padding-top: $spacing-large; /* [1] */
}


// On forms, using <legend> as heading in a <fieldset>
.c-heading-legend {
  @include font-size($font-size-h3);
}



/* Heading variants
   ========================================================================== */

.c-heading--top-border,
.c-heading--bottom-border {
  border-style: solid;
  border-color: var(--aura-c-heading-color-border, $color-border);
  border-width: 0;
}

// Add a top border to your heading to separate it from the content above.
.c-heading--top-border {
  padding-top: $spacing-small;
  border-top-width: var(--aura-c-heading-border-width, $border-width-thin);
}

// Add a bottom border to your heading to separate it from the content below.
.c-heading--bottom-border {
  padding-bottom: $spacing-small;
  border-bottom-width: var(--aura-c-heading-border-width, $border-width-thin);
}

// When the viewport is wider than the max-width, extend the border beyond
// the edge of the column into the gutter.
.c-heading--extend-border {
  @include mq($from: x-wide) {
    margin-left:  -$spacing-default;
    margin-right: -$spacing-default;
    padding-left:  $spacing-default;
    padding-right: $spacing-default;
  }

  @if ($remove-gutter == true) {
    @include mq($from: x-wide) {
      padding-right: $spacing-default;
      padding-left:  $spacing-default;
    }
  }

}



/* Text and labels
  ========================================================================== */
/**
 * Text and label modifiers
 */
.c-text-intro {
  @include font-size($font-size-body + 4, $modifier: +.75);

  @include mq($from: desktop) {
    @include font-size($font-size-body + 4, $modifier: +2);
  }

}


.c-text-greyed-out {
  font-style: italic;
  color: $color-text-disabled;
}


// Large body text.
.c-text-preamble {
  @include font-size($font-size-larger, 1.6);
  margin-bottom: $spacing-large;
}

.c-text--small {
  @include font-size($font-size-small, 2);
}


/** 
 * Use in tables. Table layout property must be set to `fixed` for this to work.
 * Use <colgroup><col width="{x}%"> [...]</colgroup> to set column widths when using
 * a fixed table layout.
 *
 * HTML:

    // Must total 100%
    <colgroup>
      <col width="10%">
      <col width="35%">
      <col width="25%">
      <col width="30%">
    </colgroup> 

    // Truncate text
    <th class="" scope="col">
      <div class="c-text-truncate" title="Heading text">Heading text</div>
    </th>

    <td data-label="Heading text">
      <div class="c-text-truncate" title="Cell text">Cell text</div>
    </td>
 */
.c-text-truncate {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  
  // Force all text in this container to truncate.
  &.no-wrap {
    white-space: nowrap;
  }
}


/* Links
  ========================================================================== */
// A text link in the UI that should not have a visited state
.c-link-ui {
  font-weight: var(--aura-c-link-ui-font-weight, $font-weight-medium);
  
  &, 
  &:link,
  &:visited {
    color: var(--aura-color-text-link, $color-text-link) !important;
  }

  &:hover {
    color: var(--aura-color-text-hover, $color-text-link-hover);
  }

  &:active {
    color: var(--aura-color-text-active, $color-text-link-active);
  }

}