/* ==========================================================================
  #LISTS
  ========================================================================== */
/**
 * Layouts using <ul> and <li> elements and the o-layout based @mixin
 *  o-layout-list($gutter: $spacing-default, $vertical-alignment: top, $item-width: 100%)
 */

.o-list-inline {
  @include o-layout-list($item-width: auto);
}

.o-listbox-inline {
  @include o-layout-list($spacing-tiny, middle, $item-width: auto);
}


/* Vertical alignment modifiers
   ========================================================================== */

/**
 * Align all grid items to the middles of each other.
 */
.o-list--middle {
  li {
    vertical-align: middle;
  }
}
  
  
/**
 * Align all grid items to the bottoms of each other.
 */
.o-list--bottom {
  li {
    vertical-align: bottom;
  }
}



/* Variants
   ========================================================================== */
.o-list--reverse {
  direction: rtl;

  > li {
    direction: ltr;
  }
}

.o-list--spacing-bottom {
  li {
    margin-bottom: $spacing-default;
  }
}