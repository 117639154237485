/* ==========================================================================
   #CAROUSELS
   ========================================================================== */

// Overriding some settings from the slick-theme.scss
$hero-slick-dot-color:           $color-white !default;
$hero-slick-dot-color-active:    $color-background-primary-hover !default;


/**
 * Extending and modifying the carousel layout.
 *
 * Related files:
 * `/vendors/slick.scss`, `/vendors/slick-theme.scss`, `/js/slick.min.js`
 *
 *
 * Some standard style overrides you may want to apply to all your
 * `o-carousel` components.
 */
.o-carousel {
  position: relative;
  opacity: 0;
  visibility: hidden;
  transition: $opacity-transition;
  will-change: opacity;

  &.slick-initialized {
    visibility: visible;
    opacity: 1;
  }

}



// Put this on the `li` in your `ul.slides` list.
.o-carousel__item {
  position: relative;
}




/* Carousel variants
   ========================================================================== */
/* Hero area carousel - example */
.o-carousel--hero {

  &.slick-dotted.slick-slider {
    margin-bottom: 0px;
  }

  .slick-dots {
    bottom: 8px;
  }


  .slick-dots {
    @include set-svg-bg-css-var("slick-dot-icon", $svg-slick-dot-character, $hero-slick-dot-color);
    @include set-svg-bg-css-var("slick-dot-icon-active", $svg-slick-dot-character, $hero-slick-dot-color-active);

    li {

      button {

        &::before {
          @include svg-css-var-bg-img("slick-dot-icon");
        }

      }

      &.slick-active button:before {
        @include svg-css-var-bg-img("slick-dot-icon-active");
      }

    }

  }

}





/**
 * A thumbnail style carousel, where you see more then one slide
 * at a time and page through the "groups" of slides.
 */
.o-carousel--thumbs {
  width: auto;
  margin: 0;
  margin-left:  -$spacing-default;
  margin-right: -$spacing-default;
  opacity: 0;
  visibility: hidden;
  transition: $opacity-transition;
  will-change: opacity;

  .o-wrapper--tips-history &,
  &.slick-initialized {
    visibility: visible;
    opacity: 1;
  }

  @include mq($from: tablet) {
    margin-left: 0;
    margin-right: 0;
  }

  .slick-list {

    @include mq($from: tablet) {
      margin-left: -$spacing-default;
    }

    @if ($use-markup-fix == false) {
      font-size: 0;
    }

  }

  .slick-track {
    margin-left:  0;
    margin-right: 0;
  }


  .o-carousel__item {
    box-sizing: border-box;
    display: inline-block;
    vertical-align: top;
    width: 100%;
    
    @include mq($from: tablet) {
      padding-left: $spacing-default;
    }

    @if ($use-markup-fix == false) {
      font-size: $font-size-body;
      font-size: 1rem;
      line-height: initial;
    }

  }

  .slick-prev,
  .slick-next {
    height: 32px;
    width:  32px;
    z-index: 100;

    @include mq($from: desktop) {
      height: 42px;
      width:  42px;
      @include translate(0, -70%);
    }

  }

  .slick-prev {
    @include mq($until: desktop) {
      left: $spacing-small;
    }

    @include mq($from: desktop) {
      left: -72px;
    }

  }

  .slick-next {
    @include mq($until: desktop) {
      right: $spacing-small;
    }

    @include mq($from: desktop) {
      right: -72px;
    }

  }

}