/* ==========================================================================
   #THEME - CUSTOM AURA CSS
   ========================================================================== */
/**
 * This file contains bits of custom CSS and AuraCSS overrides.
 */

.form-stepper {
  position: relative;
  width: 100%;
  height: 800px;
}

.form-row-delete-btn {
  padding-top: $spacing-medium;
  line-height: 1;
}

.form-stepper__item {
  position: absolute;

  &,
  form {
    @include display-flex();
    width: 100%;
    min-height: 100%;
    flex-grow: 1;
    @include flex-direction-column();
  }

  .c-form-section {
    position: relative;
    width: 100%;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    -webkit-flex: 1 0 auto;
  }

  .c-form-buttons {
    -webkit-box-flex: 0;
    -webkit-flex: none;
    -ms-flex: none;
    flex: none;
  }
}



// Karl stuff
.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.flex-row {
  flex-direction: row;
}

.flex-gap-5 {
  gap: 5px;
}

.flex-gap-10 {
  gap: 10px;
}

.flex-gap-11 {
  gap: 11px;
}

.flex-gap-15 {
  gap: 15px;
}

.flex-gap-30 {
  gap: 30px;
}

.flex-gap-50 {
  gap: 50px;
}

.flex-gap-60 {
  gap: 60px;
}

.flex-gap-200 {
  gap: 200px;
}

.flex-gap-24 {
  gap: 24px;
}

.flex-gap-150 {
  gap: 150px;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.flex-start {
  justify-content: start;
  align-items: center;
}

.flex-space {
  justify-content: space-between;
  align-items: center;
}

.flex-space-start {
  justify-content: space-between;
  align-items: start;
}

.flex-wrap {
  flex-wrap: wrap;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.no-margin {
  margin: 0px!important;
}

.bold {
  font-weight: 600;
}

.divider-line-50 {
  width: 0px;
  height: 50px;
  border: 1px solid #B7B7B8;
}

.divider-line-20 {
  width: 0px;
  height: 20px;
  border: 1px solid #B7B7B8;
}

.divider-line-15 {
  width: 0px;
  height: 15px;
  border: 1px solid #424243;
}

.flex-space {
  justify-content: space-between;
  align-items: center;
}

html {
  overflow-y: hidden;
}

.c-input--search {
  @include set-svg-bg-css-var("search-field-icon", $svg-search-icon, $color-text-default);
  .c-input {
    --aura-c-form-input-padding-inline-start: #{$spacing-medium};
  }
  &::before {
    @include svg-css-var-bg-img("search-field-icon");
    @include position-y-center();
    left:  4px;
    width:  var(--aura-c-form-input-search-icon-size, $icon-size-default);
    height: var(--aura-c-form-input-search-icon-size, $icon-size-default);
    z-index: 0;
  }
}

.pointer {
  cursor: pointer;
}

.svg-top {
  top: 1px;
}

.nowrap {
  white-space: nowrap;
}

.no-border {
  border-style: initial!important;
}

.display-none {
  display: none;
}

.margin-top-30 {
  margin-top: 30px;
}


.margin-top-14 {
  margin-top: 14px;
}

.margin-top-4 {
  margin-top: 4px;
}

.margin-top-12 {
  margin-top: 12px;
}

.margin-top-90 {
  margin-top: 90px;
}

.margin-bottom-25 {
  margin-bottom: 25px;
}
.w-50p {
  width: 50%;
}

.modal-title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #424243;
}

.modal-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #424243;
}

header.page-head--sticky  {
  z-index: 1;
}

body {
  font-family: var(--aura-font-family-heading, "Open Sans", "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif)!important;
}

.w-200 {
  width: 200px;
}