/* ========================================================================
   #FONT-FAMILY
   ======================================================================== */
/**
 * Tokens for font-family.

  category: font
  type: font
  cssProperties:
  - 'font'
  - 'font-family'

 */

/**
 * ATTENTION
 *
 * Usage:
 * Until the design token pipeline is in place, find the file
 *  `_themes.custom-tokens.scss`
 * in the themes folder and rename it to
 *  `_themes.custom-{product-name}-tokens.scss`
 *
 * You will use this file to override !default token values as needed.
 */


/**
 * DO NOT EDIT ANYTHING BELOW
 * Override default token values in YOUR custom tokens file.
 */

/* Font-family tokens
   ========================================================================== */
$font-family:               $font-family-opensans !default;
$font-family-heading:       $font-family-opensans !default;
// Use @import for Google fonts rather then loading the font files locally.
$font-family-import:        true !default;


// :root {
//   --aura-font-family:           #{$font-family-opensans};
//   --aura-font-family-heading:   #{$font-family-opensans};
//   --aura-font-family-import:    true;
// }
