/* ========================================================================
   #FONT-WEIGHT
   ======================================================================== */
/**
 * Tokens for font-weights.

  category: font
  type: font-weight
  cssProperties:
  - font
  - font-weight

 */

/**
 * ATTENTION
 *
 * Usage:
 * Until the design token pipeline is in place, find the file
 *  `_themes.custom-tokens.scss` 
 * in the themes folder and rename it to 
 *  `_themes.custom-{product-name}-tokens.scss`
 *
 * You will use this file to override !default token values as needed.
 */


/**
 * DO NOT EDIT ANYTHING BELOW 
 * Override default token values in YOUR custom tokens file.
 */
$font-weight-thin:          100 !default; // Cern Ultra Light
$font-weight-light:         300 !default; // Cern Light
$font-weight-normal:        400 !default;
$font-weight-medium:        500 !default; // Cern Medium or normal - default
$font-weight-semi-bold:     600 !default; // Nicer for Open Sans bolding
$font-weight-bold:          700 !default; // Cern Bold
$font-weight-extra:         800 !default;
$font-weight-black:         900 !default;

// Heading font weights
$font-weight-h1:            $font-weight-medium !default;
$font-weight-h2:            $font-weight-medium !default;
$font-weight-h3:            $font-weight-semi-bold !default;
$font-weight-h4:            $font-weight-semi-bold !default;
$font-weight-h5:            $font-weight-medium !default;
$font-weight-h6:            $font-weight-normal !default;

// UI font weights
$font-weight-site-nav:      $font-weight-medium !default;
$font-weight-body:          $font-weight-normal !default;
$font-weight-button:        $font-weight-semi-bold !default;
$font-weight-tooltip:       $font-weight-medium !default;
$font-weight-form-label:    $font-weight-medium !default;
$font-weight-form-hint:     $font-weight-normal !default;
$font-weight-form-error:    $font-weight-medium !default;
$font-weight-small:         $font-weight-light !default;
$font-weight-badge:         $font-weight-semi-bold !default;
$font-weight-caption:       $font-weight-medium !default;
$font-weight-chip:          $font-weight-semi-bold !default;
$font-weight-footer:        $font-weight-medium !default;
$font-weight-toast:         $font-weight-normal !default;
$font-weight-card-heading:  $font-weight-medium !default;
$font-weight-pagination:    $font-weight-medium !default;
$font-weight-pagination-current:  $font-weight-bold !default;
