/* ==========================================================================
   #PAGE
   ========================================================================== */

/**
 * 1. Ensure the page always fills at least the entire height of the viewport.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 */
html {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100%; /* [1] */
  overflow: -moz-scrollbars-vertical;
  overflow-y: scroll; /* [2] */
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(0,0,0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  background-color: var(--aura-html-color-background, $color-background);


  @if ($aura-theme-is-responsive == true) {
    overflow-x: hidden;
  } @else {

    @include mq($from: $aura-theme-page-width) {
      overflow-x: hidden;
    }

  }

}


body {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: var(--aura-body-color-background, transparent);
  // -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none;

  // Reposition the sass-mq show-breakpoint element
  // so it appears in a layer above the page-head.
  &::before {
    z-index: 1099;
  }

  // If the site is not responsive, set a min-width for the site
  // below the static breakpoint.
  @if ($aura-theme-is-responsive != true) {
    // $static-bp: $aura-theme-page-width - 1;
    $static-bp: calc(var(--aura-theme-page-width - 1));
    @include mq($until: $static-bp) {
      min-width: var(--aura-theme-page-width, $aura-theme-page-width);
    }
  }

  // Prevent vertical scrolling, like when a modal is open.
  // Applied to body via JS.
  &.scroll-lock {
    overflow: hidden;
    height: 100vh;
  }
}


// If we need to hide a page while it's loading
/*
.body__page-loading {
  display: none;
}

.body__page-isLoaded {
  @include animation-name(aniStayHidden, aniFadeIn);
  @include animation-duration(250ms, 250ms);
  @include animation-delay(0s, 250ms);
  @include animation-timing-function(linear, ease-out);
  @include animation-fill-mode(forwards);
  @include animation-iteration-count(1);
}

*/
