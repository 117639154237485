/* ==========================================================================
   #ICON BUTTONS
   ========================================================================== */

// $c-icon-size:                 $icon-size-default !default;
$c-icon-size-large:           $icon-size-large !default;
$c-icon-default-color:        $color-text-default !default;
$c-icon-hover-color:          $color-black !default;

$c-close-btn-color-text:              $color-text-default !default;
$c-close-btn-color-text-hover:        $color-black !default;
$c-close-btn-color-background-hover:  $color-text-light-secondary !default;
$c-close-btn-color-text-inverse:      $color-white !default;

/**
 * Icon buttons are just that, an icon which acts as buttons. For example,
 * the close button on a modal window, or the play button over a video thumbnail.
 *
 */
.c-icon-btn {
  position: relative;
  display: inline-block;
  width:  var(--aura-c-icon-btn-icon-size, $icon-size-default);
  height: var(--aura-c-icon-btn-icon-size, $icon-size-default);
  margin: 0;
  padding: 0;
  border: none;
  line-height: 0;
  background-color: transparent;
  cursor: pointer;
  @include appearance(none);

  @include all-anchor-states() {
    text-decoration: none;
  }

  &:disabled {
    transition: none;

    &,
    &:hover {
      cursor: default;
      background-image: none;
      border: 0;
      opacity: $opacity-disabled;
    }

  }

  // Use `.c-svg-hover-swap` on parent element if swapping between two svg icons on hover
  .c-svg {
    &,
    &.c-svg--baseline {
      @include position-center();
      width:  100%;
      height: 100%;
    }
  }

}


// Button with icon and text
// .c-icon-btn--label {

//   @if ($use-markup-fix == false) {
//     font-size: 0;
//   }

//   > * {
//     display: inline-block;
//     box-sizing: border-box;
//     vertical-align: middle;

//     @if ($use-markup-fix == false) {
//         @include font-size($font-size-body, 0);
//     }

//   }

// }

//   .c-icon-btn__icon {
//     position: relative;
//     margin-right: $spacing-small;
//     width:  $c-icon-size;
//     height: $c-icon-size;
//   }

//   .c-icon-btn__label {
//     text-align: left;
//   }


// Add this class to a input/submit button wrapped in a `.c-icon-btn` container.
// .c-icon-btn-input {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   border: none;
//   background: none;
//   cursor: pointer;
//   z-index: 100;
// }


// Play button overlaid on video thumbnails.
.c-icon-btn--play {
  width:  80px;
  height: 80px;
  text-align: center;
  color: $color-white;

  .c-svg {
    width:  100%;
    height: 100%;
    cursor: pointer;
    fill: currentColor;
    transition: filter 250ms ease-out;

    &:hover {
      background-color: transparent;
      -webkit-filter: url(#drop-shadow);
      filter: url(#drop-shadow);
    }

  }

}


// Action button icons, have the background circle hover effect
.c-icon-btn--action {
  border-radius: $border-radius-circle;
  transition: $text-color-transition;
  z-index: $z-index-default;

  &,
  &:link,
  &:visited {
    color: $color-text-icon-default;
  }

  &:hover,
  &:active,
  &:focus {
    color: $color-text-icon-hover;
  }

  &::before {
    content: '';
    display: block;
    opacity: 0;
    position: absolute;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(0.4,0.0,0.2,1);
    z-index: -1;
    bottom: -6px;
    left:   -6px;
    right:  -6px;
    top:    -6px;
    background: none;
    border-radius: $border-radius-circle;
    box-sizing: border-box;
    transform: scale(0);
    transition-property: transform, opacity;
  }

  &:hover,
  &:focus,
  &:active {

    &::before {
      background-color: rgba(32,33,36,0.059);
      border: none;
      box-shadow: none;
      opacity: 1;
      transform: scale(1);
    }

  }

  &:focus {
    &,
    &::before {
      outline: none;
    }
  }

  &:active:before {
    border-width: $border-width-thin;
    border-style: solid;
    border-color: $resmed-brand-gray-palette-300;
  }
}



/**
 * 'X' type button for closing windows and overlays
 *
 * HTML

    <button class="c-icon-btn c-icon-btn--close js-close-btn">
      <svg class="c-svg c-svg--close-24"><use xlink:href="#c-svg--close-24"></use></svg>
    </button>

  Inverse - white X on dark background

    <button class="c-icon-btn c-icon-btn--close is-inverse js-close-btn">
      <svg class="c-svg c-svg--close-24"><use xlink:href="#c-svg--close-24"></use></svg>
    </button>

 */
.c-icon-btn--close {
  z-index: 0;

  &,
  &:link,
  &:visited {
    color: inherit; // $c-close-btn-color;
  }

  &::before {
    content: '';
    display: block;
    opacity: 0;
    position: absolute;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(0.4,0.0,0.2,1);
    z-index: -1;
    bottom:  0;
    left:    0;
    right:   0;
    top:     0;
    background: none;
    border-radius: 50%;
    box-sizing: border-box;
    transform: scale(0);
    transition-property: transform,opacity;
  }

  &:hover,
  &:active,
  &:focus,
  &:focus-within {
    color: $c-close-btn-color-text-hover !important;

    &::before {
      background-color: $c-close-btn-color-background-hover;
      border: none;
      box-shadow: none;
      opacity: 1;
      transform: scale(1);
    }

  }

  &:focus,
  &:focus-within {
    &,
    &::before {
      outline: none;
    }
  }

  &:active::before {
    border-width: $border-width-thin;
    border-style: solid;
    border-color: $resmed-brand-gray-palette-300;
  }

}


// 'X' type button for deleting an item, table row
.c-icon-btn--delete {
  border-radius: 50%;
  transition: $text-color-transition;

  .c-tooltip &,
  &:not(.c-svg-hover-swap) {
    &,
    &:link,
    &:visited {
      color: $color-text-default;
    }

    &:hover,
    &:active,
    &:focus {
      color: $color-text-danger;
    }
  }

  // Deprecating... use `.c-icon-btn--action` as well for this hover effect
  &.delete-table-row {
    z-index: 0;

    &::before {
      content: '';
      display: block;
      opacity: 0;
      position: absolute;
      transition-duration: .15s;
      transition-timing-function: cubic-bezier(0.4,0.0,0.2,1);
      z-index: -1;
      bottom: -6px;
      left:   -6px;
      right:  -6px;
      top:    -6px;
      background: none;
      border-radius: 50%;
      box-sizing: border-box;
      transform: scale(0);
      transition-property: transform, opacity;
    }

    &:hover,
    &:focus,
    &:active {

      &::before {
        background-color: rgba(32,33,36,0.059);
        border: none;
        box-shadow: none;
        opacity: 1;
        transform: scale(1);
      }

    }

    &:focus {
      &,
      &::before {
        outline: none;
      }
    }

    &:active:before {
      border-width: $border-width-thin;
      border-style: solid;
      border-color: $resmed-brand-gray-palette-300;
    }

  }

}


// Ratings widget thumbs up/down
.c-icon-btn-thumbs {
  position: relative;
  width:  $icon-size-default;
  height: $icon-size-default;
  margin: 0;
  padding: 0;
  float: left;
  border: none;
  background-color: transparent;
  cursor: pointer;
}


/**
 * Back to Top - requires JS, find showBackToTop();
 *
 * HTML:

    <a class="c-icon-btn-top-link [ js-scrollToElem ]" href="#top" data-state="is-hidden" role="button">
      <svg class="c-svg c-svg--top-circle-outline-24"><use xlink:href="#c-svg--top-circle-outline-24"></use></svg>
      <span class="u-sr-only">Back to top</span>
    </a>

 */
.c-icon-btn-top-link {
  // @include position-sticky(true);
  position: fixed !important;
  bottom: 160px;
  // left: 100%;
  right: $spacing-small;
  display: inline-flex;
  margin: 0;
  padding: 0;
  width:  $c-icon-size-large;
  height: $c-icon-size-large;
  line-height: 0;
  background-color: rgba($color-white, 0.8);
  border-radius: $border-radius-circle;
  border: 1px solid rgba($color-white, 0.8);
  cursor: pointer;
  align-items: center;
  justify-content: center;
  transition: all .25s ease-in-out;

  @include mq($from: desktop) {
    right: $spacing-default;
  }

  &.show,
  &[data-state="is-visible"] {
    visibility: visible;
    opacity: 1;
    transition: opacity .25s ease-in-out;
    will-change: opacity;
  }

  &.hide,
  &[data-state="is-hidden"] {
    visibility: hidden;
    opacity: 0;
  }

  .c-svg {
    @include position-center();
    width:  100%;
    height: 100%;
    transition: opacity .25s ease-in-out, color .25s ease-in-out;
    will-change: color;
    color: $c-icon-default-color;

    @include hocus() {
      color: $c-icon-hover-color;
    }

  }

  // @include mq($from: x-wide) {
  //   @include translateX(54px);
  // }

  // For stupid IE - this class works with .Net
  &.sticky-top-ie {
    position: fixed;
    left: auto;
    right: 24px;
    bottom: 160px;

    // @include mq($from: x-wide) {
    //   @include translateX(0);
    // }

  }

}
