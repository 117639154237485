/* ==========================================================================
   #SITENAV
   ========================================================================== */
@import "tokens";

/**
 * Site navigation
 * @selector .site-nav
 * @restrict nav [role="navigation"]
 *
 * Navigation control for any type of site navigation. Use modifier classes for
 * Main, Tabs, or Stacked navigation.
 */
.site-nav {
  position: relative;
  @include clearfix();
}

.site-nav__branding {
  @include display-flex();
  margin: 0;
  margin-right: var(--aura-c-site-nav-branding-spacing-inline, $site-nav-branding-spacing-inline);
  flex-grow: var(--aura-c-site-nav-branding-flex-grow, $site-nav-branding-flex-grow);
  align-items: var(--aura-c-site-nav-align-items, $site-nav-align-items);
  @include clearfix();
}

.site-nav__home-link {
  @include display-flex();
  align-items: var(--aura-c-site-nav-align-items, $site-nav-align-items);
  
  @include all-anchor-states() {
    color: var(--aura-c-site-nav-text-color-link, $site-nav-text-color-link);
    text-decoration: none;
  }

  // @include mq($from: tablet) {
  //   margin-right: $spacing-large;
  //   // height: var(--aura-c-site-nav-min-height, $site-nav-min-height);
  // }

  + .site-nav__home-link {
    margin-left: var(--aura-c-site-nav-logo-spacing-inline-mobile, $site-nav-logo-spacing-inline-mobile);
    
    @include mq($from: tablet) {
      margin-left: var(--aura-c-site-nav-logo-spacing-inline, $site-nav-logo-spacing-inline);
    }
  }

}


.site-nav__logo {
  display: inline-block;
  
  // `h1`
  .site-nav__title {
    position: relative;
    margin: 0;
    @include font-size($site-nav-title-font-size, $site-nav-min-height);
    font-weight: var(--aura-c-site-nav-title-font-weight, $site-nav-title-font-weight);
    color: var(--aura-c-site-nav-text-color-link, $site-nav-text-color-link);

    &.show-divider {

      &::before {
        position: absolute;
        @include position-center();
        left: calc(#{var(--aura-c-site-nav-logo-spacing-inline-mobile, $site-nav-logo-spacing-inline-mobile) / 2} * -1);
        width: var(--aura-border-width-thin, $border-width-thin);
        height: 50%;
        border-left: var(--aura-border-width-thin, $border-width-thin) solid var(--aura-c-site-nav-text-color-link, $site-nav-text-color-link);
        content: "";
        opacity: 0.87;
      }

      @include mq($from: tablet) {
        &::before {
          left: calc(#{var(--aura-c-site-nav-logo-spacing-inline, $site-nav-logo-spacing-inline) / 2} * -1);
        }
      }
      
    }

  }

  + .site-nav__logo {
    margin-left: var(--aura-c-site-nav-logo-spacing-inline-mobile, $site-nav-logo-spacing-inline-mobile);
    
    @include mq($from: tablet) {
      margin-left: var(--aura-c-site-nav-logo-spacing-inline, $site-nav-logo-spacing-inline);
    }
  }

}




/**
 * The mobile menu toggle
 * 1. Mobile menu icon - hamburger
 * 2. Hide it from non-mobile screens
 */
.site-nav__open {
  position: absolute;
  display: block;
  top: 50%;
  right: 0;
  width:  30px;
  height: 25px;
  @include translateY(-50%);

  @include mq($from: tablet) {
    display: none; /* [2] */
  }

  span {
    display: block;
    position: absolute;
    left: 0;
    height: 2px;
    width: 100%;
    background: var(--aura-c-site-nav-mobile-toggle-color, $site-nav-mobile-toggle-color);
    border-radius: 0;
    opacity: 1;
    @include rotate(0);
    transition: .25s ease-in-out;

    &:nth-child(1) {
      top: 0px;
      @include transform-origin(left center);
    }

    &:nth-child(2) {
      top: 10px;
      @include transform-origin(left center);
    }

    &:nth-child(3) {
      top: 20px;
      @include transform-origin(left center);
    }

  }

  &:target {

    span {

      &:nth-child(1) {
        @include rotate(45);
        top: 10px;
        left: 0px;
      }

      &:nth-child(2) {
        width: 0%;
        opacity: 0;
      }

      &:nth-child(3) {
        @include rotate(-45);
        top: 10px;
        left: 0px;
      }

    }

  }

}


// Trigger for the off-canvas mobile menu
.site-nav__target {
  position: fixed;
  top: 0;
  left: 0;

  &:target+.site-nav__list {
    @include transform(none);
  }

}

// The list of menu items
.site-nav__list {
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
  @include clearfix();
}

// List item in the nav
.site-nav__item {

  @include mq($from: tablet) {
    position: relative;
    text-align: center;
    height: 100%;
  }

}

// Link item in the nav
.site-nav__link {
  position: relative;
  display: block;
  font-weight: var(--aura-c-font-weight-site-nav, $font-weight-site-nav);

  @include all-anchor-states() {
    text-decoration: none;
  }

}

// Close mobile menu list
.site-nav__item--close {
  text-align: right;
}

.site-nav__close {
  display: inline-block;
  line-height: 0;
  
  &,
  &:hover,
  &:focus {
    text-decoration: none;
  }
  
  .c-svg {
    top: 0;
    width:  var(--aura-c-site-nav-close-button-sizing, $site-nav-close-button-sizing);
    height: var(--aura-c-site-nav-close-button-sizing, $site-nav-close-button-sizing);
  }

}

.site-nav__close,
.site-nav__item--close {
  @include mq($from: tablet) {
    display: none;
  }
}


// Menu dropdown
.site-nav__sub-menu {
  list-style: none;
  margin-left: 0;
  margin-bottom: 0;

  @include mq($until: tablet) {
    margin-bottom: $spacing-small;
  }

  @include mq($from: tablet) {
    position: absolute;
    top: 100%;
    left: var(--aura-c-site-nav-submenu-left-position, $site-nav-submenu-left-position);
    @include translateY(-18px);
    z-index: 1;
    width: auto;
    min-width: $site-nav-submenu-min-width;
    max-width: $site-nav-submenu-max-width;
    padding: $spacing-tiny 0;
    text-align: left;
    // white-space: nowrap;
    background-color: var(--aura-c-site-nav-submenu-color-background, $site-nav-submenu-color-background);
    border-radius: var(--aura-c-site-nav-submenu-border-radius, $site-nav-submenu-border-radius);
    border: var(--aura-border-width-thin, $border-width-thin) solid $site-nav-submenu-color-border;
    box-shadow: var(--aura-site-nav-submenu-shadow, $shadow-drop-down);
    visibility: hidden;
    opacity: 0;
    transition: $global-transition;

    .site-nav__item:hover & {
      opacity: 1;
      visibility: visible;
      will-change: opacity;
      @include translateY(-6px);
    }

  }

}



.site-nav__sub-link {
  display: block;
  padding: var(--aura-c-site-nav-submenu-padding-block-mobile, $site-nav-submenu-padding-block-mobile) var(--aura-c-site-nav-submenu-padding-inline-mobile, $site-nav-submenu-padding-inline-mobile);
  font-weight: var(--aura-font-weight-site-nav, $font-weight-site-nav);
  transition: $background-color-transition;
  // @include word-wrap(none);

  @include mq($until: tablet) {
    @include font-size($site-nav-submenu-font-size-mobile);
  }

  &,
  &:link,
  &:visited {
    color: var(--aura-c-site-nav-text-color-link-mobile, $site-nav-text-color-link-mobile);
  }

  // Mobile view on desktop browser
  &:hover,
  &:active,
  &:focus {
    color: var(--aura-c-site-nav-text-color-active-mobile, $site-nav-text-color-link-active-mobile);
    text-decoration: none;
  }

  @include mq($from: tablet) {
    padding: var(--aura-c-site-nav-submenu-padding-block, $site-nav-submenu-padding-block) var(--aura-c-site-nav-submenu-padding-inline, $site-nav-submenu-padding-inline);
  
    &,
    &:link,
    &:visited {
      color: var(--aura-c-site-nav-submenu-text-color-link, $site-nav-submenu-text-color-link);
    }

    &:hover,
    &:active,
    &:focus {
      color: var(--aura-c-site-nav-submenu-text-color-hover, $site-nav-submenu-text-color-link-hover);
      background-color: var(--aura-c-site-nav-submenu-color-background-hover, $site-nav-submenu-color-background-hover);
    }
  }

}





/* Nav type modifiers.
   ========================================================================== */

// Main site navigation
.site-nav--main {
  @include display-flex();
  align-items: var(--aura-c-site-nav-align-items, $site-nav-align-items);
  line-height: 0;

  @include set-svg-bg-css-var("site-nav-sub-menu-icon", $svg-ddlb-arrow, $color-white);
  @include set-svg-bg-css-var("site-nav-sub-menu-icon-hover", $svg-ddlb-arrow, $color-white);

  @include mq($from: tablet) {
    height: var(--aura-c-site-nav-min-height, $site-nav-min-height);
  }

  .site-nav__list {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(100%);
    transition: 0.2s;
    will-change: transform;
    background-color: var(--aura-c-site-nav-color-background-mobile, $site-nav-color-background-mobile);
    line-height: normal;
    z-index: $z-index-zenith;

    @include mq($until: tablet) {
      overflow-y: scroll;
    }
    
    // Angular mobile nav trigger
    @at-root .show-mobile-nav#{&} {
      @include transform(none);
    }
    
    @include mq($from: tablet) {
      @include display-flex();
      position: relative;
      top: auto;
      right: auto;
      bottom: auto;
      left: auto;
      height: var(--aura-c-site-nav-min-height, $site-nav-min-height);
      background-color: transparent;
      overflow: visible;
      backdrop-filter: none;
      @include transform(none);
      z-index: 10;
      flex-grow: var(--aura-c-site-nav-list-flex-grow, $site-nav-list-flex-grow);
    }

  }

  .site-nav__item {

    @include mq($from: tablet) {
      margin-left: var(--aura-c-site-nav-link-margin-inline-tablet, $site-nav-margin-inline-tablet);
      padding: 0;

      &.site-nav--first-link {
        margin-left: 0;
      }

      @include hocus() {

        // Keep menu link and arrow highlighted while dropmenu is active.
        > .site-nav__link.has-sub-menu {
          color: var(--aura-c-site-nav-text-color-hover, $site-nav-text-color-link-hover);
          background-color: var(--aura-c-site-nav-color-background-hover, $site-nav-color-background-hover);

          &::after {
            @include svg-css-var-bg-img("site-nav-sub-menu-icon-hover");
          }

        }

      }

    }

    @include mq($from: desktop) {
      margin-left: var(--aura-c-site-nav-link-margin-inline-desktop, $site-nav-margin-inline-desktop);
    }

  }
  
  .site-nav__link {
    padding: var(--aura-c-site-nav-padding-mobile, $site-nav-padding-mobile);
    
    @include all-anchor-states() {
      color: var(--aura-c-site-nav-text-color-link-mobile, $site-nav-text-color-link-mobile);
    }

    &:hover,
    &:active,
    &:focus {
      color: var(--aura-c-site-nav-text-color-active-mobile, $site-nav-text-color-link-active-mobile);
      background-color: transparent;
    }

    &.site-nav__close {    
      &:hover,
      &:active,
      &:focus {
        background-color: transparent;
      }
    }
    
    &.is-mobile-username {
      @include font-size($site-nav-username-font-size-mobile);
      color: var(--aura-c-site-nav-username-text-color-mobile, $site-nav-username-text-color-mobile);
      font-weight: var(--aura-c-site-nav-username-font-weight-mobile, $site-nav-username-font-weight-mobile);
    }
    
    &.is-icon-only {
      .c-svg {
        width:  var(--aura-c-site-nav-icon-size, $site-nav-icon-size);
        height: var(--aura-c-site-nav-icon-size, $site-nav-icon-size);
      }
    }

    &.has-sub-menu {
      &::before {
        display: none;
      }
    }

    @include mq($until: tablet) {
      &:not(.site-nav__close) {
        text-align: center;
      }

    }

    @include mq($from: tablet) {
      @include display-flex();
      height: 100%;
      padding: var(--aura-c-site-nav-padding-block, $site-nav-padding-block) var(--aura-c-site-nav-padding-inline, $site-nav-padding-inline);
      background-color: transparent;
      // vertical-align: middle;
      align-items: center;
      transition: $background-color-transition;
      @include font-size($font-size-site-nav);

      &,
      &:link,
      &:visited {
        color: var(--aura-c-site-nav-text-color-link, $site-nav-text-color-link);
      }

      &:hover,
      &:active,
      &:focus {
        color: var(--aura-c-site-nav-text-color-hover, $site-nav-text-color-link-hover);
        background-color: var(--aura-c-site-nav-color-background-hover, $site-nav-color-background-hover);
      }

      // span {
      //   line-height: var(--aura-c-site-nav-min-height, $site-nav-min-height);
      // }

      // &.has-menu-icon {
      //   padding-left: var(--aura-c-site-nav-spacing-inline-icon, $site-nav-spacing-inline-icon);
      // }

      &.has-sub-menu {
        &::after {
          display: inherit;
          width:  var(--aura-ddlb-arrow-icon-size, $select-arrow-icon-sizing);
          height: var(--aura-ddlb-arrow-icon-size, $select-arrow-icon-sizing);
          margin-left: $spacing-small;
          @include svg-css-var-bg-img("site-nav-sub-menu-icon");
          transition: background-image $duration-promptly ease-out;
        }

        &:hover::after {
          @include mq($from: tablet) {
            @include svg-css-var-bg-img("site-nav-sub-menu-icon-hover");
          }
        }
        
      }

    }

  }

  // Menu dropdown
  .site-nav__sub-menu {

    &.is-last {
      @include mq($from: tablet) {
        left: auto;
        right: 0;
      }
    }
    
    .site-nav__item {
      text-align: center;

      @include mq($from: tablet) {
        margin-left: 0;
        // float: none;
        text-align: left;
      }
      
    }

  }

  .site-nav__sub-link {

    span {
      line-height: normal;
    }

  }



  // Meta menu - user profile
  // At tablet and above screens, profile menu is at the far right 
  // of the header, so we need a separate nav__list to position it outside 
  // of the rest of the main menu.
  .site-nav--metamenu {
    display: none;
    visibility: hidden;

    @include mq($from: tablet) {
      @include display-flex();
      visibility: visible;
      flex-grow: 0;
      @include clearfix();

      .site-nav__link {

        // &:not(.has-menu-icon) {
        //   padding-left: 0;
        // }

        &:hover,
        &:active,
        &:focus {
          background-color: transparent;
        }

        span {
          padding: 0;
        }

        &.is-icon-only {
          padding: 0;
        }

      }

    }

  }

  @if ($site-nav-position == 'right' or $aura-theme-type == 'patient') {
    .site-nav__branding {
      @include mq($from: tablet) {
        flex-grow: 1;
      }
    }

    .site-nav__list:not(.site-nav--metamenu) {
      @include mq($from: tablet) {
        flex-grow: 0;
      }
    }

  }

} // end site-nav--main




// Tab style page navigation
.site-nav--tabs {
  margin-bottom: var(--aura-c-tabs-nav-margin-block-end, $tabs-nav-margin-block-end);
  // border-bottom: $tabs-nav-border-bottom-sizing solid $color-border;

  .site-nav__list {
    @include mq($from: tablet) {
      @include display-flex();
      height: var(--aura-c-tabs-nav-height, $tabs-nav-height);
    }

    // Center tab nav horizontally on page.
    &.is-centered {
      justify-content: center;
    }
  }

  .site-nav__item {
    // float: left;
    border-top-style: var(--aura-c-tabs-nav-border-top-style-mobile, solid);
    border-top-width: var(--aura-c-tabs-nav-sizing-border-top-mobile, $border-width-thin);
    border-top-color: var(--aura-c-tabs-nav-color-border-top-mobile, $color-border);
    transition: background-color $duration-promptly ease-out;

    @include mq($from: tablet) {
      border-top: none;
      transition: none;
    }


    &:first-child .site-nav__link {
      @include mq($from: tablet) {
        padding-left: 0;

        &::before {
          left: 0;
          @include calc(width, 100%, '-', $spacing-default, 100%);
        }

        @if ($tabs-nav-pipe-dividers == true) {
          &::after {
            border-left: none;
          }
        }

      }

    }

    &:hover {

      &:not(.is-active) .site-nav__link {
        background-color: var(--aura-c-tabs-nav-color-background-mobile, $color-background-gray-lighter);

        @include mq($from: tablet) {
          background-color: transparent;
        }

      }

    }

  }

  .site-nav__link {
    padding: var(--aura-c-tabs-nav-padding-inline-mobile, $tabs-nav-padding-inline-mobile);
    text-align: center;
    color: var(--aura-c-tabs-nav-text-color-link, $tabs-nav-text-color-link);

    @include mq($from: tablet) {
      @include display-flex();
      height: 100%;
      align-items: center;
      padding: 0 var(--aura-c-tabs-nav-padding-inline, $tabs-nav-padding-inline);

      // Tab underline indicator
      &::before {
        position: absolute;
        top: 100%;
        @include calc(left, 0px, '+', $spacing-default, 0);
        @include calc(width, 100%, '-', ($spacing-default * 2), 100%);
        content: "";
        border-bottom-width: var(--aura-c-tabs-nav-border-bottom-sizing, $tabs-nav-border-bottom-sizing);
        border-bottom-style: solid;
        border-bottom-color: transparent;
        line-height: 0;
        transition: border-bottom-color $duration-promptly ease-out;
      }

      // Show vertical dividers between tabs if `true`
      @if ($tabs-nav-pipe-dividers == true) {
        &::after {
          position: absolute;
          top: 50%;
          left: 0;
          height: 50%;
          margin-top: -1em;
          content: "";
          border-left: var(--aura-border-width-thick, $border-width-thick) solid var(-aura-color-border, $color-border);
        }
      }

      &:hover {

        @include mq($from: tablet) {

          &::before {
            border-bottom-color: var(--aura-c-tabs-nav-border-color-hover, $tabs-nav-border-color-hover);
          }

        }

      }

    }

  }

}



// Stacked navigation - like a sidebar nav
.site-nav--stacked {
  
  // @include mq($from: tablet) {
  //   margin-bottom: $spacing-large;
  // }

  .site-nav__list {
    margin-bottom: var(--aura-c-stacked-nav-margin-block-end, $stacked-nav-margin-block-end);

    &:last-of-type {
      margin-bottom: 0;
    }

  }

  .site-nav__item {
    width: 100%;

    @if ($stacked-nav-item-show-border-bottom == true) {
      border-bottom: $border-width-thin solid var(--aura-color-border, $color-border);
  
      &:first-child {
        border-top: $border-width-thin solid var(--aura-color-border, $color-border);
      }

    }

    @include mq($from: tablet) {
      text-align: left;
    }

  }

  .site-nav__link {
    color: var(--aura-c-stacked-nav-text-color-link, $stacked-nav-text-link-color);
    text-align: var(--aura-c-stacked-nav-link-text-align-mobile, center);
    padding: var(--aura-c-stacked-nav-padding-block-mobile, $stacked-nav-padding-block-mobile) var(--aura-c-stacked-nav-padding-inline-mobile, $stacked-nav-padding-inline-mobile);
    border-radius: var(--aura-c-stacked-nav-text-link-border-radius, 0);
    transition: $text-color-transition, $background-color-transition;

    &:hover:not(.is-active) {
      color: var(--aura-c-stacked-nav-text-color-hover, $stacked-nav-text-link-color-hover);
    }

    @include mq($from: tablet) {
      display: inline-block;
      width: var(--aura-c-stacked-nav-link-width, auto);
      text-align: var(--aura-c-stacked-nav-link-text-align, left);
      padding-right: var(--aura-c-stacked-nav-padding-inline-end, $stacked-nav-padding-inline-end);
      
      &:hover:not(.is-active) {
        background-color: var(--aura-c-stacked-nav-link-color-background-hover, transparent);
      }
    }

    .c-svg {
      display: none;
    }

  }

}


// Branding only nav
.site-nav--branding {
  @include display-flex();
  align-items: var(--aura-c-site-nav-align-items, $site-nav-align-items);
  justify-content: center;
  text-align: center;
  line-height: 0;

  @include mq($from: tablet) {
    height: var(--aura-c-site-nav-min-height, $site-nav-min-height);
  }

  .site-nav__home-link {
    @include mq($from: tablet) {
      flex-grow: 0;
      margin-right: var(--aura-c-site-nav-logo-spacing-inline, $site-nav-logo-spacing-inline);
    }
  }

  &.align-logos-left\@tablet {
    @include mq($from: tablet) {
      justify-content: left;
      text-align: left;
    }
  }
}



// .site-nav--sticky {
//   margin-bottom: 0 !important;
  
//   @include mq($from: tablet) {
//     position: sticky;
//     top: 150px;
//     width: 100%;
//     height: calc(100vh - 236px);
//     margin-top: 0;
//     overflow: auto;
//     overflow-y: scroll;
//     z-index: 1000;
//   }

// }




/* Current section indicator.
   ========================================================================== */
/**
 * Add `.is-active` class dynamically to current `site-nav__link` or use
 * page/section classes for specificity.
 * e.g.: html.page--about .site-nav__link.site-nav__about {}
 */
.is-active {

  .site-nav--main  & {

    .site-nav__link {

      @include all-anchor-states() {
        color: var(--aura-c-site-nav-text-color-active-mobile, $site-nav-text-color-link-active-mobile);
      }
      background-color: var(--aura-c-site-nav-color-background-active-mobile, $site-nav-color-background-active-mobile);

      @include mq($from: tablet) {
        @include all-anchor-states() {
          color: var(--aura-c-site-nav-text-color-active, $site-nav-text-color-link-active);
        }
        background-color: var(--aura-c-site-nav-color-background-active, $site-nav-color-background-active);
      }

    }

    .site-nav__sub-link {
      @include all-anchor-states() {
        color: var(--aura-c-site-nav-text-color-active-mobile, $site-nav-text-color-link-active-mobile);
      }
      background-color: var(--aura-c-site-nav-color-background-active-mobile, $site-nav-color-background-active-mobile);

      @include mq($from: tablet) {
        @include all-anchor-states() {
          color: var(--aura-c-site-nav-submenu-text-color-active, $site-nav-submenu-text-color-link-active);
        } 
        background-color: var(--aura-c-site-nav-color-background-active, $site-nav-color-background-active);
      }

    }

  }


  .site-nav--tabs & {

    .site-nav__link {
      @include all-anchor-states() {
        color: var(--aura-c-tabs-nav-text-color-active, $tabs-nav-text-color-active);
      }

      @include mq($from: tablet) {
        &::before {
          border-bottom-color: var(--aura-c-tabs-nav-border-color-active, $tabs-nav-border-color-active);
        }
      }

    }

  }


  .site-nav--stacked & {

    .site-nav__link {
      @include all-anchor-states() {
        color: var(--aura-c-stacked-nav-text-color-active, $stacked-nav-text-link-color-active);
        background-color: var(--aura-c-stacked-nav-link-color-background-active-mobile, $stacked-nav-link-color-background-active-mobile);
      }
      
      @include mq($from: tablet) {
        @include all-anchor-states() {
          background-color: var(--aura-c-stacked-nav-link-color-background-active, $stacked-nav-link-color-background-active);
        }

        .c-svg {
          display: block;
          @include position-y-center-right();
        }

      }

    }

  }

}



/* Aligment variants
   ========================================================================== */
.page-head--align-start {

  .site-nav__branding,
  .site-nav__home-link,
  .site-nav--main {
    align-items: flex-start;
  }

}

.page-head--align-end {
  
  .site-nav__branding,
  .site-nav__home-link,
  .site-nav--main {
    align-items: flex-end;
  }

}