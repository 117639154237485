/* ========================================================================
   #COLORS
   ======================================================================== */
/**
 * Background color tokens

  type: color
  category: background-color
  cssProperties:
    - background
    - background-color

 */

/**
 * ATTENTION
 *
 * Usage:
 * Until the design token pipeline is in place, find the file
 *  `_themes.custom-tokens.scss` 
 * in the themes folder and rename it to 
 *  `_themes.custom-{product-name}-tokens.scss`
 *
 * You will use this file to override !default token values as needed.
 */


/**
 * DO NOT EDIT ANYTHING BELOW 
 * Override default token values in YOUR custom tokens file.
 */

/* Gradient
   ========================================================================== */
$color-background-gradient-start:     $resmed-brand-007 !default;
$color-background-gradient-mid:       $resmed-brand-006 !default;
$color-background-gradient-end:       $resmed-brand-005 !default;
$color-background-gradient-angle:     311.16deg !default;
$color-background-gradient:           linear-gradient($color-background-gradient-angle, $color-background-gradient-start 6.73%, $color-background-gradient-mid 58.19%, $color-background-gradient-end 99.59%) !default;
$color-background-gradient-fill:      linear-gradient($color-background-gradient-angle, $color-background-gradient-start 0%, $color-background-gradient-start 49.9%, $color-background-gradient-end 50%, $color-background-gradient-end 100%) !default;




/* Background Colors
   ========================================================================== */
// Use these tokens for background colors only. Do not use these for border 
// colors or text colors.

$color-background:                        $color-white !default;
$color-background-alt:                    $resmed-brand-gray-palette-50 !default;

$color-background-gray-lightest:          $resmed-brand-gray-palette-50 !default;
$color-background-gray-lighter:           $resmed-brand-gray-palette-100 !default;
$color-background-gray-light:             $resmed-brand-gray-palette-200 !default;

$color-background-gray-mid-lighter:       $resmed-brand-gray-palette-300 !default;
$color-background-gray-mid-light:         $resmed-brand-gray-palette-400 !default;
$color-background-gray-mid:               $resmed-brand-gray-palette-500 !default;
$color-background-gray-mid-dark:          $resmed-brand-gray-palette-600 !default;

$color-background-gray-dark:              $resmed-brand-gray-palette-700 !default;
$color-background-gray-darker:            $resmed-brand-gray-palette-800 !default;
$color-background-gray-darkest:           $resmed-brand-gray-palette-900 !default;


/* Do we need this?? 
$color-background-brand:                  $resmed-brand-001-palette-500 !default;
$color-background-brand-accessible:       $resmed-brand-001-palette-600 !default;
*/

$color-background-primary:                $theme-primary-color !default;
$color-background-primary-hover:          $theme-primary-color-hover !default;
$color-background-primary-active:         $theme-primary-color-active !default;

$color-background-primary-lightest:       $theme-primary-color-50 !default;
$color-background-primary-lighter:        $theme-primary-color-100 !default;
$color-background-primary-light:          $theme-primary-color-200 !default;
$color-background-primary-dark:           $theme-primary-color-700 !default;
$color-background-primary-darker:         $theme-primary-color-800 !default;
$color-background-primary-darkest:        $theme-primary-color-900 !default;
$color-background-primary-messaging:      $theme-primary-color-300 !default;

$color-background-success:                $palette-mod-green-500 !default;
$color-background-success-hover:          $palette-mod-green-400 !default;
$color-background-success-active:         $palette-mod-green-700 !default;
$color-background-success-light:          $palette-mod-green-200 !default;
$color-background-success-lighter:        $palette-mod-green-100 !default;
$color-background-success-lightest:       $palette-mod-green-50 !default;

$color-background-warning:                $palette-light-orange-600 !default;
$color-background-warning-hover:          $palette-light-orange-400 !default;
$color-background-warning-active:         $palette-light-orange-700 !default;
$color-background-warning-light:          $palette-vivid-orange-200 !default;
$color-background-warning-lighter:        $palette-vivid-orange-100 !default;
$color-background-warning-lightest:       $palette-vivid-orange-50 !default;

$color-background-danger:                 $resmed-brand-022-palette-600 !default;
$color-background-danger-hover:           $resmed-brand-022-palette-400 !default;
$color-background-danger-active:          $resmed-brand-022-palette-700 !default;
$color-background-danger-light:           $resmed-brand-022-palette-200 !default;
$color-background-danger-lighter:         $resmed-brand-022-palette-100 !default;
$color-background-danger-lightest:        $resmed-brand-022-palette-50 !default;

$color-background-selection:              $palette-light-yellow-500 !default;
$color-background-dialog:                 $color-white !default;
$color-background-backdrop:               $color-black-85 !default;
$color-background-image-overlay:          $color-black-60 !default;
$color-background-form-input:             transparent !default;
$color-background-textarea:               $color-white !default;
$color-background-input-disabled:         $resmed-brand-gray-palette-200 !default;
$color-background-checkbox-disabled:      $resmed-brand-gray-palette-300 !default;

$button-color-background:                 transparent !default;
$button-color-background-hover:           $resmed-brand-gray-palette-100 !default;
$button-color-background-active:          transparent !default;

$button-primary-color-background:         $theme-primary-color !default;
$button-primary-color-background-hover:   $theme-primary-color-hover !default;
$button-primary-color-background-active:  $theme-primary-color-active !default;

$button-outline-color-background:         transparent !default;
$button-outline-color-background-hover:   transparent !default;
$button-outline-color-background-active:  $theme-primary-color-50 !default;

$button-neutral-color-background:         $resmed-brand-gray-palette-700 !default;
$button-neutral-color-background-hover:   $resmed-brand-gray-palette-500 !default;
$button-neutral-color-background-active:  $resmed-brand-gray-palette-800 !default;

$button-outline-neutral-color-background:         transparent !default;
$button-outline-neutral-color-background-hover:   transparent !default;
$button-outline-neutral-color-background-active:  $resmed-brand-gray-palette-100 !default;

$button-inverse-color-background:                 transparent !default;
$button-inverse-color-background-hover:           $resmed-brand-gray-palette-900 !default;
$button-inverse-color-background-active:          transparent !default;

$button-primary-inverse-color-background:         $color-white !default;
$button-primary-inverse-color-background-hover:   $resmed-brand-gray-palette-200 !default;
$button-primary-inverse-color-background-active:  $color-white !default;

$button-outline-inverse-color-background:         transparent !default;
$button-outline-inverse-color-background-hover:   transparent !default;
$button-outline-inverse-color-background-active:  $color-white !default;

$button-neutral-inverse-color-background:         $resmed-brand-gray-palette-300 !default;
$button-neutral-inverse-color-background-hover:   $resmed-brand-gray-palette-200 !default;
$button-neutral-inverse-color-background-active:  $resmed-brand-gray-palette-50 !default;

$button-text-inverse-color-background:            transparent !default;
$button-text-inverse-color-background-hover:      transparent !default;
$button-text-inverse-color-background-active:     transparent !default;


// $page-head-color-background:              $color-background !default; // Page header background
// $page-head-color-background-fill:         $color-background !default; // Background overflow for centered page layouts
$page-head-color-background:              $color-background-gradient !default;
$page-head-color-background-fill:         $color-background-gradient-fill !default;

$card-color-background:                   $color-white !default;
$footer-color-background:                 $color-background-gray-darker !default;
$popover-color-background:                $color-white !default;
$popover-dark-color-background:           $palette-blue-gray-500 !default;
$tooltip-color-background:                $resmed-brand-gray-palette-800 !default;

$chip-color-background:                   $resmed-brand-gray-palette-200 !default;
$chip-color-background-hover:             $resmed-brand-gray-palette-300 !default;
$chip-color-background-active:            $color-background-primary-lightest !default;





// $popover-dark-color-background-alt:            $palette-blue-gray-700 !default;

// $color-background-toggle:;
// $color-background-toggle-disabled:;
// $color-background-toggle-hover:;

// $color-background-table-header:;
