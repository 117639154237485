
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$werewolf-primary: mat.define-palette(mat.$indigo-palette);
$werewolf-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$werewolf-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$werewolf-theme: mat.define-light-theme((
  color: (
    primary: $werewolf-primary,
    accent: $werewolf-accent,
    warn: $werewolf-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($werewolf-theme);

/* ==========================================================================
   AURACSS
   ========================================================================== */

/**

      ___           ___           ___           ___           ___           ___           ___
     /\  \         /\  \         /\  \         /\  \         /\__\         /\__\         /\__\
    /::\  \        \:\  \       /::\  \       /::\  \       /:/  /        /:/ _/_       /:/ _/_
   /:/\:\  \        \:\  \     /:/\:\__\     /:/\:\  \     /:/  /        /:/ /\  \     /:/ /\  \
  /:/ /::\  \   ___  \:\  \   /:/ /:/  /    /:/ /::\  \   /:/  /  ___   /:/ /::\  \   /:/ /::\  \
 /:/_/:/\:\__\ /\  \  \:\__\ /:/_/:/__/___ /:/_/:/\:\__\ /:/__/  /\__\ /:/_/:/\:\__\ /:/_/:/\:\__\
 \:\/:/  \/__/ \:\  \ /:/  / \:\/:::::/  / \:\/:/  \/__/ \:\  \ /:/  / \:\/:/ /:/  / \:\/:/ /:/  /
  \::/__/       \:\  /:/  /   \::/~~/~~~~   \::/__/       \:\  /:/  /   \::/ /:/  /   \::/ /:/  /
   \:\  \        \:\/:/  /     \:\~~\        \:\  \        \:\/:/  /     \/_/:/  /     \/_/:/  /
    \:\__\        \::/  /       \:\__\        \:\__\        \::/  /        /:/  /        /:/  /
     \/__/         \/__/         \/__/         \/__/         \/__/         \/__/         \/__/

 * AURACSS v1.1.0-beta
 * see CHANGELOG.md for details.
 * By: jason.otis@resmed.com
 */


/****************************************************************************
 * INSTRUCTIONS
 *
 *
 * NPM Dependencies
 *   AURACSS is dependent on sassmq for media-queries.
 *   Sassmq is included manually in `/sass/modules/`.
 *   This CSS module is available as a Node package.
 *   If you are using NPM, please DO NOT include or replace the included
 *   version with the NPM version.
 *
 * SASS-MQ
 *   https://github.com/sass-mq/sass-mq
 *
 ******************************************************************************/


/******************************************************************************

                        █░█░█ ▄▀█ █▀█ █▄░█ █ █▄░█ █▀▀ █
                        ▀▄▀▄▀ █▀█ █▀▄ █░▀█ █ █░▀█ █▄█ ▄

      !!!!!!   Most partial SASS files are imported by default   !!!!!!

  Comment out any SASS partials that you do not need for your project to help
  keep your outputted CSS file size down. There's no meed to include classes
  you won't be using.

 ******************************************************************************/


/**
 * CONTENTS
 *
 *
 * TOOLS
 * Font-size............A mixin which guarantees baseline-friendly line-heights.
 * Clearfix.............Micro clearfix mixin.
 * Hidden...............Mixin for hiding elements.
 * Animation............Animation mixins that provide prefixing.
 * Color variations.....A function to mix colors on the fly.
 * Color functions......Functions to lighten or darken colors.
 * Compass..............Mixins from the Compass framework that we still use.
 * Postioning...........Mixins for positioning of elements.
 * Properties...........CSS properties, mostly for prefixing.
 * SVG..................SVG related mixins.
 * Transforms...........Prefixer mixins for CSS transforms.
 *
 *
 * TOKENS
 * Color palettes.......ResMed and AURA color palettes.
 * Custom tokens........Override tokens with custom values for themes.
 * Design-tokens........Import file for all global token files.
 *
 *
 * SETTINGS
 * Config...............Project-level configuration and feature switches.
 * Fonts................Importing Google fonts or loading local font files.
 * Core.................AURACSS framework settings - values come from tokens.
 * Color................Color map settings used by compoents and utility classes.
 * Breakpoints..........Breakpoint settings for media queries.
 * Utilities............Config dynamically generated utility classes.
 *
 *
 * MEDIA QUERIES
 * Sass MQ..............Media query manager. Needs to be imported after
 *                      `_settings.breakpoints.scss` in order to override the
 *                      default $breakpoints map.
 *
 *
 * GENERIC
 * Box-sizing...........Better default `box-sizing`.
 * Normalize.css........A level playing field using @necolas’ Normalize.css.
 * Reset................A tiny reset to complement Normalize.css.
 * Shared...............Sensibly and tersely share some global commonalities
 *                      (particularly useful when managing vertical rhythm).
 *
 *
 * ELEMENTS
 * Page.................Set up our document’s default `font-size` and
 *                      `line-height`.
 * Headings.............Very minimal (i.e. only font-size information) for
 *                      headings 1 through 6.
 * Images...............Base image styles.
 * Tables...............Simple table styles.
 * Forms................Form input defaults for font-family, font-weight, color.
 * Page.................Our page defaults, sticky footer.
 * Tables...............Our table defaults.
 * Typography...........Font-family, font-weight, and color defaults for HTML,
 *                      H, B, STRONG, LABEL, A elements.
 *
 *
 * VENDORS
 * Slick................Responsive carousel.
 * Modaal...............Accessible modal windows.
 *
 *
 * OBJECTS
 * Wrapper..............Page constraint object.
 * Layout...............Generic layout module.
 * Media................Image- and text-like content side by side. The
 *                      poster-child of OOCSS.
 * Flag.................Table-layout-based advancement on the Media object.
 * List-bare............Lists with no bullets or indents.
 * List-inline..........A list whose items all site in a line.
 * Box..................Simple boxing abstraction.
 * Block................Image-on-top-of-text object.
 * Ratio................A container for maintaining aspect ratio of content.
 * Crop.................Provide a cropping context for media (images, etc.).
 * Table................Classes for manipulating `table`s.
 * Pack.................Pack items into available horizontal space.
 * Callouts.............Box element for call to action content.
 * Carousels............Layout customizations to the carousel.
 * Flexbody.............Page body wrapper for sticky footer.
 * Tables...............Our product specific table styles for grid views.
 * Sections.............Define your content area, break it up in to sections.
 * Scrollable...........Scrollable container.
 *
 *
 * ANIMATIONS
 * Custom...............Some custom CSSkeyframe animations.
 *
 *
 * COMPONENTS
 * Alerts...............Global alerts messages, errors, warnings, and success.
 * Android-Badges.......Get it on Android.
 * Apple-Badges.........Apple download badges.
 * Buttons..............Button component.
 * Cards................Content lures, Title, Image, Description.
 * Chips................Interactive filter selection chips.
 * Code.................Show code snippets in a nice <code> block. Uses Prism.js.
 * Directional nav......Previous Next chevron nav.
 * Dropdowns............Custom dropdown.
 * Expander.............Collapsible content.
 * Footer...............Page footers.
 * Forms................Form inputs.
 * Hero.................Custom hero banners.
 * Icons................Specific inline icons.
 * Button Icons.........Icons which interact as a button.
 * Indicators...........Little status indicators.
 * Loaders..............CSS page loading spinners.
 * Logos................Branding items.
 * Modal................Simple modal window.
 * Notifications........Site notifications.
 * Page-head............Page headers, masthead, hero.
 * Pagination...........Pagination for lists of results, wizard, stepper.
 * Popover..............Fancy tooltip that stays active so you can hover over it.
 * Progress.............Progress bar.
 * Sitenav..............Navigation component.
 * Survey...............Survey modal with stepper.
 * SVG Symbols..........SVG symbols baseline CSS.
 * Tags.................Text in a box.
 * Tooltips.............CSS only style-able tooltip option.
 * Typography...........Typography related styles, headings, labels, text.
 * Video................Video player overay.
 * Wizards..............Wizard, step-through.
 *
 *
 * UTILITIES
 * Clearfix.............Bind our clearfix onto a utility class.
 * Widths...............Simple width helper classes.
 * Headings.............Reassigning our heading styles to helper classes.
 * Spacings.............Nudge bits of the DOM around with these spacing
 *                      classes.
 * Responsive-Spacings..Enhances the function of normal spacings for
 *                      responsive usage.
 * Print................Reset-like styles taken from the HTML5 Boilerplate.
 * Hide.................Helper classes to hide content.
 * Accessibility........Accessibility related classes.
 * Border...............Border and border radius classes.
 * Color fill...........Background color classes created from color maps.
 * Float................Float or unfloat elements.
 * Percent widths.......Specific percentage widths for grid view columns.
 * Positioning..........Helper classes to position elements - float.
 * Typography...........Helper classes for specific text and font styles.
 * Visibility...........Show or hide DOM elements at/or between breakpoints.
 *
 *
 * THEMES
 * Provider.............Theme toggling example using default Provider theme.
 *                      For demo purposes only.
 * Dark UI..............Dark UI theme toggle - in progress.
 */



// TOOLS
@import "../aura-design-system/auracss/tools/tools.font-size";
@import "../aura-design-system/auracss/tools/tools.clearfix";
@import "../aura-design-system/auracss/tools/tools.animation";
@import "../aura-design-system/auracss/tools/tools.color.functions";
@import "../aura-design-system/auracss/tools/tools.compass";
@import "../aura-design-system/auracss/tools/tools.layout";
@import "../aura-design-system/auracss/tools/tools.positioning";
@import "../aura-design-system/auracss/tools/tools.properties";
@import "../aura-design-system/auracss/tools/tools.svg";
@import "../aura-design-system/auracss/tools/tools.transforms";



// TOKENS
@import "../aura-design-system/auracss/design-tokens/tokens.color-palettes";
@import "../aura-design-system/auracss/design-tokens/tokens.font-stacks";
/**
 * Uncomment the below @import rule line if you need to override !default
 * design token values with your own.
 */
@import "app-theme-css/_themes.custom-app-tokens";
@import "../aura-design-system/auracss/design-tokens";


// SETTINGS
// @import "../aura-design-system/auracss/settings/settings.config";
@import "../aura-design-system/auracss/settings/settings.fonts";
@import "../aura-design-system/auracss/settings/settings.core";
@import "../aura-design-system/auracss/settings/settings.color";
@import "../aura-design-system/auracss/settings/settings.breakpoints";
@import "../aura-design-system/auracss/settings/settings.utilities";



// MEDIA QUERIES
@import "../aura-design-system/auracss/sass-mq/mq";



// GENERIC
@import "../aura-design-system/auracss/generic/generic.box-sizing";
@import "../aura-design-system/auracss/generic/generic.normalize";
@import "../aura-design-system/auracss/generic/generic.reset";
@import "../aura-design-system/auracss/generic/generic.shared";



// ELEMENTS
@import "../aura-design-system/auracss/elements/elements.forms";
@import "../aura-design-system/auracss/elements/elements.images";
@import "../aura-design-system/auracss/elements/elements.page";
@import "../aura-design-system/auracss/elements/elements.tables";
@import "../aura-design-system/auracss/elements/elements.typography";



// VENDORS
/**
 * Include 3rd-party CSS files here. This makes it esier to overwrite any styles
 * in your object or component classes further down the stack.
 */

// Slick - used for carousels
// http://kenwheeler.github.io/slick/
@import "../aura-design-system/auracss/vendors/slick";
@import "../aura-design-system/auracss/vendors/slick-theme";

// Modaal plugin
// https://humaan.com/modaal/
@import "../aura-design-system/auracss/vendors/modaal";



// OBJECTS
//
// Uncomment and add to this section as necessary.


// @import "../aura-design-system/auracss/objects/objects.media";
// @import "../aura-design-system/auracss/objects/objects.flag";
// @import "../aura-design-system/auracss/objects/objects.list-bare";
// @import "../aura-design-system/auracss/objects/objects.list-inline";
// @import "../aura-design-system/auracss/objects/objects.box";
// @import "../aura-design-system/auracss/objects/objects.block";
// @import "../aura-design-system/auracss/objects/objects.ratio";
// @import "../aura-design-system/auracss/objects/objects.crop";
// @import "../aura-design-system/auracss/objects/objects.pack";
// @import "../aura-design-system/auracss/objects/objects.callouts";
@import "../aura-design-system/auracss/objects/objects.carousels";
@import "../aura-design-system/auracss/objects/objects.flexbody";
// @import "../aura-design-system/auracss/objects/objects.inset";
@import "../aura-design-system/auracss/objects/objects.lists";
@import "../aura-design-system/auracss/objects/objects.wrapper";
@import "../aura-design-system/auracss/objects/objects.layout";
@import "../aura-design-system/auracss/objects/objects.sections";
// @import "../aura-design-system/auracss/objects/objects.scrollable";



// CSS ANIMATIONS
@import "../aura-design-system/auracss/animations/animations.custom";



// COMPONENTS
//
// Build and include your project’s UI Compoments here.
// @import "../aura-design-system/auracss/components/alerts/components.alerts";
// // @import "../aura-design-system/auracss/components/components.android-badges";
// // @import "../aura-design-system/auracss/components/components.apple-badges";
// @import "../aura-design-system/auracss/components/components.badges";
@import "../aura-design-system/auracss/components/buttons/components.buttons";
// @import "../aura-design-system/auracss/components/components.cards";
// @import "../aura-design-system/auracss/components/components.chips";
@import "../aura-design-system/auracss/components/components.stepper-nav";
// @import "../aura-design-system/auracss/components/components.directional-nav";
@import "../aura-design-system/auracss/components/components.dropdowns";
@import "../aura-design-system/auracss/components/components.expander";
@import "../aura-design-system/auracss/components/components.footer";
@import "../aura-design-system/auracss/components/components.forms";
// @import "../aura-design-system/auracss/components/components.hero";
@import "../aura-design-system/auracss/components/components.icons";
@import "../aura-design-system/auracss/components/components.icon-buttons";
@import "../aura-design-system/auracss/components/components.indicators";
@import "../aura-design-system/auracss/components/components.lists";
// @import "../aura-design-system/auracss/components/components.loaders";
@import "../aura-design-system/auracss/components/components.logos";
@import "../aura-design-system/auracss/components/components.page-head";
// @import "../aura-design-system/auracss/components/components.pagination";
@import "../aura-design-system/auracss/components/components.popover";
@import "../aura-design-system/auracss/components/components.progress";
@import "../aura-design-system/auracss/components/navigation/components.sitenav";
// @import "../aura-design-system/auracss/components/components.survey";
@import "../aura-design-system/auracss/components/components.svg-symbols";
// @import "../aura-design-system/auracss/components/components.tags";
// @import "../aura-design-system/auracss/components/components.data-tables";
// @import "../aura-design-system/auracss/components/components.toggles";
// @import "../aura-design-system/auracss/components/components.tooltips";
@import "../aura-design-system/auracss/components/components.typography";
// @import "../aura-design-system/auracss/components/components.video";
// @import "../aura-design-system/auracss/components/components.wizards";



// CUSTOM CSS
// @import your custom css file here.
@import "app-theme-css/themes.custom-app-css";



// UTILITIES
@import "../aura-design-system/auracss/utilities/utilities.accessibility";
@import "../aura-design-system/auracss/utilities/utilities.border";
@import "../aura-design-system/auracss/utilities/utilities.clearfix";
@import "../aura-design-system/auracss/utilities/utilities.color-fill";
@import "../aura-design-system/auracss/utilities/utilities.float";
@import "../aura-design-system/auracss/utilities/utilities.percent.widths";
@import "../aura-design-system/auracss/utilities/utilities.positioning";
@import "../aura-design-system/auracss/utilities/utilities.spacings";
@import "../aura-design-system/auracss/utilities/utilities.responsive-spacings";
@import "../aura-design-system/auracss/utilities/utilities.typography";
@import "../aura-design-system/auracss/utilities/utilities.visibility";
@import "../aura-design-system/auracss/utilities/utilities.widths";

@import "../aura-design-system/auracss/utilities/utilities.print";
@import "../aura-design-system/auracss/utilities/utilities.healthcheck";
@import "../aura-design-system/auracss/utilities/utilities.debug";

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
