/* ==========================================================================
   #ICONS
   ========================================================================== */

$c-icon-ddlb-arrow-size:     $select-arrow-icon-sizing !default;
$c-icon-ddlb-arrow-color:    $color-text-default !default;

/**
 * Create design patterns with icons.
 */
.c-icon {
  position: relative;
  display: inline-block;

  &::before {
    display: inline-block;
    color: inherit;
  }

  &:disabled,
  .is-disabled &,
  [data-state="is-disabled"] & {
    cursor: none !important;
    opacity: 0.3;
  }

  span {
    display: none;
    @include hide-text();
  }

  @at-root button #{&} {
    width: 100%;
    height: 100%;
  }

  // @at-root a #{&} {
  //   width: 100%;
  //   height: 100%;
  // }

  .c-svg {
    top: 0;
    width: 100%;
    height: 100%;
  }
}



/* Icon variations
   ========================================================================== */
// If you can't include the drop down arrow as an inline SVG
// .c-icon--dd-arrow {
//   &::before {
//       display: inline-block;
//       width:  $c-icon-ddlb-arrow-size;
//       height: $c-icon-ddlb-arrow-size;
//       @include svg-bg-img($svg-ddlb-arrow, $c-icon-ddlb-arrow-color);
//   }
// }


.c-icon--nav-item {
  // @include position-y-center();
  // left: $spacing-tiny;
  display: inline-flex;
  margin-right: $spacing-tiny;
  width:  $icon-size-default;
  height: $icon-size-default;

  .c-svg {
    top: 0;
    width: 100%;
    height: 100%;
  }

}

// 'X' type button for deleting an item, table row
.c-icon--delete {
  border-radius: 50%;
  transition: $text-color-transition;

  @at-root .c-popover--icon .c-popover__target:not(.c-svg-hover-swap) #{&},
  &:not(.c-svg-hover-swap) {
    .c-svg {
      color: $color-text-default;
    }
  }

  @at-root .c-popover--icon .c-popover__target:hover:not(.c-svg-hover-swap) #{&},
  &:hover,
  &:active,
  &:focus {
    .c-svg {
      color: $color-text-danger;
    }
  }

  &.delete-table-row {
    z-index: 0;

    &::before {
      content: '';
      display: block;
      opacity: 0;
      position: absolute;
      transition-duration: .15s;
      transition-timing-function: cubic-bezier(0.4,0.0,0.2,1);
      z-index: -1;
      bottom: -6px;
      left:   -6px;
      right:  -6px;
      top:    -6px;
      background: none;
      border-radius: 50%;
      box-sizing: border-box;
      transform: scale(0);
      transition-property: transform, opacity;
    }

    &:hover,
    &:focus,
    &:active {

      &::before {
        background-color: rgba(32,33,36,0.059);
        border: none;
        box-shadow: none;
        opacity: 1;
        transform: scale(1);
      }

    }

    &:focus {
      &,
      &::before {
        outline: none;
      }
    }

    &:active:before {
      border: $border-width-thin solid $resmed-brand-gray-palette-300;
    }

  }

}
