/* ==========================================================================
   #BUTTONS
   ========================================================================== */
// @import 'mixins.scss';

/** Private framework-only tokens **/
$c-button-line-height-mobile:     20px !default;
$c-button-max-width-mobile:       252px !default;
$c-button-letter-spacing:         1px !default;
$c-button-transition:             $text-color-transition, $background-color-transition, $box-shadow-transition !default;

$c-button-padding-block-mobile:   calc($font-size-button / 2) + calc(($button-height - $c-button-line-height-mobile - $font-size-button) / 2) !default;
$c-button-padding-inline-mobile:  $spacing-default !default;
// $c-button-padding-block:          $spacing-none !default;
$c-button-padding-inline:         $spacing-medium !default;
$c-button-icon-inline-spacing:    $spacing-small !default;

$c-button-md-height:              calc($button-height - 6px) !default;
$c-button-md-font-size:           13px !default;
$c-button-md-padding-inline:      20px !default;

$c-button-sm-height:              calc($button-height - 12px)  !default;
$c-button-sm-font-size:           12px !default;
$c-button-sm-padding-inline:      $spacing-md-sm !default;

$c-button-xsm-height:             calc($button-height - 18px)  !default;
$c-button-xsm-font-size:          10px !default;
$c-button-xsm-padding-inline:     $spacing-small !default;

$c-button-min-width:              186px !default;
$c-button-width-short:            120px !default;
$c-button-width-wide:             240px !default;

/**
 * Buttons
 * @selector .c-btn
 * @restrict a, button, input[type="submit"]
 *
 * HTML

  Anchor:
    <a href="#0" id="btnLink" class="c-btn c-btn--primary" role="button">Link</a>

  Button:
    <button id="btnButton" class="c-btn c-btn--primary">Button</button>

  Input:
    <input id="btnSubmit" class="c-btn c-btn--primary" type="submit" value="Submit""/>

 *
 */

/**
 * 1. Mobile first - make our buttons block elements below tablet size screens,
 *    center align them, and make them full width up to a set max-width.
 * 2. Allow us to style box model properties.
 * 3. Line different sized buttons up a little nicer.
 * 4. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 5. Reset/normalize some styles.
 * 6. Force all button-styled elements to appear clickable.
 * 7. Give the user a nice visual indication that the button is in focus.
 * 8. Make the default button have small rounded corners for the box-shadow outline.
 */
.c-btn {
  --aura-c-button-color-background: #{$button-color-background};
  --aura-c-button-color-background-hover: #{$button-color-background-hover};
  --aura-c-button-color-background-active: #{$button-color-background-active};

  --aura-c-button-color-border:  #{$button-color-border};
  --aura-c-button-color-border-hover: #{$button-color-border-hover};
  --aura-c-button-color-border-active: #{$button-color-border-active};
  --aura-c-button-radius-border: #{$button-border-radius};

  --aura-c-button-color-text: #{$button-color-text};
  --aura-c-button-color-text-hover: #{$button-color-text-hover};
  --aura-c-button-color-text-active: #{$button-color-text-active};

  --aura-c-button-height: #{$button-height};

  position: relative;
  display: inline-flex; // block; /* [1] */
  align-items: center;
  justify-content: center;
  width: 100%; /* [1] */
  // height: --aura-c-button-height;
  max-width:      var(--aura-c-button-max-width, $c-button-max-width-mobile); /* [1] */
  // margin-bottom:  $spacing-default; /* [1] */
  margin-right:   auto; /* [1] */
  margin-left:    auto; /* [1] */
  padding-top:    var(--aura-c-button-padding-block-start-mobile, $c-button-padding-block-mobile);  /* [1] */
  padding-bottom: var(--aura-c-button-padding-block-end-mobile, $c-button-padding-block-mobile);  /* [1] */
  padding-left:   var(--aura-c-button-padding-inline-start-mobile, $c-button-padding-inline-mobile);
  padding-right:  var(--aura-c-button-padding-inline-end-mobile, $c-button-padding-inline-mobile);
  font: inherit; /* [4] */
  text-align: center; /* [5] */
  @include font-size($font-size-button, $c-button-line-height-mobile);
  font-weight: var(--aura-c-button-font-weight, $font-weight-button);
  letter-spacing: var(--aura-c-button-letter-spacing, $c-button-letter-spacing);
  background: none; 
  background-color: var(--aura-c-button-color-background);
  background-clip: border-box;
  cursor: pointer; /* [6] */
  border-color: var(--aura-c-button-color-border);
  border-style: solid;
  border-width: var(--aura-c-button-border-width, $button-border-width);
  border-radius: var(--aura-c-button-radius-border); /* [8] */
  border-collapse: separate; /* For IE */
  -webkit-box-shadow: none;
          box-shadow: none;
  transition: var(--aura-c-button-transition, $c-button-transition);
  @include appearance(none);
  white-space: normal;
  user-select: none;

  @include all-anchor-states() {
    color: var(--aura-c-button-color-text);
    text-decoration: none;
  }

  @include hocus() {
    outline: 0;
    cursor: pointer;
  }
  
  &:hover {
    color: var(--aura-c-button-color-text-hover);
    background-color: var(--aura-c-button-color-background-hover);
    border-color: var(--aura-c-button-color-border-hover);
    box-shadow: var(--aura-c-button-shadow-hover, none);
  }

  &:focus,
  &.is-input-btn:focus-within,
  &.c-btn--input-combo:focus-within {
    box-shadow: var(--aura-c-button-shadow-focus, $button-shadow-focus);
  } 

  &:active {
    color: var(--aura-c-button-color-text-active);
    background-color: var(--aura-c-button-color-background-active);
    border-color: var(--aura-c-button-color-border-active);
    box-shadow: var(--aura-c-button-shadow-active, none) !important;
  }

  &:disabled,
  &.is-disabled,
  &[disabled],
  &[disabled="disabled"] {
    transition: none;
    
    @include all-anchor-states() {
      color: var(--aura-c-button-color-text-disabled, var(--aura-c-button-color-text));
      cursor: default !important;
      background-image: none !important;
      background-color: var(--aura-c-button-color-background-disabled, var(--aura-c-button-color-background));
      border-color: var(--aura-c-button-color-border-disabled, var(--aura-c-button-color-border));
      box-shadow: none !important;
      opacity: var(--aura-c-button-opacity-disabled, $opacity-disabled);
    }

    * {
      pointer-events: none;
    }

  }

  a {
    color: currentColor;
  }

  @include mq($from: tablet) {
    // display: inline-block; /* [2] */
    // margin-left:    0; /* [5] */
    // margin-bottom:  0; /* [5] */
    // margin-right:   0; /* [5] */
    padding-top:    var(--aura-c-button-padding-block-start, 0);  /* [1] */
    padding-right:  var(--aura-c-button-padding-inline-end, $c-button-padding-inline);  /* [1] */
    padding-bottom: var(--aura-c-button-padding-block-end, 0);  /* [1] */
    padding-left:   var(--aura-c-button-padding-inline-start, $c-button-padding-inline);  /* [1] */
    width: auto;
    max-width: initial;
    // height: var(--aura-c-button-height, $c-button-height);
    line-height: var(--aura-c-button-height, $button-height);
    white-space: nowrap;
    // min-width: --aura-c-button-min-width;
    vertical-align: middle; /* [3] */
  }

  
  /**
   * When `c-btn` classes are on a <label> wrapping an input button
   * 1. Set pointer-events to none to fix issue with the parent container's 
   *    :pseudo or positioned elements interfering with the submmit action of the 
   *    input button.
   */
   &.is-input-btn {

    &,
    &:hover,
    &:active,
    .c-svg {
      pointer-events: none; /* [1] */
    }
    
  }

  // Add spacing when another button is adjacent to it in the same parent container.
  + .c-btn {
    @include mq($until: tablet) {
      margin-top: $spacing-small;
    }

    @include mq($from: tablet) {
      margin-left: $spacing-small;
    }
  }

}


// a.c-btn {
//   text-align: center;

//   &:not(.c-btn--neutral, .c-btn--inverse, .c-btn--outline-neutral, .c-btn--outline-inverse) {
//     &:focus {
//       outline: 0;
//       box-shadow: var(--aura-c-button-shadow-focus, $button-shadow-focus);
//     }
//   }
// }


// Set up an input type="button|submit|reset|search|file" to be styled inside a `.c-btn` container
input.c-btn,
.c-btn__input {
  @include appearance(none);
  outline: none;
}

.c-btn__input {
  @include position-center();
  display: block;
  width:  100%;
  height: 100%;
  margin: 0;
  padding: 0;
  color: inherit;
  font: inherit;
  letter-spacing: inherit;
  background: none;
  border: none;
  border-radius: var(--aura-c-button-radius-border, $button-border-radius);
  cursor: pointer;
  pointer-events: all;
  opacity: 0;
}




/* Style variants
   ========================================================================== */

// Reset button styles to browser default
.c-btn--reset {
  font-size: inherit;
  color: inherit;
  line-height: inherit;
  padding: 0;
  background: transparent;
  border: 0;
  text-align: inherit;
}


.c-btn--primary {
  --aura-c-button-color-background: var(--aura-c-button-primary-color-background, #{$button-primary-color-background});
  --aura-c-button-color-background-hover: var(--aura-c-button-primary-color-background-hover, #{$button-primary-color-background-hover});
  --aura-c-button-color-background-active: var(--aura-c-button-primary-color-background-active, #{$button-primary-color-background-active});

  --aura-c-button-color-border: var(--aura-c-button-primary-color-border, #{$button-primary-color-border});
  --aura-c-button-color-border-hover: var(--aura-c-button-primary-color-border-hover, #{$button-primary-color-border-hover});
  --aura-c-button-color-border-active: var(--aura-c-button-primary-color-border-active, #{$button-primary-color-border-active});

  --aura-c-button-color-text: var(--aura-c-button-primary-text-color, #{$button-primary-color-text});
  --aura-c-button-color-text-hover: var(--aura-c-button-primary-text-color-hover, #{$button-primary-color-text-hover});
  --aura-c-button-color-text-active: var(--aura-c-button-primary-text-color-active, #{$button-primary-color-text-active});

  /* @TODO 
      Is it better to use the custom props to change styles or keep the below CSS?
  */

  // @include all-anchor-states() {
  //   color: var(--aura-c-button-primary-text-color, $button-primary-color-text);
  // }

  // @include hocus() {
  //   background-color: var(--aura-c-button-primary-color-background-hover, $button-primary-color-background-hover);
  //   border-color: var(--aura-c-button-primary-color-border-hover, $button-primary-color-border-hover);
  //   color: var(--aura-c-button-primary-text-color-hover, $button-primary-color-text);
  // }

  // &:active {
  //   background-color: var(--aura-c-button-primary-color-background-active, $button-primary-color-background-active);
  //   border-color: var(--aura-c-button-primary-color-border-active, $button-primary-color-border-active);
  //   color: var(--aura-c-button-primary-text-color-active, $button-primary-color-text);
  // }

  // &:disabled,
  // &.is-disabled,
  // &[disabled],
  // &[disabled="disabled"] {

  //   @include all-anchor-states() {
  //     background-color: var(--aura-c-button-primary-color-background-disabled, $button-primary-color-background);
  //     border-color: var(--aura-c-button-primary-color-border-disabled, $button-primary-color-border);
  //     color: var(--aura-c-button-primary-text-color-disabled, $button-primary-color-text);
  //   }

  // }

}


.c-btn--secondary, /* Deprecated class name */
.c-btn--outline {
  --aura-c-button-color-background: var(--aura-c-button-outline-color-background, #{$button-outline-color-background});
  --aura-c-button-color-background-hover: var(--aura-c-button-outline-color-background-hover, #{$button-outline-color-background-hover});
  --aura-c-button-color-background-active: var(--aura-c-button-outline-color-background-active, #{$button-outline-color-background-active});

  --aura-c-button-color-border: var(--aura-c-button-outline-color-border, #{$button-outline-color-border});
  --aura-c-button-color-border-hover: var(--aura-c-button-outline-color-border-hover, #{$button-outline-color-border-hover});
  --aura-c-button-color-border-active: var(--aura-c-button-outline-color-border-active, #{$button-outline-color-border-active});

  --aura-c-button-color-text: var(--aura-c-button-outline-text-color, #{$button-outline-color-text});
  --aura-c-button-color-text-hover: var(--aura-c-button-outline-text-color-hover, #{$button-outline-color-text-hover});
  --aura-c-button-color-text-active: var(--aura-c-button-outline-text-color-active, #{$button-outline-color-text-active});

  // background-color: var(--aura-c-button-outline-color-background, transparent);
  // border-color: var(--aura-c-button-outline-color-border, $button-outline-color-border);

  // @include all-anchor-states() {
  //   color: var(--aura-c-button-outline-text-color, $button-outline-color-text);
  // }

  // &:hover,
  // &:focus,
  // &.is-input-btn:focus-within,
  // &.c-btn--input-combo:focus-within {
  //   background-color: var(--aura-c-button-outline-color-background-hover, transparent);
  //   border-color: var(--aura-c-button-outline-color-border-hover, $button-outline-color-border-hover);
  //   color: var(--aura-c-button-outline-text-color-hover, $button-outline-color-text);
  // }

  // &:active {
  //   background-color: var(--aura-c-button-outline-color-background-active, transparent);
  //   border-color: var(--aura-c-button-outline-color-border-active, $button-outline-color-border-active);
  //   color: var(--aura-c-button-outline-text-color-active, $button-outline-color-text);
  // }

  border-collapse: separate; /* For IE */

  &:hover {
    box-shadow: var(--aura-c-button-outline-shadow-hover, $button-outline-inset-shadow-hover);
  }

  &:disabled,
  &[disabled],
  &.is-disabled,
  &[disabled="disabled"] {
    @include all-anchor-states() {
      // background-color: var(--aura-c-button-outline-color-background-disabled, transparent);
      // border-color: var(--aura-c-button-outline-color-border-disabled, $button-outline-color-border);
      // color: var(--aura-c-button-outline-text-color-disabled, $button-outline-color-text);

      // Hide inner border
      &::before {
        display: none;
      }

    }
  }


}


.c-btn--neutral {
  --aura-c-button-color-background: var(--aura-c-button-neutral-color-background, #{$button-neutral-color-background});
  --aura-c-button-color-background-hover: var(--aura-c-button-neutral-color-background-hover, #{$button-neutral-color-background-hover});
  --aura-c-button-color-background-active: var(--aura-c-button-neutral-color-background-active, #{$button-neutral-color-background-active});

  --aura-c-button-color-border: var(--aura-c-button-neutral-color-border, #{$button-neutral-color-border});
  --aura-c-button-color-border-hover: var(--aura-c-button-neutral-color-border-hover, #{$button-neutral-color-border-hover});
  --aura-c-button-color-border-active: var(--aura-c-button-neutral-color-border-active, #{$button-neutral-color-border-active});

  --aura-c-button-color-text: var(--aura-c-button-neutral-text-color, #{$button-neutral-color-text});
  --aura-c-button-color-text-hover: var(--aura-c-button-neutral-text-color-hover, #{$button-neutral-color-text-hover});
  --aura-c-button-color-text-active: var(--aura-c-button-neutral-text-color-active, #{$button-neutral-color-text-active});

  // background-color: var(--aura-c-button-neutral-color-background, $button-neutral-color-background);
  // border-color: var(--aura-c-button-neutral-color-border, $button-neutral-color-border);

  // @include all-anchor-states() {
  //   color: var(--aura-c-button-neutral-text-color, $button-neutral-color-text);
  // }

  // &:hover,
  // &:focus,
  // &.is-input-btn:focus-within,
  // &.c-btn--input-combo:focus-within {
  //   background-color: var(--aura-c-button-neutral-color-background-hover, $button-neutral-color-background-hover);
  //   border-color: var(--aura-c-button-neutral-color-border-hover, $button-neutral-color-border-hover);
  //   color: var(--aura-c-button-neutral-text-color-hover, $button-neutral-color-text);
  // }

  // &:active {
  //   background-color: var(--aura-c-button-neutral-color-background-active, $button-neutral-color-background-active);
  //   border-color: var(--aura-c-button-neutral-color-border-active, $button-neutral-color-border-active);
  //   color: var(--aura-c-button-neutral-text-color-active, $button-neutral-color-text);
  // }

  // &:disabled,
  // &.is-disabled,
  // &[disabled],
  // &[disabled="disabled"] {

  //   @include all-anchor-states() {
  //     background-color: var(--aura-c-button-neutral-color-background-disabled, $button-neutral-color-background);
  //     border-color: var(--aura-c-button-neutral-color-border-disabled, $button-neutral-color-border);
  //     color: var(--aura-c-button-neutral-text-color-disabled, $button-neutral-color-text);
  //   }

  // }

}


.c-btn--outline-neutral {
  --aura-c-button-color-background: var(--aura-c-button-outline-neutral-color-background, #{$button-outline-neutral-color-background});
  --aura-c-button-color-background-hover: var(--aura-c-button-outline-neutral-color-background-hover, #{$button-outline-neutral-color-background-hover});
  --aura-c-button-color-background-active: var(--aura-c-button-outline-neutral-color-background-active, #{$button-outline-neutral-color-background-active});

  --aura-c-button-color-border: var(--aura-c-button-outline-neutral-color-border, #{$button-outline-neutral-color-border});
  --aura-c-button-color-border-hover: var(--aura-c-button-outline-neutral-color-border-hover, #{$button-outline-neutral-color-border-hover});
  --aura-c-button-color-border-active: var(--aura-c-button-outline-neutral-color-border-active, #{$button-outline-neutral-color-border-active});

  --aura-c-button-color-text: var(--aura-c-button-outline-neutral-text-color, #{$button-outline-neutral-color-text});
  --aura-c-button-color-text-hover: var(--aura-c-button-outline-neutral-text-color-hover, #{$button-outline-neutral-color-text-hover});
  --aura-c-button-color-text-active: var(--aura-c-button-outline-neutral-text-color-active, #{$button-outline-neutral-color-text-active});

  // background-color: var(--aura-c-button-outline-neutral-color-background, transparent);
  // border-color: var(--aura-c-button-outline-neutral-color-border, $button-neutral-color-border);

  // @include all-anchor-states() {
  //   color: var(--aura-c-button-outline-neutral-text-color, $button-outline-neutral-color-text);
  // }

  // &:hover,
  // &:focus,
  // &.is-input-btn:focus-within,
  // &.c-btn--input-combo:focus-within {
  //   background-color: var(--aura-c-button-outline-color-background-hover, transparent);
  //   border-color: var(--aura-c-button-outline-neutral-color-border-hover, $button-neutral-color-border-hover);
  //   color: var(--aura-c-button-outline-neutral-text-color-hover, $button-outline-neutral-color-text);
  // }

  // &:active {
  //   background-color: var(--aura-c-button-outline-neutral-color-background-active, transparent);
  //   border-color: var(--aura-c-button-outline-neutral-color-border-active, $button-neutral-color-border-active);
  //   color: var(--aura-c-button-outline-neutral-text-color-active, $button-outline-neutral-color-text);
  // }

  &:disabled,
  &[disabled],
  &.is-disabled,
  &[disabled="disabled"] {
    @include all-anchor-states() {
      // background-color: var(--aura-c-button-outline-neutral-color-background-disabled, transparent);
      // border-color: var(--aura-c-button-outline-neutral-color-border-disabled, $button-neutral-color-border);
      // color: var(--aura-c-button-outline-neutral-text-color-disabled, $button-outline-neutral-color-text);

      // Hide inner border
      &::before {
        display: none;
      }

    }
  }

  &:hover {
    box-shadow: var(--aura-c-button-outline-neutral-shadow-hover, $button-outline-neutral-inset-shadow-hover);
  }
  
}

.c-btn--neutral,
.c-btn--outline-neutral {

  &:focus,
  &.is-input-btn:focus-within,
  &.c-btn--input-combo:focus-within {
    box-shadow: var(--aura-c-button-neutral-shadow-focus, $button-neutral-shadow);
  }
  &:active {
    box-shadow: var(--aura-c-button-neutral-shadow-active, none);
  }
}

// Looks like text but treated like a button
.c-btn--text,
.c-btn--text-inverse {
  margin: 0;
  padding: 0;
  width: auto;
  letter-spacing: initial;
  font-weight: var(--aura-c-button-text-font-weight, $font-weight-medium);
  border-radius: $border-radius-small;
}

.c-btn--text {
  --aura-c-button-color-background-hover: transparent;
  --aura-c-button-color-border-hover: transparent;

  // transition: $text-color-transition;

  // &.is-back-btn {
  //   display: inline-block;
  //   margin: 0;
  //   padding: 0;
  // }

  // &.is-inline {
  //   height: auto;
  //   line-height: inherit;
  // }

  &.has-icon-bubble {
    padding: 0;
    padding-left: var(--aura-c-button-text-padding-inline-icon, $spacing-large);
    font-weight: var(--aura-c-button-font-weight, $font-weight-button);
    max-width: fit-content;

    @include all-anchor-states() {
      color: var(--aura-c-button-text-text-color, $color-text-default);
    }
    
    @include hocus() {
      color: var(--aura-c-button-text-text-color-hover, $color-black);
    }
  }

}

.c-btn__icon-bubble {
  display: block;
  @include position-y-center();
  left: 0;
  width:  var(--aura-c-button-icon-bubble-boundary, $round-icon-boundary);
  height: var(--aura-c-button-icon-bubble-boundary, $round-icon-boundary);
  background-color: var(--aura-c-button-icon-bubble-color-background, $resmed-brand-gray-palette-50);
  border-radius: $border-radius-circle;
  transition: $background-color-transition;

  .c-svg {
    @include position-center();
    width:  var(--aura-c-button-icon-size, $icon-size-default);
    height: var(--aura-c-button-icon-size, $icon-size-default);
    color: currentColor;
    background-color: transparent;
  }

  @at-root .c-btn--text:hover #{&} {
    background-color: var(--aura-c-button-text-icon-bubble-background-color-hover, $button-primary-color-background-hover);
    color: var(--aura-c-button-text-icon-bubble-background-color-hover, $color-white);
  }
}

.c-btn--skip-to-content {
  @include mq($until: desktop) {
    display: none;
  }

  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -201%);
  transition: transform $duration-promptly;
  z-index: $z-index-dialog;

  &:focus {
    transform: translate(-50%, 24px);
  }

  &.c-btn--secondary,
  &.c-btn--tertiary {
    background-color: var(--aura-c-button-skip-color-background, $color-white);
  }
}



/**
 * An icon only button butted up to a form input, like a search control.
 *
 * 1. Reset inherited `c-btn` styles that will mess up the combo button.
 * 2. Default button width that fits an icon.
 * 3. Match the height of input fields.
 * 4. Hide any button text that might be there for screen-readers etc.
 *    put it in a `span` tag.
 *
 * HTML:

    <div class="c-form-field">
      <label class="c-form-field__label">Search</label>
      <div class="c-form-field__input c-form-field--btn-combo">
        <input type="text" name="text" value="" class="c-input c-input--btn-combo" />

        <label class="c-btn c-btn--primary c-btn--input-combo" for="btn-search">
          <svg class="c-svg c-svg--search-24" aria-labelledby="svg-title">
            <title id="svg-title">Search</title>
            <use xlink:href="#c-svg--search-24"></use>
          </svg>
          <input id="btn-search" type="submit" value="Search" class="c-btn__input" />
        </label>
      </div>
    </div>

 */
.c-btn--input-combo {
  display: inline-block; /* [1] */
  margin: 0;    /* [1] */
  padding: 0;
  width: 40px;  /* [2] */
  height: $input-field-height; /* [3] */
  border-top-left-radius:    0;
  border-bottom-left-radius: 0;
  border-top-right-radius:    $border-radius-small;
  border-bottom-right-radius: $border-radius-small;
  vertical-align: top;
  line-height: 2.75em;
  flex-shrink: 0;
  span {
    display: none;
    @include hide-text(); /* [4] */
  }

  .c-svg {
    @include position-center();
    width:  var(--aura-c-button-icon-size, $icon-size-default);
    height: var(--aura-c-button-icon-size, $icon-size-default);
  }

  &:hover {
    .c-svg {
      color: currentColor;
    }
  }

}





/* Inverse buttons
   ========================================================================== */
/**
 * Inverse buttons are used on dark backgrounds.
 */
.c-btn--inverse {
  --aura-c-button-color-background: var(--aura-c-button-inverse-color-background, #{$button-inverse-color-background});
  --aura-c-button-color-background-hover: var(--aura-c-button-inverse-color-background-hover, #{$button-inverse-color-background-hover});
  --aura-c-button-color-background-active: var(--aura-c-button-inverse-color-background-active, #{$button-inverse-color-background-active});

  --aura-c-button-color-border: var(--aura-c-button-inverse-color-border, #{$button-inverse-color-border});
  --aura-c-button-color-border-hover: var(--aura-c-button-inverse-color-border-hover, #{$button-inverse-color-border-hover});
  --aura-c-button-color-border-active: var(--aura-c-button-inverse-color-border-active, #{$button-inverse-color-border-active});

  --aura-c-button-color-text: var(--aura-c-button-inverse-text-color, #{$button-inverse-color-text});
  --aura-c-button-color-text-hover: var(--aura-c-button-inverse-text-color-hover, #{$button-inverse-color-text-hover});
  --aura-c-button-color-text-active: var(--aura-c-button-inverse-text-color-active, #{$button-inverse-color-text-active});

  // &,
  // &:link,
  // &:visited {
  //   background-color: var(--aura-c-button-inverse-color-background, $button-inverse-color-background);
  //   border-color: var(--aura-c-button-inverse-color-border, $button-inverse-color-border);
  //   color: var(--aura-c-button-inverse-text-color, $button-inverse-color-text-primary);
  // }

  // &:hover,
  // &:focus,
  // &.is-input-btn:focus-within,
  // &.c-btn--input-combo:focus-within {
  //   background-color: var(--aura-c-button-inverse-color-background-hover, $button-inverse-color-background-hover);
  //   border-color: var(--aura-c-button-inverse-color-border-hover, $button-inverse-color-border-hover);
  //   color: var(--aura-c-button-inverse-text-color-hover, $button-inverse-color-text-primary);
  // }

  // &:active{
  //   background-color: var(--aura-c-button-inverse-color-background-active, $button-inverse-color-background-active);
  //   border-color: var(--aura-c-button-inverse-color-border-active, $button-inverse-color-border-active);
  //   color: var(--aura-c-button-inverse-text-color-active, $button-inverse-color-text-primary);
  // }

  // &:disabled,
  // &[disabled],
  // &.is-disabled,
  // &[disabled="disabled"] {

  //   @include all-anchor-states() {
  //     background-color: var(--aura-c-button-inverse-color-background, $button-inverse-color-background);
  //     border-color: var(--aura-c-button-inverse-color-border, $button-inverse-color-border);
  //     color: var(--aura-c-button-inverse-text-color, $button-inverse-color-text-primary);
  //   }

  // }

  &:focus,
  &.is-input-btn:focus-within,
  &.c-btn--input-combo:focus-within {
    box-shadow: var(--aura-c-button-inverse-shadow-focus, $button-inverse-shadow);
  }
  &:active {
    box-shadow: var(--aura-c-button-inverse-shadow-active, none);
  }
}


.c-btn--primary-inverse {
  --aura-c-button-color-background: var(--aura-c-button-primary-inverse-color-background, #{$button-primary-inverse-color-background});
  --aura-c-button-color-background-hover: var(--aura-c-button-primary-inverse-color-background-hover, #{$button-primary-inverse-color-background-hover});
  --aura-c-button-color-background-active: var(--aura-c-button-primary-inverse-color-background-active, #{$button-primary-inverse-color-background-active});

  --aura-c-button-color-border: var(--aura-c-button-primary-inverse-color-border, #{$button-primary-inverse-color-border});
  --aura-c-button-color-border-hover: var(--aura-c-button-primary-inverse-color-border-hover, #{$button-primary-inverse-color-border-hover});
  --aura-c-button-color-border-active: var(--aura-c-button-primary-inverse-color-border-active, #{$button-primary-inverse-color-border-active});

  --aura-c-button-color-text: var(--aura-c-button-primary-inverse-text-color, #{$button-primary-inverse-color-text});
  --aura-c-button-color-text-hover: var(--aura-c-button-primary-inverse-text-color-hover, #{$button-primary-inverse-color-text-hover});
  --aura-c-button-color-text-active: var(--aura-c-button-primary-inverse-text-color-active, #{$button-primary-inverse-color-text-active});

  &:focus,
  &.is-input-btn:focus-within,
  &.c-btn--input-combo:focus-within {
    box-shadow: var(--aura-c-button-primary-inverse-shadow-focus, $button-inverse-shadow);
  }
  &:active {
    box-shadow: var(--aura-c-button-primary-inverse-shadow-active, none);
  }
}


.c-btn--outline-inverse {
  --aura-c-button-color-background: var(--aura-c-button-outline-inverse-color-background, #{$button-outline-inverse-color-background});
  --aura-c-button-color-background-hover: var(--aura-c-button-outline-inverse-color-background-hover, #{$button-outline-inverse-color-background-hover});
  --aura-c-button-color-background-active: var(--aura-c-button-outline-inverse-color-background-active, #{$button-outline-inverse-color-background-active});

  --aura-c-button-color-border: var(--aura-c-button-outline-inverse-color-border, #{$button-outline-inverse-color-border});
  --aura-c-button-color-border-hover: var(--aura-c-button-outline-inverse-color-border-hover, #{$button-outline-inverse-color-border-hover});
  --aura-c-button-color-border-active: var(--aura-c-button-outline-inverse-color-border-active, #{$button-outline-inverse-color-border-active});

  --aura-c-button-color-text: var(--aura-c-button-outline-inverse-text-color, #{$button-outline-inverse-color-text});
  --aura-c-button-color-text-hover: var(--aura-c-button-outline-inverse-text-color-hover, #{$button-outline-inverse-color-text-hover});
  --aura-c-button-color-text-active: var(--aura-c-button-outline-inverse-text-color-active, #{$button-outline-inverse-color-text-active});

  // &,
  // &:link,
  // &:visited {
  //   background-color: var(--aura-c-button-outline-inverse-color-background, transparent);
  //   border-color: var(--aura-c-button-outline-inverse-color-border, $button-outline-inverse-color-border);
  //   color: var(--aura-c-button-outline-inverse-text-color, $button-outline-inverse-color-text);
  // }

  // &:hover,
  // &:active,
  // &:focus,
  // &.is-input-btn:focus-within,
  // &.c-btn--input-combo:focus-within {
  //   background-color: var(--aura-c-button-outline-inverse-color-background-hover, transparent);
  //   border-color: var(--aura-c-button-outline-inverse-color-border-hover, $button-outline-inverse-color-border-hover);
  //   color: var(--aura-c-button-outline-inverse-text-color-hover, $button-outline-inverse-color-text);
  // }

  // &:active {
  //   background-color: var(--aura-c-button-outline-inverse-color-background-active, transparent);
  //   border-color: var(--aura-c-button-outline-inverse-color-border-active, $button-outline-inverse-color-border-active);
  //   color: var(--aura-c-button-outline-inverse-text-color-active, $button-outline-inverse-color-text);
  // }

  // &:disabled,
  // &[disabled],
  // &.is-disabled,
  // &[disabled="disabled"] {

  //   @include all-anchor-states() {
  //     background-color: var(--aura-c-button-outline-inverse-color-background, transparent);
  //     border-color: var(--aura-c-button-outline-inverse-color-border, $button-outline-inverse-color-border);
  //     color: var(--aura-c-button-outline-inverse-text-color, $button-outline-inverse-color-text);
  //   }

  // }

  border-collapse: separate; /* For IE */
  
  &:hover {
    box-shadow: var(--aura-c-button-outline-inverse-shadow-hover, $button-outline-inverse-inset-shadow-hover);
  }
  
  &:focus,
  &.is-input-btn:focus-within,
  &.c-btn--input-combo:focus-within {
    box-shadow: var(--aura-c-button-outline-inverse-shadow-focus, $button-inverse-shadow);
  }
  &:active {
    box-shadow: var(--aura-c-button-outline-inverse-shadow-active, none);
  }
}


.c-btn--neutral-inverse {
  --aura-c-button-color-background: var(--aura-c-button-neutral-inverse-color-background, #{$button-neutral-inverse-color-background});
  --aura-c-button-color-background-hover: var(--aura-c-button-neutral-inverse-color-background-hover, #{$button-neutral-inverse-color-background-hover});
  --aura-c-button-color-background-active: var(--aura-c-button-neutral-inverse-color-background-active, #{$button-neutral-inverse-color-background-active});

  --aura-c-button-color-border: var(--aura-c-button-neutral-inverse-color-border, #{$button-neutral-inverse-color-border});
  --aura-c-button-color-border-hover: var(--aura-c-button-neutral-inverse-color-border-hover, #{$button-neutral-inverse-color-border-hover});
  --aura-c-button-color-border-active: var(--aura-c-button-neutral-inverse-color-border-active, #{$button-neutral-inverse-color-border-active});

  --aura-c-button-color-text: var(--aura-c-button-neutral-inverse-text-color, #{$button-neutral-inverse-color-text});
  --aura-c-button-color-text-hover: var(--aura-c-button-neutral-inverse-text-color-hover, #{$button-neutral-inverse-color-text-hover});
  --aura-c-button-color-text-active: var(--aura-c-button-neutral-inverse-text-color-active, #{$button-neutral-inverse-color-text-active});

  // &,
  // &:link,
  // &:visited {
  //   background-color: var(--aura-c-button-neutral-inverse-color-background, $button-neutral-inverse-color-background);
  //   border-color: var(--aura-c-button-neutral-inverse-color-border, $button-neutral-inverse-color-border);
  //   color: var(--aura-c-button-neutral-inverse-text-color, $button-neutral-inverse-color-text);
  // }

  // &:hover,
  // &:focus,
  // &.is-input-btn:focus-within,
  // &.c-btn--input-combo:focus-within {
  //   background-color: var(--aura-c-button-neutral-inverse-color-background-hover, $button-neutral-inverse-color-background-hover);
  //   border-color: var(--aura-c-button-neutral-inverse-color-border-hover, $button-neutral-inverse-color-border-hover);
  //   color: var(--aura-c-button-inverse-neutral-text-color-hover, $button-neutral-inverse-color-text);
  // }

  // &:active{
  //   background-color: var(--aura-c-button-neutral-inverse-color-background-active, $button-neutral-inverse-color-background-active);
  //   border-color: var(--aura-c-button-neutral-inverse-color-border-active, $button-neutral-inverse-color-border-active);
  //   color: var(--aura-c-button-neutral-inverse-text-color-active, $button-neutral-inverse-color-text);
  // }

  // &:disabled,
  // &[disabled],
  // &.is-disabled,
  // &[disabled="disabled"] {

  //   @include all-anchor-states() {
  //     background-color: var(--aura-c-button-neutral-inverse-color-background, $button-neutral-inverse-color-background);
  //     border-color: var(--aura-c-button-neutral-inverse-color-border, $button-neutral-inverse-color-border);
  //     color: var(--aura-c-button-neutral-inverse-text-color, $button-neutral-inverse-color-text);
  //   }

  // }

  &:focus,
  &.is-input-btn:focus-within,
  &.c-btn--input-combo:focus-within {
    box-shadow: var(--aura-c-button-neutral-inverse-shadow-focus, $button-inverse-shadow);
  }
  &:active {
    box-shadow: var(--aura-c-button-neutral-inverse-shadow-active, none);
  }
}


.c-btn--text-inverse {
  --aura-c-button-color-background: var(--aura-c-button-text-inverse-color-background, #{$button-text-inverse-color-background});
  --aura-c-button-color-background-hover: var(--aura-c-button-text-inverse-color-background-hover, #{$button-text-inverse-color-background-hover});
  --aura-c-button-color-background-active: var(--aura-c-button-text-inverse-color-background-active, #{$button-text-inverse-color-background-active});

  --aura-c-button-color-border: var(--aura-c-button-text-inverse-color-border, #{$button-text-inverse-color-border});
  --aura-c-button-color-border-hover: var(--aura-c-button-text-inverse-color-border-hover, #{$button-text-inverse-color-border-hover});
  --aura-c-button-color-border-active: var(--aura-c-button-text-inverse-color-border-active, #{$button-text-inverse-color-border-active});

  --aura-c-button-color-text: var(--aura-c-button-text-inverse-text-color, #{$button-text-inverse-color-text});
  --aura-c-button-color-text-hover: var(--aura-c-button-text-inverse-text-color-hover, #{$button-text-inverse-color-text-hover});
  --aura-c-button-color-text-active: var(--aura-c-button-text-inverse-text-color-active, #{$button-text-inverse-color-text-active});

  // &,
  // &:link,
  // &:visited {
  //   color: var(--aura-c-button-text-inverse-text-color, $button-text-inverse-color-text);
  // }

  // &:hover,
  // &:active,
  // &:focus {
  //   color: var(--aura-c-button-text-inverse-text-color-hover, rgba($button-text-inverse-color-text, $opacity-8));
  // }

  // &:disabled,
  // &.is-disabled,
  // &[disabled="disabled"] {

  //   @include all-anchor-states() {
  //     color: var(--aura-c-button-text-inverse-text-color, $button-text-inverse-color-text);
  //   }

  // }

  &:focus,
  &.is-input-btn:focus-within,
  &.c-btn--input-combo:focus-within {
    box-shadow: var(--aura-c-button-text-inverse-shadow-focus, $button-inverse-shadow);
  }
  &:active {
    box-shadow: var(--aura-c-button-text-inverse-shadow-active, none);
  }
}




/* Buttons with an icon
   ========================================================================== */
/**
 * 1. Reset pointer-events on submit button.
 * 2. Icons are placed on the right by default, we need to add extra padding to
 *    account for the positioning of the icon.
 * 3. We have to do some negative margin trickery so our input button looks
 *    and interacts like any other button.
 * 4. Then re-add the padding to the input element.
 *
 * HTML

  Anchor:
    <a href="#0" id="btnLinkIcon" class="c-btn c-btn--primary has-icon" role="button">
      <svg class="c-svg c-svg--arrow-right-24" aria-hidden="true" focusable="false">
        <use xlink:href="#c-svg--arrow-right-24"></use>
      </svg>
      Link
    </a>

  Button:
    <button id="btnButtonIcon" class="c-btn c-btn--secondary has-icon">
      <svg class="c-svg c-svg--add-24" aria-hidden="true" focusable="false">
        <use xlink:href="#c-svg--add-24"></use>
      </svg>
      Button
    </button>

  Input:
    <label class="c-btn c-btn--primary has-icon is-input-btn" for="btnSubmitIcon">
      <svg class="c-svg c-svg--add-24" aria-hidden="true" focusable="false">
        <use xlink:href="#c-svg--add-24"></use>
      </svg>
      Submit
      <input id="btnSubmitIcon" type="submit" value="Submit" class="c-btn__input" />
    </label>

 *
 */
.c-btn {

  &:not(.c-btn--text):not(.c-btn--text-inverse) {

    // Icons sould be to the right of the text by default,
    // but sometimes you might need it on the left.
    &.has-icon,
    &.has-icon-left {
  
      .c-svg {
        // @include position-y-center();
        top: 0;
        width:  var(--aura-c-button-icon-size, $icon-size-default);
        height: var(--aura-c-button-icon-size, $icon-size-default);
        color: currentColor;
        background-color: transparent;
      }

    }
  
    &.has-icon {
      
      .c-svg {
        margin-left: var(--aura-c-button-icon-inline-spacing, $c-button-icon-inline-spacing); /* [2] */
        order: 1;
      }
  
    }
  
    &.has-icon-left {
      
      .c-svg {
        margin-right: var(--aura-c-button-icon-inline-spacing, $c-button-icon-inline-spacing); /* [2] */
        order: 0;
      }
  
    }

  }

  &.c-btn--text,
  &.c-btn--text-inverse {

    &:not(.is-back-btn):not(.is-next-btn):not(.has-icon-bubble) {
     
      &.has-icon,
      &.has-icon-left {
        .c-svg {
          top: 0;
          width:  var(--aura-c-button-icon-size, $icon-size-default);
          height: var(--aura-c-button-icon-size, $icon-size-default);
        }
      }
  
      &.has-icon {
        .c-svg {
          margin-left: $spacing-tiny;
        }
      }
  
      &.has-icon-left {
        .c-svg {
          margin-right: $spacing-tiny;
        }
      }

    }

    &.is-back-btn,
    &.is-next-btn {
      .c-svg {
        position: absolute;
        top: 50%;
        width:  $icon-size-small;
        height: $icon-size-small;
        color: currentColor;
        @include transform(translateX(0) translateY(-50%));
        transition: 0.3s transform ease-out;
      }
    }

    &.is-back-btn {
      padding-left: $spacing-default;
  
      .c-svg {
        left: 0;
        @include transform(translateX(0) translateY(-50%));
      }

      &:hover {
        .c-svg {
          @include transform(translateX(-3px) translateY(-50%));
        }
      }
  
    }

    &.is-next-btn {
      padding-right: $spacing-default;
  
      .c-svg {
        right: 0;
        @include transform(translateX(0) translateY(-50%));
      }
  
      &:hover {
        .c-svg {
          @include transform(translateX(3px) translateY(-50%));
        }
      }
  
    }

  }

}




/* Size variants
   ========================================================================== */
.c-btn--auto {
  width: auto;
  min-width: unset;
  max-width: unset;
}

.c-btn--md,
.c-btn--sm,
.c-btn--xsm {
  width: auto;
}

.c-btn--md {
  padding-left:   var(--aura-c-button-md-padding-inline-start, $c-button-md-padding-inline);
  padding-right:  var(--aura-c-button-md-padding-inline-end, $c-button-md-padding-inline);
  @include font-size($c-button-md-font-size, 1);

  @include mq($from: tablet) {
    line-height: var(--aura-c-button-md-height, #{$c-button-md-height});
  }
}

.c-btn--sm {
  padding-left:   var(--aura-c-button-sm-padding-inline-start, $c-button-sm-padding-inline);
  padding-right:  var(--aura-c-button-sm-padding-inline-end, $c-button-sm-padding-inline);
  @include font-size($c-button-sm-font-size, 1);
  
  @include mq($until: tablet) {
    padding-top: 7px;
    padding-bottom: 7px;
  }
  @include mq($from: tablet) {
    line-height: var(--aura-c-button-sm-height, #{$c-button-sm-height});
  }
}

.c-btn--xsm {
  padding-left:   var(--aura-c-button-xsm-padding-inline-start, $c-button-xsm-padding-inline);
  padding-right:  var(--aura-c-button-xsm-padding-inline-end, $c-button-xsm-padding-inline);
  @include font-size($c-button-xsm-font-size, 1);
  
  @include mq($until: tablet) {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  @include mq($from: tablet) {
    line-height: var(--aura-c-button-xsm-height, #{$c-button-xsm-height});
  }
}



// We might want certain buttons to be of the same width, or minimum width,
// such as form submit buttons that might have text of varying length, like:
// "Save", "Submit", "Done", "Ok", you get the idea.
.c-btn--min-width {

  @include mq($from: tablet) {
    min-width: var(--aura-c-button-min-width);
    min-width: var(--aura-c-button-min-width, $c-button-min-width);
  }

}

.c-btn--short {

  @include mq($from: tablet) {
    width: var(--aura-c-button-short-width, $c-button-width-short);
  }

}

.c-btn--wide {

  @include mq($from: tablet) {
    width: var(--aura-c-button-wide-width, $c-button-width-wide);
  }

}

// Make the button be the full width of it's parent container.
.c-btn--full {
  &,
  .c-btn__input {
    width: 100%;
    max-width: 100%;
  }
}

.c-btn--tight {
  padding-left:  var(--aura-c-button-tight-padding-inline-start, $spacing-default);
  padding-right: var(--aura-c-button-tight-padding-inline-end, $spacing-default);
}

// .c-btn--tight,
// .c-btn--small,
// .c-btn--large {

//   &.has-icon {
//     padding-left: --aura-c-button-padding-icon;
//   }

//   &.has-icon-left {
//     padding-right: --aura-c-button-padding-icon;
//   }

// }