/* ==========================================================================
  #ACCESSIBILITY
  ========================================================================== */
// Some classes used for accessibility

// Text meant only for screen readers.
.u-screen-reader-text, /* Deprecated class name */
.u-sr-only {
  position: absolute;
  clip-path: inset(50%);
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  word-wrap: normal !important;
  clip: rect(1px, 1px, 1px, 1px);

  &:focus {
      display: block;
      top: 5px;
      left: 5px;
      z-index: 100000; // Above WP toolbar
      clip-path: none;
      background-color: #eee;
      padding: 15px 23px 14px;
      width: auto;
      height: auto;
      text-decoration: none;
      line-height: normal;
      color: #444;
      font-size: 1em;
      clip: auto !important;
  }
}