/* ========================================================================
   #SPACING
   ======================================================================== */
/**
 * Spacing tokens

  category: spacing
  type: size
  cssProperties:
  - top
  - right
  - bottom
  - left
  - 'margin*'
  - 'padding*'

 */

/**
 * ATTENTION
 *
 * Usage:
 * Until the design token pipeline is in place, find the file
 *  `_themes.custom-tokens.scss` 
 * in the themes folder and rename it to 
 *  `_themes.custom-{product-name}-tokens.scss`
 *
 * You will use this file to override !default token values as needed.
 */


/**
 * DO NOT EDIT ANYTHING BELOW 
 * Override default token values in YOUR custom tokens file.
 */
$spacing-micro:       3px !default;
$spacing-tiny:        6px !default;
$spacing-small:       12px !default;
$spacing-md-sm:       16px !default;
$spacing-default:     24px !default;
$spacing-medium:      36px !default;
$spacing-large:       48px !default;
$spacing-x-large:     72px !default;
$spacing-xx-large:    96px !default;
$spacing-none:        0 !default;

// $offset-none:         "0" !default;
// $offset-x-small:      2 !default;
// $offset-small:        3 !default;
// $offset-medium:       4 !default;
// $offset-large:        8 !default;
// $offset-x-large:      16 !default;
// $offset-xx-large:     32 !default;

$icon-position-top-center:    0.25em !default;
$icon-position-top-baseline:  0.125em !default;
