/* ========================================================================
   #RADIUS
   ======================================================================== */
/**
 * Radius tokens

  type: size
  category: radius
  cssProperties:
    - 'border*radius'

 */

/**
 * ATTENTION
 *
 * Usage:
 * Until the design token pipeline is in place, find the file
 *  `_themes.custom-tokens.scss` 
 * in the themes folder and rename it to 
 *  `_themes.custom-{product-name}-tokens.scss`
 *
 * You will use this file to override !default token values as needed.
 */


/**
 * DO NOT EDIT ANYTHING BELOW 
 * Override default token values in YOUR custom tokens file.
 */

$border-radius-small:       3px !default;
$border-radius-medium:      6px !default;
$border-radius-large:       12px !default;
$border-radius-circle:      50% !default;
$border-radius-pill:        15rem !default;

$alert-border-radius:       $border-radius-small !default;
$toast-border-radius:       0 !default;
$badge-border-radius:       $border-radius-pill !default;
$button-border-radius:      $border-radius-small !default;
$card-border-radius:        $border-radius-medium !default;
$dialog-border-radius:      $border-radius-medium !default;
$form-input-border-radius:  $border-radius-small !default;
$tooltip-border-radius:     $border-radius-small !default;
$pagination-radius-border:  $border-radius-small !default;
$popover-border-radius:     $border-radius-medium !default;
$chip-border-radius:        $border-radius-pill !default;
