/* ========================================================================
   Border Colors
   ======================================================================== */
/**
 * Border color tokens

  type: color
  category: border-color
  cssProperties:
    - 'border*'
    - 'border-color*'

 */

/**
 * ATTENTION
 *
 * Usage:
 * Until the design token pipeline is in place, find the file
 *  `_themes.custom-tokens.scss` 
 * in the themes folder and rename it to 
 *  `_themes.custom-{product-name}-tokens.scss`
 *
 * You will use this file to override !default token values as needed.
 */


/**
 * DO NOT EDIT ANYTHING BELOW 
 * Override default token values in YOUR custom tokens file.
 */

$color-border:                        $resmed-brand-gray-palette-200 !default;

$color-border-lightest:               $resmed-brand-gray-palette-50 !default;
$color-border-lighter:                $resmed-brand-gray-palette-100 !default;
$color-border-light:                  $resmed-brand-gray-palette-200 !default;

$color-border-mid-lighter:            $resmed-brand-gray-palette-300 !default;
$color-border-mid-light:              $resmed-brand-gray-palette-400 !default;
$color-border-mid:                    $resmed-brand-gray-palette-500 !default;
$color-border-mid-dark:               $resmed-brand-gray-palette-600 !default;

$color-border-dark:                   $resmed-brand-gray-palette-700 !default;
$color-border-darker:                 $resmed-brand-gray-palette-800 !default;
$color-border-darkest:                $resmed-brand-gray-palette-900 !default;

$color-border-primary:                $theme-primary-color !default;
$color-border-primary-hover:          $theme-primary-color-hover !default;
$color-border-primary-active:         $theme-primary-color-active !default;
$color-border-primary-dark:           $theme-primary-color-700 !default;

$color-border-destructive:            $resmed-brand-020-palette-500 !default;
$color-border-destructive-hover:      $resmed-brand-020-palette-400 !default;
$color-border-destructive-active:     $resmed-brand-020-palette-700 !default;

$color-border-error:                  $resmed-brand-022-palette-600 !default;
$color-border-error-hover:            $resmed-brand-022-palette-400 !default;
$color-border-error-active:           $resmed-brand-022-palette-700 !default;

$color-border-warning:                $palette-light-orange-500 !default;
$color-border-warning-hover:          $palette-light-orange-400 !default;
$color-border-warning-active:         $palette-light-orange-700 !default;

$color-border-success:                $palette-mod-green-500 !default;
$color-border-success-hover:          $palette-mod-green-400 !default;
$color-border-success-active:         $palette-mod-green-700 !default;

$color-border-input:                  $resmed-brand-gray-palette-500 !default;
$color-border-input-focus:            $theme-primary-color !default;
$color-border-input-disabled:         $resmed-brand-gray-palette-100 !default;

$button-color-border:                 transparent !default;
$button-color-border-hover:           $resmed-brand-gray-palette-100 !default;
$button-color-border-active:          transparent !default;

$button-primary-color-border:         $button-primary-color-background !default;
$button-primary-color-border-hover:   $button-primary-color-background-hover !default;
$button-primary-color-border-active:  $button-primary-color-background-active !default;

$button-outline-color-border:         $theme-primary-color !default;
$button-outline-color-border-hover:   $theme-primary-color !default;
$button-outline-color-border-active:  $theme-primary-color-50 !default;

$button-neutral-color-border:         $button-neutral-color-background !default;
$button-neutral-color-border-hover:   $button-neutral-color-background-hover !default;
$button-neutral-color-border-active:  $button-neutral-color-background-active !default;

$button-outline-neutral-color-border:         $resmed-brand-gray-palette-700 !default;
$button-outline-neutral-color-border-hover:   $resmed-brand-gray-palette-700 !default;
$button-outline-neutral-color-border-active:  $resmed-brand-gray-palette-100 !default;

$button-inverse-color-border:                 $button-inverse-color-background !default;
$button-inverse-color-border-hover:           $button-inverse-color-background-hover !default;
$button-inverse-color-border-active:          $button-inverse-color-background-active !default;

$button-primary-inverse-color-border:         $button-primary-inverse-color-background !default;
$button-primary-inverse-color-border-hover:   $button-primary-inverse-color-background-hover !default;
$button-primary-inverse-color-border-active:  $button-primary-inverse-color-background-active !default;

$button-outline-inverse-color-border:         $color-white !default;
$button-outline-inverse-color-border-hover:   $color-white !default;
$button-outline-inverse-color-border-active:  $color-white !default;

$button-neutral-inverse-color-border:         $button-neutral-inverse-color-background !default;
$button-neutral-inverse-color-border-hover:   $button-neutral-inverse-color-background-hover !default;
$button-neutral-inverse-color-border-active:  $button-neutral-inverse-color-background-active !default;

$button-text-inverse-color-border:            $button-text-inverse-color-background !default;
$button-text-inverse-color-border-hover:      $button-text-inverse-color-background-hover !default;
$button-text-inverse-color-border-active:     $button-text-inverse-color-background-active !default;


$page-head-color-border:              $color-border !default;
$page-footer-color-border:            $color-border !default;
$card-color-border:                   $color-white !default;
$popover-color-border:                $color-border-lighter !default;
$popover-dark-color-border:           $color-white-20 !default;

$chip-color-border:                   $resmed-brand-gray-palette-200 !default;
$chip-color-border-hover:             $resmed-brand-gray-palette-300 !default;
$chip-color-border-active:            $color-background-primary-lightest !default;
