/* ==========================================================================
   #LAYOUT
   ========================================================================== */
// Layout @mixins which replicate the `o-layout` and `o-wrapper` objects.

// Apply `o-layout` object styles to <ul> and <li> elements
@mixin o-layout-list($gutter: $spacing-default, $vertical-alignment: top, $item-width: 100%) {
  /**
   * 1. Allows us to use the layout object on any type of element.
   * 2. We need to defensively reset any box-model properties.
   * 3. Use the negative margin trick for multi-row grids:
   *    http://csswizardry.com/2011/08/building-better-grid-systems/
   */
  display: block; /* [1] */
  margin:  0; /* [2] */
  padding: 0; /* [2] */
  list-style: none; /* [1] */

  @include mq($from: tablet) {
    margin-left: -$gutter; /* [3] */
  }

  @if ($use-markup-fix == false) {
    font-size: 0;
  }

  /**
   * 1. Required in order to combine fluid widths with fixed gutters.
   * 2. Allows us to manipulate grids vertically, with text-level properties,
   *    etc.
   * 3. Default item alignment is with the tops of each other, like most
   *    traditional grid/layout systems.
   * 4. By default, all layout items are full-width (mobile first).
   * 5. Gutters provided by left padding:
   *    http://csswizardry.com/2011/08/building-better-grid-systems/
   * 6. Fallback for old IEs not supporting `rem` values.
   */
  > li {
    box-sizing: border-box; /* [1] */
    display: block;
    
    @if ($use-markup-fix == false) {
      font-size: $font-size-body; /* [6] */
      font-size: 1rem;
    }
    
    @include mq($until: tablet) {
      text-align: center;
      margin-bottom: $gutter;
    }

    @include mq($from: tablet) {
      display: inline-block; /* [2] */
      vertical-align: $vertical-alignment; /* [3] */
      width: $item-width; /* [4] */
      padding-left: $gutter; /* [5] */
    }
    
  }
}

@mixin o-layout-div($gutter: $spacing, $vertical-alignment: top, $item-width: 100%) {
  display: block; /* [1] */
  margin:  0; /* [2] */
  padding: 0; /* [2] */
  margin-left: -$gutter; /* [3] */

  @if ($use-markup-fix == false) {
    font-size: 0;
  }

  > div {
    box-sizing: border-box; /* [1] */
    display: inline-block; /* [2] */
    vertical-align: $vertical-alignment; /* [3] */
    width: $item-width; /* [4] */
    padding-left: $gutter; /* [5] */

    @if ($use-markup-fix == false) {
      font-size: $font-size-body; /* [6] */
      font-size: 1rem;
    }

  }
}



// Apply `o-wrapper` styles to a container element.
@mixin o-wrapper($gutter: $spacing) {
  @include clearfix();
  padding-right: $gutter;
  padding-left:  $gutter;
  margin-right: auto;
  margin-left:  auto;
  max-width: var(--aura-theme-page-width, $aura-theme-page-width);
}