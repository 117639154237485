/* ========================================================================
   #FONTS
   ======================================================================== */
/**
 * The fonts settings file defines the rules for importing or loading local
 * font files.
 */
@use "sass:string";
@use "sass:list";

// Default font files folder location relative to the compiled CSS file's location.
// This is the folder which contains the actual font files,
// not the SCSS @font-face partials.
$fonts-folder-path: "/assets/fonts/" !default;

@use "../fonts/fonts.cern" as cern with ( $fonts-folder: $fonts-folder-path );
@use "../fonts/fonts.open-sans" as opensans with ( $fonts-folder: $fonts-folder-path );
@use "../fonts/fonts.roboto" as roboto with ( $fonts-folder: $fonts-folder-path );

@function res-font-settings($config, $element, $property) {
  @return map-get(map-get($config, $element), $property);
}
@function str-remove-whitespace($str) {
  @while (str-index($str, ' ') != null) {
    $index: str-index($str, ' ');
    $str: "#{str-slice($str, 0, $index - 1)}#{str-slice($str, $index + 1)}";
  }
  @return $str;
}


/* Font config
   ========================================================================== */

// List of approved Aura design system fonts
$aura-fonts: "Cern", "Open Sans", "Roboto" !default;

// Google font @import URLs
$import-opensans:           'https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap';
$import-roboto:             'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap';
$import-opensans-roboto:    'https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap';



/* @font-face and @import rules
   ========================================================================== */
// Load font-face or import rules for fonts being used in the theme.

$font-family-pass: false;
$font-family-heading-pass: false;
$font-list: list.join($font-family, $font-family-heading);

@if list.index($font-family, "Cern") or list.index($font-family, "Open Sans") or list.index($font-family, "Roboto") {
  $font-family-pass: true;
} @else {
  @warn "Missing fonts: #{$font-family}. Expected one of #{$aura-fonts}.";
}

@if list.index($font-family-heading, "Cern") or list.index($font-family-heading, "Open Sans") or list.index($font-family-heading, "Roboto") {
  $font-family-heading-pass: true;
} @else {
  @warn "Missing fonts: #{$font-family-heading}. Expected one of #{$aura-fonts}.";
}

@if $font-family-pass and $font-family-heading-pass {

    @if list.index($font-list, "Cern") {
      @include cern.load-cern-fonts;
    }

    @if list.index($font-list, "Open Sans") and list.index($font-list, "Roboto") {
      @if $font-family-import {
        @import url($import-opensans-roboto);
      }
      @else {
        @include opensans.load-opensans-fonts;
        @include roboto.load-roboto-fonts;
      }
    }
    @else {
      @if list.index($font-list, "Open Sans") {
        @if $font-family-import {
          @import url($import-opensans);
        }
        @else {
          @include opensans.load-opensans-fonts;
        }
      }

      @if list.index($font-list, "Roboto") {
        @if $font-family-import {
          @import url($import-roboto);
        }
        @else {
          @include roboto.load-roboto-fonts;
        }
      }
    }

}
@else {
  @error "Missing valid fonts. Expected one of #{$aura-fonts}.";
}
