/* ========================================================================
   #COLOR FUNCTIONS
   ======================================================================== */

// Slightly lighten a color
// @access public
// @param {Color} $color - color to tint
// @param {Number} $percentage - percentage of `$color` in returned color
// @return {Color}
@function lighten($color, $percentage) {
  @return mix(white, $color, $percentage);
}

// Slightly darken a color
// @access public
// @param {Color} $color - color to shade
// @param {Number} $percentage - percentage of `$color` in returned color
// @return {Color}
@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}



// For a given hue in a palette, return the contrast color from the map of 
// contrast palettes.
// @param $color-map
// @param $hue
@function res-contrast($palette, $hue) {
  @return map-get(map-get($palette, contrast), $hue);
}
@function res-color($palette, $hue) {
  @return map-get($palette, $hue);
}