/* ==========================================================================
   #ANIMATIONS CUSTOM
   ========================================================================== */
@include keyframes(borderscale) { 
  50% {
    box-shadow: 0 0 0 2px $color-border-input-focus;
  }
}

// BG colors of the sleep score dial
@include keyframes(aniBgColors) {
  0% { background-color: #009cdc; }
  50% { background-color: #ec2c36; }
  100% { background-color: #f4a832; }
}


// Dim an element
@include keyframes(aniDim) {
  0% { opacity: 1; }
  100% { opacity: 0.4; }
}


// Keep an element hidden
@include keyframes(aniStayHidden) {
  0%, 100% {
    opacity: 0; }
}


// Fade an element in
@include keyframes(aniFadeIn) {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; }
}


// Fade an element out
@include keyframes(aniFadeOut) {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; }
}


// Fade in and up
@include keyframes(aniFadeInUp) {
  0% {
    @include translateY(20px);
    opacity: 0;
  }
  100% {
    @include translateY(0);
    opacity: 1; }
}

// Fade in from left
@include keyframes(aniFadeInLeft) {
  0% {
    @include translateX(-20px);
    opacity: 0;
  }
  100% {
    @include translateX(0);
    opacity: 1; }
}

// Fade in from right
@include keyframes(aniFadeInRight) {
  0% {
    @include translateX(20px);
    opacity: 0;
  }
  100% {
    @include translateX(0);
    opacity: 1; }
}

// Fade in and down
@include keyframes(aniFadeInDown) {
  0% {
    @include translateY(-20px);
    opacity: 0; }
  100% {
    @include translateY(0);
    opacity: 1; }
}  

@include keyframes(aniSlideOutUp) {
  from {
    @include translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    @include translate3d(0, -100%, 0);
  }
}
.ani-slideOutUp {
  @include animation-name(aniSlideOutUp);
}


@include keyframes(aniSlideOutDown) {
  from {
    @include translate3d(0, 0, 0);
  }

  to {
    // visibility: hidden;
    @include translate3d(0, 100%, 0);
  }
}
.ani-slideOutDown {
  @include animation-name(aniSlideOutDown);
}


@include keyframes(aniSlideOutLeft) {
  from {
    @include translate3d(0, 0, 0);
  }

  to {
    // visibility: hidden;
    @include translate3d(-100%, 0, 0);
  }
}

@include keyframes(aniSlideOutRight) {
  from {
    @include translate3d(0, 0, 0);
  }

  to {
    // visibility: hidden;
    @include translate3d(100%, 0, 0);
  }
}


// Slide down from top
@include keyframes(aniSlideInDown) {
  0% {
    @include translate3d(0,-100%,0);
  }
  100% {
    @include translate3d(0,0,0);
  }
}

.ani-hideFadein\@tablet {

  @include mq($from: tablet) {            
    @include animation-name(aniStayHidden, aniFadeInUp);
    @include animation-duration( 1500ms, 550ms);
    @include animation-delay(0s, 1500ms);
    @include animation-timing-function(linear, ease-out);
    // seems to break IE11
    // animation-fill-mode: forwards;
    // animation-iteration-count: 1;
  }

}


//
@include keyframes(aniPopIn) {
  0% {
    @include transform(scale(1) translate(-50%, -50%));
  }
  1% {
    @include transform(scale(0.5) translate(-50%, -50%));
  }

  45% {
    @include transform(scale(1.05) translate(-50%, -50%));
  }

  80% {
    @include transform(scale(0.95) translate(-50%, -50%));
  }

  100% {
    @include transform(scale(1) translate(-50%, -50%));
  }
}