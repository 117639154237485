  /* ==========================================================================
   #SVG SYMBOLS
   ========================================================================== */

/**
 * This little bit of CSS is used to create a baseline for our SVG symbols.
 * It is used directly, and exclusively, on the SVG tag.

    Include SVG icons using the <use> method:

    <svg class="c-svg c-svg--icon-name"><use xlink:href="#c-svg--icon-name"></use></svg>

    When using a sprite, only inlcude the symbols you will need and not the 
    entire library. The sprite must be included at the top of the page (so right
    after the body tag), and must be hidden from view using the CSS class 
    `.u-hidden-visually`.

    How to include SVG sprite:

    <svg aria-hidden="true" class="u-hidden-visually" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <defs>
        <symbol id="c-svg--icon-name" viewBox="0 0 32 32">
        <title>Icon title</title>
        <path ...></path>
        </symbol>

        // add each symbol needed...

      </defs>
    </svg>

    Reference the symbols with the <use> tag as shown above.


    ALTERNATIVELY

    If you only require the use of a few icons here and there, include
    the icon inline instead of the <use> method. That just means, instead of 
    referencing the icon symbol in a sprite by it's ID, just inlcude the entire
    SVG symbol itself, like so:

    <svg class="c-svg c-svg--icon-name"  viewBox="0 0 32 32"><path ...></svg>

    This is how the site logos are inlcuded in the masthead.


    ****************************************************************************

    NOTE:
    The use of `xlink:href` is deprecated in SVG 2. Although it is still 
    supported and likely will never be pulled from browsers.
    REF: https://css-tricks.com/on-xlinkhref-being-deprecated-in-svg/

    If it actually does become unsupported from browsers, we will opt to use the 
    inline method descirbed in the Alternative section above.
    
    ****************************************************************************

 *
 */

/**
 * 1. Help align our inline SVG icons.
 * 2. Align bottom of icon with baseline.
 * 3. Icon only, keep flush with wrapper.
 */
.c-svg {
  position: relative; /* [1] */
  top: $icon-position-top-center; /* [1] */
  display: inline-flex; /* [1] */
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
  align-self: center; /* [1] */
  pointer-events: none;
  
  &.c-svg--baseline {
    top: $icon-position-top-baseline; /* [2] */
  }

  &.c-svg--flush {
    top: 0; /* [3] */
  }

  @include all-anchor-states() {
    color: inherit;
    stroke: currentColor;
    fill: currentColor;
  }

}


/* Interactions
   ========================================================================== */
.c-svg-hover-swap {
  position: relative;

  // &.c-icon-btn-top-link {
  //   @include position-sticky();

  //   // For stupid IE
  //   ._Telerik_IE9 & {
  //     position: fixed;
  //     left: auto;
  //     bottom: 186px;
  //   }

  // }

  .c-svg {
    @include position-center();
    width:  100%;
    height: 100%;
    transition: opacity $duration-promptly ease-in-out, color $duration-promptly ease-in-out;
    will-change: color;

    &.icon-default {
      color: var(--aura-c-svg-color-icon, $color-text-icon-default);
      visibility: visible;
      opacity: 1;
    }

    &.icon-hover {
      visibility: hidden;
      opacity: 0;
      color: var(--aura-c-svg-color-icon-active, $color-text-icon-hover);
    }

  }

  &:not(.c-icon-btn-thumbs):hover {
        
    .c-svg {

      &.icon-default {
        visibility: hidden;
        opacity: 0;
      }

      &.icon-hover {
        visibility: visible;
        opacity: 1;
      }

    }

  }

  &.c-icon-btn-thumbs {

    &:hover {
  
      .c-svg.icon-default {
        color: var(--aura-c-svg-color-icon-active, $color-text-icon-hover);
      }
  
    }
  
  
    &.is-active {
      
      &,
      &:hover {
  
        .c-svg.icon-default {
          visibility: hidden;
          opacity: 0;
        }
        
        .c-svg.icon-hover {
          visibility: visible;
          opacity: 1;
          color: var(--aura-c-svg-color-icon-active, $color-text-icon-active);
        }
  
      }
  
    }
  
  }

}




/* Variants
   ========================================================================== */
.c-svg--streak {
  width: 0.5791015625em;
}

.c-svg--mask-leak {
  width: 1.1875em;
}

.c-svg--book-open {
  width: 1.2939453125em;
}

.c-svg--connectivity {
  width: 1.2939453125em;
}

.c-svg--cpap-device {
  width: 1.2939453125em;
}

.c-svg--headmask-fullface {
  width: 1.2939453125em;
}

.c-svg--headmask-nasal {
  width: 1.2939453125em;
}

.c-svg--headmask-pillow {
  width: 1.2939453125em;
}

.c-svg--device-laptop {
  width: 1.2939453125em;
}

.c-svg--checkmark {
  width: 1.2939453125em;
}