@charset "UTF-8";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

// $slick-font-path:           "../fonts/slick/" !default;
// $slick-font-family:         $global_icon-font;
$slick-loader-path:             "../images/" !default;
$slick-arrow-color:             $color-black !default;
$slick-arrow-size:              $icon-size-default !default;
$slick-prev-character:          $svg-chevron-left !default;
$slick-next-character:          $svg-chevron-right !default;
$svg-slick-dot-color:           $color-background-gray-light !default;
$svg-slick-dot-color-active:    $color-background-primary !default;
$svg-slick-dot-character:       $svg-dot !default;
$svg-slick-dot-size:            8px !default;
$slick-opacity-default:         0.75 !default;
$slick-opacity-hover:           1 !default;
$slick-opacity-disabled:        0.25 !default;

@function slick-image-url($url) {
  @if function-exists(image-url) {
    @return image-url($url);
  }
  @else {
    @return url($slick-loader-path + $url);
  }
}

// @function slick-font-url($url) {
//     //@if function-exists(font-url) {
//     //    @return font-url($url);
//     //}
//     //@else {
//         @return url($slick-font-path + $url);
//     //}
// }

/* Slider */

// .slick-list {
//   .slick-loading & {
//       background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
//   }
// }

/* Icons */
// @if $slick-font-family == "slick" {
//     @font-face {
//         font-family: "slick";
//         src: slick-font-url("slick.eot");
//         src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
//         font-weight: normal;
//         font-style: normal;
//     }
// }

/* Arrows */

.slick-prev,
.slick-next {
  @include position-y-center();
  display: block;
  height: var(--aura-c-slick-arrow-size, $slick-arrow-size);
  width:  var(--aura-c-slick-arrow-size, $slick-arrow-size);
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  padding: 0;
  border: none;
  outline: none;
  transition: $opacity-transition;
  will-change: opacity;

  @include set-svg-bg-css-var("slick-previous-icon", $slick-prev-character, $slick-arrow-color);
  @include set-svg-bg-css-var("slick-next-icon", $slick-next-character, $slick-arrow-color);

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width:  100%;
    height: 100%;
    opacity: var(--aura-c-slick-opacity-default, $slick-opacity-default);
    @include translateX(0);
    transition: $opacity-transition, 0.3s transform ease-out;
  }

  @include hocus() {
    outline: none;
    background: transparent;
    color: transparent;

    &::before {
      opacity: var(--aura-c-slick-opacity-hover, $slick-opacity-hover);
      will-change: opacity, transform;
    }

  }

  &.slick-disabled {
    cursor: default;

    &::before {
      opacity: var(--aura-c-slick-opacity-disabled, $slick-opacity-disabled);
    }
  }

}

.slick-prev {
  left: var(--aura-c-slick-arrow-inline-position, -25px);
  [dir="rtl"] & {
    left: auto;
    right: var(--aura-c-slick-arrow-inline-position, -25px);
  }
  &::before {
    @include svg-css-var-bg-img("slick-previous-icon");

    [dir="rtl"] & {
      @include svg-css-var-bg-img("slick-next-icon");
    }
  }
  @include hocus() {
    &:not(.slick-disabled):before {
      @include translateX(-3px);
    }
  }
}

.slick-next {
  right: var(--aura-c-slick-arrow-inline-position, -25px);
  [dir="rtl"] & {
      left: var(--aura-c-slick-arrow-inline-position, -25px);
      right: auto;
  }
  &::before {
    @include svg-css-var-bg-img("slick-next-icon");
    [dir="rtl"] & {
      @include svg-css-var-bg-img("slick-previous-icon");
    }
  }
  @include hocus() {
    &:not(.slick-disabled):before {
      @include translateX(3px);
    }
  }
}

/* Dots */

.slick-dotted.slick-slider {
  margin-bottom: var(--aura-c-slick-slider-margin-block-end, $spacing-xx-large);
}

.slick-dots {
  position: absolute;
  bottom: var(--aura-c-slick-dots-margin-block-end, -$spacing-default);
  list-style: none;
  display: block;
  text-align: center;
  padding: 0 $spacing-large;
  margin: 0;
  width: 100%;

  @include set-svg-bg-css-var("slick-dot-icon", $svg-slick-dot-character, $svg-slick-dot-color);
  @include set-svg-bg-css-var("slick-dot-icon-active", $svg-slick-dot-character, $svg-slick-dot-color-active);

  @include mq($from: tablet) {
    padding: 0;
  }


  li {
    position: relative;
    display: inline-block;
    height: var(--aura-c-slick-dot-size, $svg-slick-dot-size);
    width:  var(--aura-c-slick-dot-size, $svg-slick-dot-size);
    margin: 0 3px;
    padding: 0;
    cursor: pointer;
    button {
      border: 0;
      background: transparent;
      display: block;
      height: 100%;
      width:  100%;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer;
      transition: $opacity-transition;
      will-change: opacity;

      &::before {
        @include position-center();
        width:  100%;
        height: 100%;
        @include svg-css-var-bg-img("slick-dot-icon");
        opacity: var(--aura-c-slick-opacity-default, $slick-opacity-default);
      }

      &:hover,
      &:focus {
        outline: none;
        &::before {
          opacity: var(--aura-c-slick-opacity-hover, $slick-opacity-hover);
          transition: $opacity-transition;
          will-change: opacity;
        }
      }

    }

    &.slick-active button:before {
      @include svg-css-var-bg-img("slick-dot-icon-active");
    }

  }
}
