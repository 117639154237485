/* ==========================================================================
   #POPOVER
   ========================================================================== */
/**
 * Requires 'aura-popover.min.js' for accessibility toggles and viewport
 * edge detection.
 */

@use "sass:color";

$popover-width:                       354px !default;
$popover-padding-block-start-mobile:  $spacing-small !default;
$popover-padding-inline-end-mobile:   $spacing-medium !default;
$popover-padding-block-end-mobile:    $spacing-small !default;
$popover-padding-inline-start-mobile: $spacing-small !default;

$popover-padding-block:         $spacing-medium !default;
$popover-padding-inline:        $spacing-large !default;

$tooltip-opacity:               $opacity-9 !default;

/** Private framework-only tokens **/
/**
 * 1. Wider popover width for excessive content.
 * 2. Left or right edge position of popover content element when hitting
 *    the edge of the viewport.
 * 3. Tooltips should be small, set max-width that fits within mobile screens.
 */
$c-popover-width-wide:          444px !default; /* [1] */
// $c-popover-edge-position:       $popover-nubbin-size * 2.25 !default; //$spacing-medium !default; /* [2] */

$c-tooltip-padding-block:       $spacing-md-sm  !default;
$c-tooltip-padding-inline:      $spacing-default !default;
$c-tooltip-max-width:           300px !default; /* [3] */

/**
 * Make popover/tooltip visible.
 * 1. Show the popover on click @mobile or :hover above tablet.
 */
@mixin show-popover() {
  display: block;
  opacity: 1; /* [1] */
  visibility: visible; /* [1] */
  will-change: opacity; /* [1] */
}

/**
 * Popovers
 * @selector .c-popover
 * @restrict [role="dialog"], [role="tooltip"]
 *
 * A non-modal dialog used to display contextual information to the user.
 * The popover stays open while the target element is focused or hovered.
 * The user can interact with a popover and the content can contain
 * focusable elements, such as a link.
 * A unique ID must assigned to the content element and applied to the
 * `aria-describedby` and `aria-controls` attributes.
 *
 * HTML:

    <section aria-describedby="popover_content_id" class="c-popover c-popover--icon c-popover--left" role="dialog">
      <button class="c-popover__target [ js-no-click ]" aria-controls="popover_content_id" aria-expanded="false" aria-label="Get info">
        <svg class="c-svg c-svg--help-circle-border-24" aria-hidden="true" focusable="false">
          <use xlink:href="#c-svg--help-circle-border-24"></use>
        </svg>
      </button>
      <div id="popover_content_id" class="c-popover__content">
        <p>Hello, here's a popover! <a href="#0">Click this link.</a></p>
      </div>
    </section>

 *
 * 1. Reset any defaults on the elemtent used for the popover.
 */
.c-popover {
  --aura-c-popover-color-background: #{$popover-color-background};
  --aura-c-popover-color-border: #{$popover-color-border};
  // Help to dynamically resize nubbin and spacing when updating CSS props.
  --aura-c-popover-nubbin-size: #{$popover-nubbin-size};
  --aura-c-popover-edge-position:  calc(var(--aura-c-popover-nubbin-size) * 2.25);
  --aura-c-popover-offset: calc(var(--aura-c-popover-nubbin-size) + 2px);
  --aura-c-popover-nubbin-size-edges: calc(var(--aura-c-popover-nubbin-size) - 4px);
  --aura-c-popover-nubbin-size-outline-edges: calc(var(--aura-c-popover-nubbin-size) - 3px);
  --aura-c-popover-nubbin-size-outline-tip: calc(var(--aura-c-popover-nubbin-size) + 1px);

  position: relative;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  margin:  0; /* [1] */
  padding: 0; /* [1] */
  list-style: none; /* [1] */

  &.c-popover-open {
    .c-popover__target::before,
    .c-popover__target::after,
    .c-popover__content {
      @include show-popover();
    }

  }

  &:not(:disabled):not(.is-disabled) {
    @media (hover: hover) {
      &:not(.c-popover-open):not(.c-popover--tooltip) {
        &:hover,
        &:focus-within {
          .c-popover__content {
            @include show-popover();
          }

          .c-popover__target {
            &,
            &:focus {
              &::before,
              &::after {
                @include show-popover();
              }
            }
          }
        }
      }

      &.c-popover--tooltip {

        .c-popover__target {
          &:hover,
          &:focus {
            + .c-popover__content,
            &::before,
            &::after {
              @include show-popover();
            }
          }
        }

      }
    }
  }

}

// Shared base styles for popover nubbin.
// Nubbin has two layers, top layer for fill color (matches popover background color),
// bottom layer for fake outline color.
@mixin nubbin() {
  position: absolute;
  width: 0;
  height: 0;
  content: "";
  border-width: 0;
  border-style: solid;
  border-color: transparent;
}

/**
 * 1. Reset any defaults which could be inherited on our target element.
 */
.c-popover__target {
  position: relative;
  margin:  0; /* [1] */
  padding: 0; /* [1] */
  @include appearance(none);
  border: 0;
  background: none;

  @include all-anchor-states() {
    color: var(--aura-c-popover-target-text-color, $color-text-link);
    text-decoration: none; /* [1] */
  }

  @include hocus() {
    outline: 0;
    color: var(--aura-c-popover-target-text-color-hover, $color-text-link-hover);
  }

  &:focus-visible {
    box-shadow: var(--aura-c-popover-shadow-focus, $button-shadow-focus);
  }

  .is-inverse & {
    --aura-c-popover-target-text-color: #{$color-text-link-inverse};
    --aura-c-popover-target-text-color-hover: #{$color-text-link-inverse-hover};
  }

  // Nubbin
  &::before,
  &::after {
    @include nubbin();
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    visibility: hidden;
    opacity: 0;


  }
  &::before {
    z-index: 102;
  }
  &::after {
    z-index: 101;
  }


  // Popover always on top on touch devices
  @include mq($until: desktop) {
    &::before,
    &::after {
      display: none;
      top: calc(var(--aura-c-popover-offset) * -1);
      left: 50%;
      @include translate(-50%, -1px);
      animation: aniFadeIn 0.2s ease-in;
    }
    &::before {
      border-top-color:   var(--aura-c-popover-color-background);
      border-top-width:   var(--aura-c-popover-nubbin-size);
      border-right-width: var(--aura-c-popover-nubbin-size-edges);
      border-left-width:  var(--aura-c-popover-nubbin-size-edges);
    }
    &::after {
      border-top-color:    var(--aura-c-popover-color-border);
      border-top-width:    var(--aura-c-popover-nubbin-size-outline-tip);
      border-left-width:   var(--aura-c-popover-nubbin-size-outline-edges);
      border-right-width:  var(--aura-c-popover-nubbin-size-outline-edges);
    }
  }

  @include mq($from: desktop) {
    &::before,
    &::after {
      transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
    }
  }

  &:disabled,
  &.is-disabled,
  &[disabled],
  &[disabled="disabled"] {
    transition: none;

    @include all-anchor-states() {
      cursor: default !important;
      box-shadow: none !important;
      opacity: var(--aura-c-button-opacity-disabled, $opacity-disabled);
    }

    * {
      pointer-events: none;
    }

  }
}

/**
 * The component's close button.
 */
.c-popover__close {
  &,
  &.c-icon-btn {
    position: absolute;
    top:    var(--aura-c-popover-close-position-top-mobile, $spacing-tiny);
    right:  var(--aura-c-popover-close-position-right-mobile, $spacing-tiny);
    cursor: pointer;
    line-height: 0;
    z-index: $z-index-sticky;

    &.c-icon-btn--close {
      &:hover,
      &:active,
      &:focus,
      &:focus-within {
        &::before {
          background-color: $color-background-gray-lighter;
        }
      }
    }
  }
}



/**
 * This is the `popover`.
 *
 * 1. Keep the popover hidden by default.
 * 2. Keep the size of the popover smaller than the screen width on @mobile devices.
 */
.c-popover__content {
  position: absolute;
  top: auto;
  text-align: left;
  color: var(--aura-c-popover-text-color, inherit);
  @include font-size($font-size-body, $line-height-text);
  font-weight: var(--aura-c-popover-font-weight, $font-weight-medium);
  background-color: var(--aura-c-popover-color-background);
  border-style: solid;
  border-width: var(--aura-c-popover-border-width, $popover-border-width);
  border-color: var(--aura-c-popover-color-border);
  border-radius: var(--aura-c-popover-border-radius, $popover-border-radius);
  box-shadow: var(--aura-c-popover-shadow, $popover-shadow);

  visibility: hidden; /* [1] */
  opacity: 0; /* [1] */
  z-index: 100;

  @include mq($until: tablet) {
    width: 80vw; /* [2] */
  }

  @include mq($from: tablet) {
    width: var(--aura-c-popover-sizing-width, $popover-width);  /* [2] */
  }

  @include mq($until: desktop) {
    left: 50%;
    bottom: 100%;
    display: none; /* [1] */
    padding-top:    var(--aura-c-popover-padding-block-start-mobile, $popover-padding-block-start-mobile);
    padding-right:  var(--aura-c-popover-padding-inline-start-mobile, $popover-padding-inline-end-mobile);
    padding-bottom: var(--aura-c-popover-padding-block-end-mobile, $popover-padding-block-end-mobile);
    padding-left:   var(--aura-c-popover-padding-inline-end-mobile, $popover-padding-inline-start-mobile);
    animation: aniFadeIn 0.2s ease-in;
    @include translate(-50%, calc(var(--aura-c-popover-offset) * -1));

    // Transition used by aura-popover.js to trap focus in the popover @mobile screens.
    &:not(.c-popover--tooltip &):not(:focus-within) {
      --aura-c-popover-color-background: #{color.adjust($popover-color-background, $blue: -1)};
      transition: background-color 0.01s ease;
    }
  }

  /**
   * 1. Default position of the popover is none. A modifier class must be used
   *    to position the popover in the desired direction. [ top, right, bottom, left ]
   */
  @include mq($from: desktop) {
    right:  auto; /* [1] */
    bottom: auto; /* [1] */
    left:   auto; /* [1] */
    display: block;
    padding-top: var(--aura-c-popover-padding-block-start, $popover-padding-block);
    padding-right: var(--aura-c-popover-padding-inline-start, $popover-padding-inline);
    padding-bottom: var(--aura-c-popover-padding-block-end, $popover-padding-block);
    padding-left: var(--aura-c-popover-padding-inline-end, $popover-padding-inline);
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  }

  a {
    @include all-anchor-states() {
      color: var(--aura-c-popover-content-link-color, $color-text-link);
    }
    @include hocus() {
      color: var(--aura-c-popover-content-link-color-hover, $color-text-link-hover);
      text-decoration: underline;
    }
  }

  &,
  > *:last-child {
    margin-bottom: 0;
  }

}

// Hide nubbin. Used primarily with icons which have a tooltip.
.no-nubbin {
  @at-root .c-popover#{&} {
    .c-popover__target {
      &::before,
      &::after {
        border: none;
        border-width: 0;
        border-color: transparent;
      }
    }
  }
}


/* Type variants
   ========================================================================== */
/**
 * Use an icon to trigger a popover.
 * HTML:

    <section aria-describedby="pop_unique_id" class="c-popover c-popover--icon c-popover--top" role="dialog">
      <button class="c-popover__target [ js-no-click ]" aria-controls="pop_unique_id9" aria-expanded="false" aria-label="Get info">
        <svg class="c-svg c-svg--help-circle-outline-24" aria-hidden="true" focusable="false"><use xlink:href="#c-svg--help-circle-outline-24"></use></svg>
        <span class="u-sr-only">Get info</span>
      </button>
      <div id="pop_unique_id" class="c-popover__content">
        <p>Hello, here's a bottom popover.<br /><a href="#0">Click this link.</a></p>
      </div>
    </section>

 *
 */
.c-popover--icon {
  --aura-c-popover-icon-size: #{$icon-size-default};

  // Info/Help icon next to a form input label
  @at-root .c-form-field__icon #{&} {
    --aura-c-popover-icon-size: #{$icon-size-default};
    // top: 0.25em;
  }

  display: inline-block;

  // Use `.c-svg-hover-swap` on this element if swapping between two svg icons on hover
  .c-popover__target {
    position: relative;
    display: block;
    width:  var(--aura-c-popover-icon-size);
    height: var(--aura-c-popover-icon-size);
    line-height: 0;

    &:not(.c-svg-hover-swap) {

      .c-svg {
        top: 0;
        width: 100%;
        height: 100%;
        color: var(--aura-c-popover-icon-text-color, $color-text-icon-default);
        transition: $text-color-transition;

        @include hocus() {
          color: var(--aura-c-popover-icon-text-color-hover, $color-text-icon-hover);
        }

        @at-root .is-inverse#{&} {
          --aura-c-popover-icon-text-color: #{$color-text-link-inverse};
          --aura-c-popover-icon-text-color-hover: #{$color-text-link-inverse-hover};
        }
      }

    }

  }

  &.has-large-icon {
    --aura-c-popover-icon-size: #{$icon-size-large};
  }

  // Position an icon within text flow.
  .is-text-flow & {
    .c-popover__target {
      &:not(.c-svg-hover-swap) {
        .c-svg {
          top: $icon-position-top-center;
        }
      }
    }
    .c-popover__content {
      margin-top: $icon-position-top-center;
    }
  }

  // No arrow for icon tooltips
  &.c-popover--tooltip {
    @extend .no-nubbin;
  }
}

/* Adding hint popover to form label.

  HTML:

    <label class="c-form-field__label" for="input id">
      Label text
      <span class="is-inline-popover">
        <section class="c-popover ...">
          [...]
        </section>
      </span>
    </label>
 */
// .is-inline-popover {
//   position: relative;
//   padding-left: $spacing-tiny;

//   .c-popover {
//     @include position-y-center();
//     z-index: $z-index-sticky;
//   }
// }



/* Directional variants
   ========================================================================== */

/* Top side popovers */
// Top centered
.c-popover--top {
  @include mq($from: desktop) {
    .c-popover__content {
      bottom: 100%;
      left:   50%;
      @include translate(-50%, 0);
    }

    &:hover,
    &:focus-within {
      .c-popover__content {
        @include translate(-50%, calc(var(--aura-c-popover-offset) * -1));
      }
    }
  }
}

// Top anchored to left side / start
.c-popover.left-edge,
.c-popover--top-start {
  @include mq($from: desktop) {
    .c-popover__content {
      right: auto;
      bottom: 100%;
      left: 50%;
      @include translate(calc(var(--aura-c-popover-edge-position) * -1), 0);
    }

    &:hover,
    &:focus-within {
      .c-popover__content {
        @include translate(calc(var(--aura-c-popover-edge-position) * -1), calc(var(--aura-c-popover-offset) * -1));
      }
    }
  }
}

// Top anchored to right side / end
.c-popover.right-edge,
.c-popover--top-end {
  @include mq($from: desktop) {
    .c-popover__content {
      right: 50%;
      bottom: 100%;
      left: auto;
      @include translate(var(--aura-c-popover-edge-position), 0);
    }

    &:hover,
    &:focus-within {
      .c-popover__content {
        @include translate(var(--aura-c-popover-edge-position), calc(var(--aura-c-popover-offset) * -1));
      }
    }
  }
}

.c-popover.left-edge,
.c-popover.right-edge,
.c-popover--top,
.c-popover--top-start,
.c-popover--top-end {
  @include mq($from: desktop) {
    .c-popover__target {
      &::before,
      &::after {
        top: -1px;
        left: 50%;
        @include translate(-50%, 0);
      }
      &::before {
        border-top-color:   var(--aura-c-popover-color-background);
        border-top-width:   var(--aura-c-popover-nubbin-size);
        border-right-width: var(--aura-c-popover-nubbin-size-edges);
        border-left-width:  var(--aura-c-popover-nubbin-size-edges);
      }
      &::after {
        border-top-color:    var(--aura-c-popover-color-border);
        border-top-width:    var(--aura-c-popover-nubbin-size-outline-tip);
        border-left-width:   var(--aura-c-popover-nubbin-size-outline-edges);
        border-right-width:  var(--aura-c-popover-nubbin-size-outline-edges);
      }
    }
    &:hover,
    &:focus-within {
      .c-popover__target {
        &::before,
        &::after {
          @include translate(-50%, calc(var(--aura-c-popover-offset) * -1));
        }
      }
    }
  }
}


.c-popover--left:not(.left-edge) {
  @include mq($from: desktop) {
    .c-popover__content {
      top: 50%;
      right: 100%;
      @include translate(0, -50%);
    }


    .c-popover__target {
      &::before,
      &::after {
        top: 50%;
        left: -1px;
        @include translate(0, -50%);
      }

      &::before {
        border-top-width:    var(--aura-c-popover-nubbin-size-edges);
        border-bottom-width: var(--aura-c-popover-nubbin-size-edges);
        border-left-width:   var(--aura-c-popover-nubbin-size);
        border-left-color:   var(--aura-c-popover-color-background);
      }

      &::after {
        border-top-width:    var(--aura-c-popover-nubbin-size-outline-edges);
        border-bottom-width: var(--aura-c-popover-nubbin-size-outline-edges);
        border-left-width:   var(--aura-c-popover-nubbin-size-outline-tip);
        border-left-color:   var(--aura-c-popover-color-border);
      }

    }

    &:hover,
    &:focus-within {
      .c-popover__content,
      .c-popover__target::before,
      .c-popover__target::after {
        @include translate(calc(var(--aura-c-popover-offset) * -1), -50%);
      }
    }
  }
}


.c-popover--right:not(.right-edge) {
  @include mq($from: desktop) {
    .c-popover__content {
      top: 50%;
      left: 100%;
      @include translate(0, -50%);
    }
    .c-popover__target {
      &::before,
      &::after {
        top: 50%;
        right: -1px;
        @include translate(0, -50%);
      }

      &::before {
        border-top-width:    var(--aura-c-popover-nubbin-size-edges);
        border-right-width:  var(--aura-c-popover-nubbin-size);
        border-bottom-width: var(--aura-c-popover-nubbin-size-edges);
        border-right-color:  var(--aura-c-popover-color-background);
      }

      &::after {
        border-top-width:    var(--aura-c-popover-nubbin-size-outline-edges);
        border-right-width:  var(--aura-c-popover-nubbin-size-outline-tip);
        border-bottom-width: var(--aura-c-popover-nubbin-size-outline-edges);
        border-right-color:  var(--aura-c-popover-color-border);
      }

    }

    &:hover,
    &:focus-within {
      .c-popover__content,
      .c-popover__target::before,
      .c-popover__target::after {
        @include translate(var(--aura-c-popover-offset), -50%);
      }
    }
  }
}


/* Bottom side popovers */
.c-popover--bottom:not(.left-edge):not(.right-edge) {
  @include mq($from: desktop) {
    .c-popover__content {
      top: 100%;
      left: 50%;
      @include translate(-50%, 0);
    }

    &:hover,
    &:focus-within {
      .c-popover__content {
        @include translate(-50%, var(--aura-c-popover-offset));
      }
    }
  }
}

.c-popover--bottom-start:not(.left-edge):not(.right-edge) {
  @include mq($from: desktop) {
    .c-popover__content {
      top: 100%;
      left: 50%;
      @include translate(calc(var(--aura-c-popover-edge-position) * -1), 0);
    }

    &:hover,
    &:focus-within {
      .c-popover__content {
        @include translate(calc(var(--aura-c-popover-edge-position) * -1), var(--aura-c-popover-offset));
      }
    }
  }
}


.c-popover--bottom-end:not(.left-edge):not(.right-edge) {
  @include mq($from: desktop) {
    .c-popover__content {
      top: 100%;
      right: 50%;
      @include translate(var(--aura-c-popover-edge-position), 0);
    }

    &:hover,
    &:focus-within {
      .c-popover__content {
        @include translate(var(--aura-c-popover-edge-position), var(--aura-c-popover-offset));
      }
    }
  }
}


.c-popover--bottom,
.c-popover--bottom-start,
.c-popover--bottom-end {
  &:not(.left-edge):not(.right-edge) {
    @include mq($from: desktop) {
      .c-popover__target {
        &::before,
        &::after {
          bottom: -1px;
          left: 50%;
          @include translate(-50%, 0);
        }
        &::before {
          border-right-width:  var(--aura-c-popover-nubbin-size-edges);
          border-bottom-width: var(--aura-c-popover-nubbin-size);
          border-left-width:   var(--aura-c-popover-nubbin-size-edges);
          border-bottom-color: var(--aura-c-popover-color-background);
        }

        &::after {
          border-right-width:  var(--aura-c-popover-nubbin-size-outline-edges);
          border-bottom-width: var(--aura-c-popover-nubbin-size-outline-tip);
          border-left-width:   var(--aura-c-popover-nubbin-size-outline-edges);
          border-bottom-color: var(--aura-c-popover-color-border);
        }
      }
      &:hover,
      &:focus-within {
        .c-popover__target {
          &::before,
          &::after {
            @include translate(-50%, var(--aura-c-popover-offset));
          }
        }
      }
    }
  }
}




/* Style variants
   ========================================================================== */
.c-popover--dark {
  --aura-c-popover-color-background: var(--aura-c-popover-dark-color-background, #{$popover-dark-color-background});
  --aura-c-popover-color-border: var(--aura-c-popover-dark-color-border, #{$popover-dark-color-border});

  .c-popover__content {
    --aura-c-popover-text-color: var(--aura-c-popover-dark-text-color, #{$popover-dark-color-text});
  }
}


.c-popover--wide {
  .c-popover__content {
    @include mq($from: desktop) {
      --aura-c-popover-sizing-width: var(--aura-c-popover-width-wide, #{$c-popover-width-wide});
    }
  }
}


/**
 * Tooltip markup has some slight variations from the regular popover component.
 * HTML:

    <section class="c-popover c-popover--top c-popover--tooltip">
      <button class="c-popover__target [ js-no-click ]" aria-describedby="tooltip_id" aria-controls="tooltip_id" aria-disabled="true" aria-expanded="false" aria-label="Get info">
        Get info
      </button>
      <div id="tooltip_id" class="c-popover__content" role="tooltip">
        <div class="c-tooltip__body">
          <p>Hello, here's a tooltip!</p>
        </div>
      </div>
    </section>

    Icon trigger:

    <section class="c-popover c-popover--top c-popover--icon c-popover--tooltip">
      <button class="c-popover__target [ js-no-click ]" aria-describedby="tooltip_id" aria-controls="tooltip_id" aria-disabled="true" aria-expanded="false" aria-label="Get info">
        <svg class="c-svg c-svg--help-circle-outline-24" aria-hidden="true" focusable="false"><use xlink:href="#c-svg--help-circle-outline-24"></use></svg>
        <span class="u-sr-only">Get info</span>
      </button>
      <div id="tooltip_id" class="c-popover__content" role="tooltip">
        <div class="c-tooltip__body">
          <p>Hello, here's a tooltip!</p>
        </div>
      </div>
    </section>

 */
.c-popover--tooltip {
  --aura-c-popover-nubbin-size: #{$tooltip-nubbin-size};
  // --aura-c-popover-edge-position:  calc(var(--aura-c-popover-nubbin-size) * 2.25);
  --aura-c-popover-offset: calc(var(--aura-c-popover-nubbin-size) * 2);
  --aura-c-popover-nubbin-size-edges: calc(var(--aura-c-popover-nubbin-size) - 2px);
  --aura-c-popover-nubbin-size-outline-edges: calc(var(--aura-c-popover-nubbin-size) - 1px);
  --aura-c-popover-nubbin-size-outline-tip: var(--aura-c-popover-nubbin-size);
  --aura-c-popover-border-radius: #{$tooltip-border-radius};

  &:not(.is-inverse) {
    --aura-c-popover-color-background: #{$tooltip-color-background};
    --aura-c-popover-color-border: var(--aura-c-popover-color-background);
    --aura-c-popover-text-color: #{$tooltip-color-text};

    .c-popover__content {
      --aura-c-popover-shadow: #{$tooltip-shadow};
    }
  }

  // &.is-inverse .c-popover__content {
  //   --aura-c-popover-shadow: #{$tooltip-inverse-shadow};
  // }

  .c-popover__target {
    cursor: pointer;
  }

  .c-popover__content {
    width: max-content;
    max-width: var(--aura-c-tooltip-max-width, 80vw);
    padding-top:    var(--aura-c-tooltip-padding-block-start, $spacing-tiny);
    padding-right:  var(--aura-c-tooltip-padding-inline-start, $spacing-small);
    padding-bottom: var(--aura-c-tooltip-padding-block-end, $spacing-tiny);
    padding-left:   var(--aura-c-tooltip-padding-inline-end, $spacing-small);
    @include font-size($font-size-tooltip, $line-height-text);
    text-align: center;

    @include mq($from: $bp-375) {
      --aura-c-tooltip-max-width: #{$c-tooltip-max-width};
    }
    @include mq($from: tablet) {
      --aura-c-tooltip-max-width: #{calc($c-tooltip-max-width * 1.5)};
    }
  }

  // Keep padding small for icon tooltips as they tend to be labels.
  &:not(.c-popover--icon) .c-popover__content {
    @include mq($from: tablet) {
      --aura-c-tooltip-padding-block-start: #{$c-tooltip-padding-block};
      --aura-c-tooltip-padding-inline-start: #{$c-tooltip-padding-inline};
      --aura-c-tooltip-padding-block-end: #{$c-tooltip-padding-block};
      --aura-c-tooltip-padding-inline-end: #{$c-tooltip-padding-inline};
    }
  }

}

.c-tooltip__body {
  position: relative;
  overflow-wrap: break-word;
  word-wrap: break-word;

  &,
  > *:last-child {
    margin-bottom: 0;
  }
}
