/* ==========================================================================
  #THEME - CUSTOM TOKENS
  ========================================================================== */
/**
 * Use this file to override `!default` design token values.
 *
 * NOTE:
 *    This file must be included in the main `auracss.scss` file AFTER the
 *    color palettes but BEFORE all other token files are imported.
 *    See the `auracss.scss` file for the commented out example.
 *
 *
 * To override !default design token values:
 *
 * 1. Copy the token(s) you need to override from the corresponding token `.scss` file
      and paste it below.
 * 2. Replace the default value with your new value.
 * 3. Remove the `!default` flag after the value.
 *
 * Example:
 *
 *  Step 1: Copy the token to be overriden from it's source design token file.
 *    For example, from the file `/design-tokens/_tokens.text-color.scss`
 *
 *    $color-text-default: $resmed-brand-gray-palette-800 !default;
 *
 *  Step 2: Paste the token in this file, change the value and remove
 *    the `!default` flag.
 *
 *    Aliased to a color palette token token (preferred method for colors):
 *    $color-text-default: $resmed-brand-gray-palette-900;
 *
 *    Or hardcode a value:
 *    $color-text-default: #hexvalue;
 *
 * Since your custom token file is loaded after the color palettes, but
 * before all other token files, you can alias custom color tokens to a color
 * palette token. All other custom tokens however, will require hardcoded values.
 */


/***************************************************************************
 *
 *                          !!! IMPORTANT !!!
 *
 * --> Rename this file to `_themes.custom-{project-name}-tokens.scss` <--
 *
 *   Don't forget to @import this file in your local copy of auracss.scss
 *   around line 232, just before the main design-tokens import rule:
 *
 *   @import "themes.custom-{project-name}-tokens"; <-- your file here!
 *   @import "../../aura-design-system/auracss/design-tokens";
 *
***************************************************************************/


/* Theme colors
   ========================================================================== */
// Update the primary brand color for your theme.
$theme-primary-color:                $resmed-brand-005-palette-500;
$theme-primary-color-hover:          $resmed-brand-005-palette-700;
$theme-primary-color-active:         $resmed-brand-005-palette-500;
$theme-primary-color-lightest:       $resmed-brand-005-palette-50;
$theme-primary-color-lighter:        $resmed-brand-005-palette-100;
$theme-primary-color-light:          $resmed-brand-005-palette-200;
$theme-primary-color-dark:           $resmed-brand-005-palette-700;
$theme-primary-color-darker:         $resmed-brand-005-palette-800;
$theme-primary-color-darkest:        $resmed-brand-005-palette-900;

// Contrast color to your primary color.
// For example, text color on a background of the primary color.
$theme-primary-color-contrast:       $color-white !default;

$svg-search-icon: "M15.5 14h-0.79l-0.28-0.27c0.98-1.14 1.57-2.62 1.57-4.23 0-3.59-2.91-6.5-6.5-6.5s-6.5 2.91-6.5 6.5 2.91 6.5 6.5 6.5c1.61 0 3.090-0.59 4.23-1.57l0.27 0.28v0.79l5 4.99 1.49-1.49-4.99-5zM9.5 14c-2.49 0-4.5-2.010-4.5-4.5s2.010-4.5 4.5-4.5 4.5 2.010 4.5 4.5-2.010 4.5-4.5 4.5z";