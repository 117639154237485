/* ==========================================================================
  #LISTS
  ========================================================================== */

// Form input labels
$c-list-label-font-size: $font-size-form-label !default;
$c-list-label-line-height: 1.25 !default;
$c-list-label-font-weight: $font-weight-medium !default;
$c-list-label-color: $color-text-label !default;

$c-list-value-font-size: $font-size-body !default;
$c-list-value-color: $color-text-default !default;
$c-list-value-font-weight: $font-weight-normal !default;

// List styles
.c-list,
.c-list__sub-list,
.c-list__item {
  list-style-type: none;
  margin:  0;
  padding: 0;
}

.c-list__item-heading {
  display: block;
  @include font-size(16px);
  font-weight: $font-weight-medium;
  padding: 20px $spacing-default;
}


.c-list__item {
  ul {
    list-style-type: disc;
  }
}

.c-list__item-label {
  display: block;
  margin-bottom: $spacing-tiny;
  @include font-size($c-list-label-font-size, 1);
  font-weight: $c-list-label-font-weight;
  color: $c-list-label-color;
}

.c-list__item-value {
  display: block;
  color: $c-list-value-color;
  @include font-size($c-list-value-font-size, 1.25);
  font-weight: $c-list-value-font-weight;

  &.is-email {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:not(:last-of-type) {
    margin-bottom: $spacing-small;
  }

}



/* List variants
  ========================================================================== */

/* List of form details in read-only view */
.c-list--read-only {
  margin-bottom: $spacing-default;
  // &:last-of-type {
  // }

  .c-list__item {
    @include word-wrap(none);
    margin-bottom: $spacing-default;
    // padding: 0;

    // &:last-child,
    p:last-of-type {
      margin-bottom: 0;
    }

  }

  &.has-borders {

    .c-list__item {
      margin: 0;
      padding: $spacing-default 0;
      border-bottom: 1px solid $color-border;
    }

  }


}

.c-list--inline\@tablet {
  // @include mq($until: tablet) {
  //   .c-list__item {
  //     margin-bottom: $spacing-default;
  //   }
  // }

  @include mq($from: tablet) {
    @include o-layout-list($spacing-large, top, auto);
  }

}



.c-list--rows {

  .c-list__item {
    display: block;
    margin:  0;
    padding: 0;
    border-top: 1px solid $color-border;
    @include word-wrap(none);

    &:last-of-type {
      border-bottom: 1px solid $color-border;
    }

    p:last-of-type {
      margin-bottom: 0;
    }

    @if ($use-markup-fix == false) {
      font-size: 0;
    }

  }

  .c-list__item-label,
  .c-list__item-value {
    display: inline-block;
    width: 50%;
    margin: 0;
    padding: $spacing-small 0;
    vertical-align: middle;
  }

  .c-list__item-value {
    text-align: right;
  }

}



.c-list--inline {
  @include display-flex();
  flex-direction:  row wrap;
  justify-content: flex-start;
  font-size: 0;

  .c-list__item {
    box-sizing: border-box;
    display: inline-block;
    width: auto;
    margin-bottom: $spacing-default;
    padding: $spacing-micro $spacing-large $spacing-micro $spacing-tiny;
    @include font-size(12px, 1.2);
    border-left: 2px solid $color-border;
  }

}

.c-list__item-icon {
  padding-top: 0;
  padding-bottom: 0;
  vertical-align: middle;
  align-content: center;

  .c-list-icon {

    &:before {
      display: block;
      width: 30px;
      height: 30px;
      border: 1px solid $color-border;
      background-color: $resmed-brand-gray-palette-50;
      @include font-size(22px, 28px);
      color: inherit;
      box-shadow: none;
      border-radius: 60px;
      transition: $box-shadow-transition;
      text-align: center;
    }

  }

}
