/* ========================================================================
   #SIZING
   ======================================================================== */
/**
 * Sizing tokens

  type: size
  category: sizing
  cssProperties:
  - '*width'
  - '*height'

 */

/**
 * ATTENTION
 *
 * Usage:
 * Until the design token pipeline is in place, find the file
 *  `_themes.custom-tokens.scss` 
 * in the themes folder and rename it to 
 *  `_themes.custom-{product-name}-tokens.scss`
 *
 * You will use this file to override !default token values as needed.
 */


/**
 * DO NOT EDIT ANYTHING BELOW 
 * Override default token values in YOUR custom tokens file.
 */
$header-min-height-patient:     80px !default;
$header-min-height-provider:    70px !default;

$header-min-height:             80px !default;
$input-field-height:            40px !default;

$icon-size-small:               16px !default;
$icon-size-default:             24px !default;
$icon-size-large:               32px !default;
$icon-size-x-large:             48px !default;
$round-icon-boundary:           40px !default;

$select-arrow-icon-sizing:      $icon-size-small !default;

$nubbin-size-small:             9px;
$nubbin-size-large:             16px;

$popover-nubbin-size:           $nubbin-size-large !default; // Size of the pointer arrow
$tooltip-nubbin-size:           $nubbin-size-small !default;

// Border sizing
$border-width-thin:             1px !default;
$border-width-thick:            2px !default;
$border-width-x-thick:          4px !default;

$badge-border-width:            $border-width-thin !default;
$badge-height:                  $spacing-default - ($badge-border-width * 2) !default;

$button-border-width:           $border-width-thin !default;
$button-height:                 $input-field-height - ($button-border-width*2) !default;

$chip-border-width:             $border-width-thin !default;
$chip-height:                   32px - calc($chip-border-width * 2) !default;

$page-head-border-width:        $border-width-thin !default;
$page-footer-border-width:      $border-width-thin !default;
$form-input-border-width:       $border-width-thin !default;
$popover-border-width:          $border-width-thin !default;

$screen-320-safe-max-width:     272px;
