/* ==========================================================================
   #EXPANDER
   ========================================================================== */
  
/** Private framework-only tokens **/
$c-expander-arrow-sizing:                     16px !default;
$c-expander-nav-label-visible-mobile:         false !default; // [ true | false ]
$c-expander-nav-color-background-mobile:      $color-background-gray-light !default;
$c-expander-nav-label-font-size-mobile:       16px !default;
$c-expander-nav-label-font-size:              18px !default;
$c-expander-nav-label-text-color:             $color-text-default !default;
$c-expander-nav-label-font-weight:            $font-weight-bold !default;


/**
 * Expander
 * @selector .c-expander
 * @restrict div, article
 *
 * Custon expander -- toggling a section of UI open and closed
 * Requires `res-expander.min.js` for toggling and accessibility function.
 *
 * HTML

    <div class="c-expander" data-state="is-hidden">
      <button class="c-expander__label" aria-expanded="false" aria-controls="unique_id" role="button">
        <svg class="c-svg c-svg--chevron-right-24" aria-hidden="true" focusable="false"><use xlink:href="#c-svg--chevron-right-24"></use></svg>
        Click on me to expand or collapse the content
      </button>
      <div id="unique_id" class="c-expander__panel">
        <div class="c-expander__body">
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
        </div>
      </div>
    </div>
 *
 */

/**
 *
 * 1. Make the expander default to 100% width of it's parent container.
 * 2. A little buffer between the expander and other content on the page.
 * 3. Hide the contents of the `c-expander__panel` when it's collapsed.
 * 4. Position the `c-expander__panel` up slightly so we can create a little
 *    slide down effect when it's toggled open.
 * 5. When the panel is `active`, change the color of the expander label text
 *    and rotate the arrow icon.
 * 6. The height of the expander content in calculated by the aura-expander.js 
 *    plugin and the default value of `--aura-c-expander-open-height` is  
 *    dynamically overwritten with the new height value.
 * 7. Make the expander panel content visible.
 */
.c-expander {
  position: relative;
  display: block;
  width: 100%; /* [1] */
  // margin-top:     $spacing-default; /* [2] */
  // margin-bottom:  $spacing-default; /* [2] */


  /* Toggle/animation states */
  /* [3] */
  &[data-state="is-hidden"] {

    > .c-expander__panel {
      max-height: 0;
      opacity: 0;
      overflow: hidden;
      visibility: hidden;
    }

  }

  &[data-state="is-animating"] {

    > .c-expander__panel {
      opacity: 0;
      visibility: visible;
    }

  }

  open,
  &.is-open,
  &.is-expanded,
  &[data-state="is-expanded"] {

    > .c-expander__label {
      color: var(--aura-c-expander-label-color-text-active, $expander-label-color-text-active); /* [5] */

      // Arrow is pointing right and turns downward
      .c-svg {
        @include transform(rotate(90deg) translateX(-50%));
        color: inherit; // $c-expander-label-color-text-active;
      }

    }

    > .c-expander__panel {
      --aura-c-expander-open-height: 400px; /* [6] */
      margin-top: 0;
      max-height: var(--aura-c-expander-open-height);
      visibility: visible; /* [7] */
      opacity: 1;          /* [7] */
      will-change: opacity;

      // &.c-expander__panel--500 {
      //   --aura-c-expander-open-height: 500px; /* [6] */
      // }

      // &.c-expander__panel--600 {
      //   --aura-c-expander-open-height: 600px; /* [6] */
      // }

      // &.c-expander__panel--700 {
      //   --aura-c-expander-open-height: 700px; /* [6] */
      // }

      // &.c-expander__panel--800 {
      //   --aura-c-expander-open-height: 800px; /* [6] */
      // }

      // &.c-expander__panel--1800 {
      //   --aura-c-expander-open-height: 1800px; /* [6] */
      // }

      // // Height animation transition won't work with this.
      // &.c-expander__panel--max-content {
      //   --aura-c-expander-max-height: max-content;
      // }

    }

  }

}



/* The label which toggles the panel.
 *
 * 1. Add some left paddingto make room for the arrow icon.
 * 2. Align the icon and text vertically.
 * 3. Force the label elements to appear clickable.
 * 4. By default the arrow icon is positioned on the left and pointing right. `>`
 */
.c-expander__label {
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  padding: var(--aura-c-expander-label-padding-block, $spacing-small) 0;
  padding-left: var(--aura-c-expander-label-padding-inline-start, $spacing-default); /* [1] */
  color: var(--aura-c-expander-label-color-text, $expander-label-color-text);
  font-weight: var(--aura-c-expander-label-font-weight, $font-weight-medium);
  text-align: left;
  vertical-align: middle; /* [2] */
  cursor: pointer; /* [3] */
  border: 0;
  background: none;
  @include appearance(none);

  /* [4] */
  .c-svg {
    @include position-y-center();
    left: 0;
    width:  var(--aura-c-expander-arrow-sizing, $c-expander-arrow-sizing);
    height: var(--aura-c-expander-arrow-sizing, $c-expander-arrow-sizing);
    color: inherit; // $c-expander-label-color;
    text-align: center;
    @include transformsition(250ms ease-out);
    @include transform-origin(50% 50%);
  }

}



/* The content we want to reveal.
 * 1. Hide everything.
 * 2. Set up our transitions.
 * 3. Undo it all @tablet size and above if it's navigation.
 */
.c-expander__panel {
  position: relative;
  margin-top:    -$spacing-small;
  margin-bottom: $spacing-small;
  max-height: 0; /* [1] */
  overflow: hidden; /* [1] */
  opacity: 0; /* [1] */
  transition: max-height 500ms ease-out, margin-top 500ms ease-out, opacity 500ms ease-out 250ms; /* [2] */


  // .c-expander--navigation[data-state="is-hidden"] > & {

  //   /* [3] */
  //   @include mq($from: tablet) {
  //     max-height: none !important;
  //     overflow: initial !important;
  //     visibility: visible !important;
  //     opacity: 1;
  //   }

  // }

}

// Need a body container to apply padding for the content because adding it
// to the panel messes up the annimation
// 1. Match the padding on the label text (icon space) so the content aligns 
//    with the label.
.c-expander__body {
  padding-top: var(--aura-c-expander-padding-block-start, $spacing-small);
  padding-right: var(--aura-c-expander-padding-inline-end, 0);
  padding-bottom: var(--aura-c-expander-padding-block-end, $spacing-small);
  padding-left: var(--aura-c-expander-padding-inline-start, $spacing-default); /* [1] */
}



/* Navigation that turns into an expander at mobile screen sizes.
   ========================================================================== */
/**
 * 1. Reset some defaults.
 * 2. Force the navigation container to be full screen width @mobile sizes
 *    since it's probably inside an `o-wrapper` object. Useful only if your
 *    navigation container has a background color.
 * 3. Add some padding so the content isn't butted against the edge of the screen.
 * 4. Let the navigation be the full width of it's parent container @tablet size
 *    and above.
 * 5. In navigation mode, the label's default arrow icon position is to point down
 *    when collapsed, and to rotate 180° to point up when expanded.
 */
.c-expander--navigation {
  width: auto; /* [1] */
  margin-top:     0; /* [1] */
  margin-bottom:  $spacing-large; /* [1] */
  margin-left:    -$spacing-default; /* [2] */
  margin-right:   -$spacing-default; /* [2] */
  padding: 0 $spacing-default; /* [3] */
  background-color: var(--aura-c-expander-nav-color-background-mobile, $c-expander-nav-color-background-mobile);


  @include mq($from: tablet) {
    width: 100%; /* [4] */
    margin: 0;
    margin-top: 0;
    padding: 0;
    background-color: transparent;
  }


  > .c-expander__label {
    display: block; /* [1] */
    margin-bottom: 0;
    @include font-size($c-expander-nav-label-font-size-mobile);
    font-weight: var(--aura-c-expander-nav-label-font-weight, $c-expander-nav-label-font-weight);
    color: var(--aura-c-expander-nav-label-text-color, $c-expander-nav-label-text-color);
    text-align: center; /* [1] */

    @if($c-expander-nav-label-visible-mobile) {
      @include mq($from: tablet) {
        display: block;
        padding: 0; /* [1] */
        margin-bottom: $spacing-small; /* [1] */
        @include font-size($c-expander-nav-label-font-size);
        text-align: left;
      }
    } @else {
      @include mq($from: tablet) {
        display: none;
      }
    }

    /* [5] */
    .c-svg {
      left: auto;
      right: 4px;

      @include mq($from: tablet) {
        display: none;
      }

      
    }
    
    &[data-state="is-expanded"] > .c-expander__label .c-svg {
  
      @include transform(rotate(180deg) translateY(50%)); /* [5] */
  
    }
    
  }

  &[data-state="is-expanded"] {

    > .c-expander__label {

      @include mq($from: tablet) {
        color: var(--aura-c-expander-label-color-text, $expander-label-color-text);
        cursor: default;
      }

    }

  }

  > .c-expander__panel {
    top: 0;
    margin-left:  -$spacing-default;
    margin-right: -$spacing-default;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: $spacing-small;

    @include mq($from: tablet) {
      top:     0;
      margin:  0;
      padding: 0;
    }

  }

}



/* Accordion 
   ========================================================================== */
/**
 * Make it work like an accordion, one open / others closed.
 *
 * HTML

    <div class="c-expander c-expander--group" data-state="is-hidden" data-group="my-group-name">
      <button class="c-expander__label" aria-expanded="false" aria-controls="unique_id3" role="button"><svg class="c-svg c-svg--chevron-down-24" aria-hidden="true" focusable="false"><use xlink:href="#c-svg--chevron-down-24"></use></svg> Click on me to expand or collapse the content</button>
      <div id="unique_id3" class="c-expander__panel">
        <div class="c-expander__body">
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
        </div>
      </div>
    </div>
    <div class="c-expander c-expander--group" data-state="is-hidden" data-group="my-group-name">
      <button class="c-expander__label" aria-expanded="false" aria-controls="unique_id4" role="button"><svg class="c-svg c-svg--chevron-down-24" aria-hidden="true" focusable="false"><use xlink:href="#c-svg--chevron-down-24"></use></svg> Click on me to expand or collapse the content</button>
      <div id="unique_id4" class="c-expander__panel">
        <div class="c-expander__body">
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
        </div>
      </div>
    </div>
    <div class="c-expander c-expander--group" data-state="is-hidden" data-group="my-group-name">
      <button class="c-expander__label" aria-expanded="false" aria-controls="unique_id5" role="button"><svg class="c-svg c-svg--chevron-down-24" aria-hidden="true" focusable="false"><use xlink:href="#c-svg--chevron-down-24"></use></svg> Click on me to expand or collapse the content</button>
      <div id="unique_id5" class="c-expander__panel">
        <div class="c-expander__body">
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
        </div>
      </div>
    </div>
 *
 */

/**
 * 1. Put a border between each item in the group.
 * 2. Add a top border to the first item.
 * 3. In accordion mode, the label's default arrow icon position is to point down
 *    when collapsed, and to rotate 180° to point up when expanded.
 */

.c-accordion {
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
}

.c-accordion__item {
  margin: 0;
  padding: 0 $spacing-small;
  list-style: none;
  border-top-style: solid; /* [1] */
  border-top-width: var(--aura-c-accordion-border-sizing, $border-width-thin);
  border-top-color: var(--aura-c-accordion-color-border, $color-border);
  
  &:first-of-type {
    border-top: 0;
    // border-top-style: solid; /* [2] */
    // border-top-width: var(--aura-c-accordion-border-sizing, $border-width-thin);
    // border-top-color: var(--aura-c-accordion-color-border, $color-border);
  }

  .c-expander__label {
    width: 100%;
    // .c-svg {
    //   @include transformsition(250ms ease-out);
    // }
  }

}





/* Expander icon position variant
   ========================================================================== */
// 1. Undo the arrow icon's default settings.
// 2. Place the arrow icon to the far right of the label.
// 3. Point arrow up when expanded.
// .c-expander--group,
.c-expander--icon-right {

  &:not(.c-expander--navigation) {
    > .c-expander__label {
      padding-left: 0; /* [1] */
    }
  }

  > .c-expander__label {
    padding-right: $spacing-medium; /* [2] */

    .c-svg {
      left: auto; /* [1] */
      right: 4px; /* [2] */
    }
  }

  .c-expander__body {
    padding-left: 0px; /* [1] */
  }

  &.c-expander--navigation {

    > .c-expander__label {
      @include mq($until: tablet) {
        width: 100%;
        text-align: center;
        padding-left: $spacing-medium;
      }
    }

  }

}

// Starting with down chevron icon.
.c-expander--icon-down {
  &[data-state="is-expanded"] {
    > .c-expander__label .c-svg {
      @include transform(rotate(-180deg) translateY(50%));
    }
  }
}


.c-expander--no-icon {

  > .c-expander__label,
  > .c-expander__panel .c-expander__body {
    padding-left: 0;
    padding-right: 0;
  }

  &[data-state="is-hidden"],
  &[data-state="is-expanded"] {
    .c-expander__label {
      color: var(--aura-c-expander-label-color-text, $expander-label-color-text);
    }
  }

}

// When the side nav has sections which are expanders
.is-nested-nav {
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: $spacing-default;

  > .c-expander__label {
    display: block;
    padding-top: 0;
    padding-bottom: 0;
  }

  > .c-expander__panel {
    margin-bottom: 0;

    .c-expander__body {
      padding: 0;
    }

  }

  .site-nav--stacked & {
    .site-nav__list {
      margin-bottom: 0;
    }
  }

}