/* ========================================================================
   #FONT-STACKS
   ======================================================================== */
/**
 * Tokens used by font-family.

  category: font
  type: font
  cssProperties:
  - 'font'
  - 'font-family'

 */

/**
 * ATTENTION
 *
 * Usage:
 * Until the design token pipeline is in place, find the file
 *  `_themes.custom-tokens.scss`
 * in the themes folder and rename it to
 *  `_themes.custom-{product-name}-tokens.scss`
 *
 * You will use this file to override !default token values as needed.
 */


/**
 * DO NOT EDIT ANYTHING BELOW
 * Override default token values in YOUR custom tokens file.
 */

/* Font stacks
   ========================================================================== */
// Standard font stacks used within the framework.

// ResMed font
$font-family-cern:          "Cern", "Helvetica Neue", "Helvetica", Helvetica, Arial, Verdana, sans-serif !default;


// System fonts
$font-family-sans-serif:    "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif !default;
$font-family-serif:         Georgia, "Times New Roman", serif !default;
$font-family-ios:           -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;


// Monospace stack
$font-family-monospace:     Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier, monospace !default;


// Google fonts
$font-family-opensans:      "Open Sans", "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif !default;
$font-family-roboto:        "Roboto", "Open Sans", "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif !default;
