/* ========================================================================
   #CLEARFIX
   ======================================================================== */

// Mixin to drop micro clearfix into a selector. Further reading:
// http://www.cssmojo.com/the-very-latest-clearfix-reloaded/
//
// .simple-usage {
//   @include clearfix();
// }
//
// .detailed-usage {
//   @include clearfix(important);
// }

@mixin clearfix($important: false) {

  // Determine whether or not to make declarations important.
  @if ($important == false) {
    $important: null;
  } @else {
    $important: !important;
  }

  &::after {
    content: "" $important;
    display: table $important;
    clear: both $important;
  }

}
