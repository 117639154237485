/* ==========================================================================
   #DROPDOWN
   ========================================================================== */

$dropdown-padding-block:                  0 !default;
$dropdown-padding-inline-start:           $spacing-small;
$dropdown-padding-inline-end:             $spacing-default !default;
$dropdown-max-width:                      140px !default;
$dropdown-color-background:               $color-white !default;
$dropdown-border-radius:                  $border-radius-pill !default;
$dropdown-border-width:                   $border-width-thin !default;
$dropdown-height:                         $input-field-height - ($dropdown-border-width*2) !default;
$dropdown-text-color:                     $color-text-default !default;

$dropdown-list-text-color:                $color-text-default !default;
$dropdown-list-text-color-hover:          $color-text-link-hover !default;
$dropdown-list-color-background-hover:    $color-background-gray-lightest !default;
$dropdown-list-border-radius:             $border-radius-small !default;

$dropdown-shadow-size:                    3px !default;
$dropdown-shadow-opacity:                 $opacity-3 !default;
$dropdown-shadow:                         0 0 1px $dropdown-shadow-size transparent !default;
$dropdown-shadow-focus:                   0 0 1px $dropdown-shadow-size rgba($color-background-primary, $dropdown-shadow-opacity) !default;
$dropdown-list-shadow:                    $shadow-drop-down !default;


/** Private framework-only tokens **/
$c-dropdown-transition:                   $text-color-transition, $background-color-transition, $box-shadow-transition !default;
$c-dropdown-border-width:                 if($aura-theme-type == patient, 0 0 $form-input-border-width 0, $form-input-border-width) !default;

// $c-dropmenu-ddlb-min-width:           160px !default;
// $c-dropmenu-ddlb-max-width:           222px !default;
// $c-dropmenu-ddlb-height:              $input-field-height !default;
// $c-dropmenu-ddlb-font-size:           $font-size-body !default;
// $c-dropmenu-ddlb-text-color:          $color-text-default !default;
// $c-dropmenu-ddlb-text-hover-color:    $color-primary-hover !default;
// $c-dropmenu-ddlb-font-weight:         $font-weight-medium !default;
// $c-dropmenu-ddlb-line-height:         27px !default;
// $c-dropmenu-ddlb-border-color:        $resmed-brand-gray-palette-400 !default;
// $c-dropmenu-ddlb-radius:              $border-radius-small !default;

// $c-dropmenu-ddlb-arrow-size:          $select-arrow-icon-sizing !default;
// $c-dropmenu-ddlb-arrow-color:         $color-primary !default;


/**
 * Make a UL look and behave like dropdown/select list.
 *
 * HTML:

    <div class="c-form-field" data-validation="default">
      <label id="customDropdown" class="c-form-field__label">Label</label>
      <div class="c-form-field__input c-form-column">
        <div class="c-dropdown">
          <button class="c-dropdown__target" aria-haspopup="listbox" aria-labelledby="customDropdown" aria-expanded="false">
            <svg class="c-svg c-svg--chevron-down-24" aria-hidden="true" focusable="false">
              <use xlink:href="#c-svg--chevron-down-24"></use>
            </svg>
            <span class="c-dropdown-value">Select</span>
          </button>
          <ul class="c-dropdown__list" role="listbox" id="customDropdownList" tabindex="-1">
            <li class="c-dropdown__item" id="optionOne" role="option">Option one</li>
            <li class="c-dropdown__item" id="optionTwo" role="option">Option two</li>
            <li class="c-dropdown__item" id="optionThree" role="option">Option three</li>
          </ul>
        </div>
      </div>
    </div>

 */

.c-dropdown {
  position: relative;
  margin: 0;
  padding: 0;

  // Keep dropdown text and arrow highlighted while dropdown-list is active.
  // @include hocus() {
  //   .c-dropdown__target {
  //     color: var(--aura-c-dropdown-text-link-color-hover, $dropdown-text-link-color-hover);
  //   }
  // }

  &.is-focus,
  &:hover,
  &:focus,
  &:active,
  &:focus-within {
    .c-dropdown__target {
      // color: var(--aura-c-dropdown-text-link-color-hover, $dropdown-text-link-color-hover);
      outline: 0;
      border-color: var(--aura-c-form-input-color-border-focus, $color-border-input-focus);
      box-shadow: none; //var(--aura-c-dropdown-shadow-focus, $dropdown-shadow-focus);
      
      .c-svg {
        color: var(--aura-c-dropdown-text-link-color-hover, $color-border-input-focus);
      }
    }
  }

}

.c-dropdown__target {
  position: relative;
  display: inline-block;
  width: 100%;
  height: var(--aura-c-form-input-height, $input-field-height);
  // @include display-flex();
  // justify-content: space-between;
  // align-items: center;
  // flex-direction: row-reverse;
  @include font-size($font-size-input-mobile);
  color: var(--aura-c-dropdown-text-color, $color-text-default);
  text-align: left;
  white-space: nowrap;
  cursor: pointer;
  font-weight: var(--aura-c-dropdown-font-weight, $font-weight-normal);
  line-height: var(--aura-c-dropdown-height, $dropdown-height);
  border-style: solid;
  border-width: var(--aura-c-dropdown-border-width, $c-dropdown-border-width);
  border-color: var(--aura-c-dropdown-color-border, $color-border-input);
  border-radius: var(--aura-c-dropdown-border-radius, $form-input-border-radius);

  padding-top:    var(--aura-c-dropdown-padding-block-start, $dropdown-padding-block);
  padding-right:  var(--aura-c-dropdown-padding-inline-end, $dropdown-padding-inline-end); // space for arrow icon
  padding-bottom: var(--aura-c-dropdown-padding-block-end, $dropdown-padding-block);
  padding-left:   var(--aura-c-dropdown-padding-inline-start, $dropdown-padding-inline-start);

  overflow: hidden;
  text-overflow: ellipsis;
  background: none;
  // box-shadow: var(--aura-c-dropdown-shadow, $dropdown-shadow);
  transition: var(--aura-c-dropdown-transition, $c-dropdown-transition);
  @include appearance(none);

  // &,
  // &:link,
  // &:visited {
  //   color: var(--aura-c-dropdown-text-link-color, $dropdown-text-link-color);
  // }

  // &:hover,
  // &:active {
  //   color: var(--aura-c-dropdown-text-link-color-hover, $dropdown-text-link-color-hover);
  //   text-decoration: none;
  // }

  // &.is-focus,
  // &:hover,
  // &:focus,
  // &:active,
  // &:focus-within {
  //   outline: 0;
  //   border-color: var(--aura-c-form-input-color-border-focus, $color-border-input-focus);
  //   box-shadow: none; //var(--aura-c-dropdown-shadow-focus, $dropdown-shadow-focus);
  // }

  .c-svg {
    @include position-y-center();
    right:  4px;
    width:  var(--aura-c-form-input-select-arrow-size, $select-arrow-icon-sizing);
    height: var(--aura-c-form-input-select-arrow-size, $select-arrow-icon-sizing);
    color: inherit;
  }

  &:disabled,
  &[disabled="disabled"],
  &.is-disabled {
    cursor: default;
    background-color: var(--aura-c-form-input-color-background-disabled, $color-background-input-disabled);
    opacity: var(--aura-c-form-input-opacity-disabled, $opacity-disabled);
  }

  @include mq($from: tablet) {
    @include font-size($font-size-input);
  }
}


// Menu dropdown
.c-dropdown__list {
  list-style: none;
  margin: 0;
  position: absolute;
  top: 100%;
  left: 0;
  @include translateY(-6px);
  width: auto;
  min-width: 100%;
  max-width: $dropdown-max-width;
  padding: $spacing-tiny 0;
  font-weight: var(--aura-c-dropdown-font-weight, $font-weight-normal);
  text-align: left;
  // white-space: nowrap;
  background-color: var(--aura-c-dropdown-color-background, $dropdown-color-background);
  border-style: solid;
  border-width: var(--aura-c-dropdown-border-width, $dropdown-border-width);
  border-color: var(--aura-c-dropdown-color-border, $color-border-input);
  border-radius: var(--aura-c-dropdown-border-radius, $dropdown-list-border-radius);
  box-shadow: $dropdown-list-shadow;
  visibility: hidden;
  opacity: 0;
  transition: $global-transition;
  overflow: hidden;
  z-index: 1;

  .c-dropdown:hover & {
    opacity: 1;
    visibility: visible;
    will-change: opacity;
    @include translateY(4px);
  }

}

// .c-dropdown__item {
//   margin: 0;
//   padding: 0;
//   list-style: none;
// }

.c-dropdown__item {
  list-style: none;
  @include a-block();
  padding: $spacing-tiny;
  background-color: transparent;
  transition: $background-color-transition, $text-color-transition;
  cursor: pointer;

  // &,
  // &:link,
  // &:visited {
    color: var(--aura-c-dropdown-text-link-color, $dropdown-list-text-color);
  //   text-decoration: none;
  // }

  &:hover,
  &:active {
    color: var(--aura-c-dropdown-list-text-color-hover, $dropdown-list-text-color-hover);
    background-color: var(--aura-c-dropdown-list-color-background-hover, $dropdown-list-color-background-hover);
    text-decoration: none;
    will-change: background-color;
  }

}



/* Dropdown types
   ========================================================================== */
.c-dropdown--pill {
  .c-dropdown__target {
    border-width: var(--aura-c-form-input-border-width, $form-input-border-width);
    border-radius: $border-radius-pill;
    padding-left: $spacing-small;
    padding-right:  var(--aura-c-form-select-padding-inline-end, form-input-padding-inline-icon);

    .c-svg {
      right: 8px;
    }
  }
}


.c-dropdown--status {

  // page is not editable, hide caret in dropdown
  .is-read-only & {

    .c-dropdown,
    .c-dropdown__target {
      &:hover {
        cursor: default;
      }
    }

    .c-dropdown__target {
      .c-svg {
        display: none;
      }
    }

  }

  // Custom - show status `dots` with color
  &[data-status="Pending"] {
    
    .c-dropdown__target {
      color: var(--aura-c-dropdown-text-link-color-primary, $badge-color-text-primary);
      background-color: var(--aura-c-dropdown-color-background-primary, $color-background-primary-lightest) !important;
    }

    &.is-focus,
    &:focus,
    &:active {
      border-color: var(--aura-c-dropdown-color-border-primary, $color-border-primary);
    }

  }

  &[data-status="OnHold"],
  &[data-status="VendorHold"] {
    background-color: var(--aura-c-dropdown-color-background-light, $color-background-gray-lighter) !important;
    
    &,
    .c-dropdown__target {
      color: var(--aura-c-dropdown-text-link-color-light, $badge-color-text-light);
    }

    &,
    &.is-focus,
    &:focus,
    &:active {
      border-color: var(--aura-c-dropdown-color-border-light, $color-border-mid-light);
    }

  }

  &[data-status="Sent"],
  &[data-status="Late"],
  &[data-status="SentTo3Pl"],
  &[data-status="Confirmed"] {
    background-color: var(--aura-c-dropdown-color-background-warning, $color-background-warning-lighter) !important;
    
    &,
    .c-dropdown__target {
      color:  var(--aura-c-dropdown-text-link-color-warning, $badge-color-text-warning);
    }

    &,
    &.is-focus,
    &:focus,
    &:active {
      border-color: var(--aura-c-dropdown-color-border-warning, $color-background-warning-lighter);
    }

  }

  &[data-status="Shipped"],
  &[data-status="Delivered"],
  &[data-status="AutoShipped"],
  &[data-status="Ready for pickup"] {
    background-color: var(--aura-dropdown-color-background-success, $color-background-success-lighter) !important;
    
    &,
    .c-dropdown__target {
      color: var(--aura-c-dropdown-text-link-color-success, $badge-color-text-success);
    }

    &,
    &.is-focus,
    &:focus,
    &:active {
      border-color: var(--aura-dropdown-color-border-success, $color-background-success-lighter);
    }

  }

  &[data-status="Closed"],
  &[data-status="Cancelled"],
  &[data-status="AutoCancelled"] {
    background-color: var(--aura-dropdown-color-background-danger, color-background-danger-light) !important;
    
    &,
    .c-dropdown__target {
      color: var(--aura-c-dropdown-text-link-color-danger, $badge-color-text-danger);
    }

    &,
    &.is-focus,
    &:focus,
    &:active {
      border-color: var(--aura-dropdown-color-border-danger, color-background-danger-light);
    }

  }

}