/* ==========================================================================
   #SITENAV
   ========================================================================== */

$site-nav-close-button-sizing:                  $icon-size-default !default;
$site-nav-mobile-toggle-color:                  $color-white !default;

$site-nav-color-background-mobile:              $color-white !default;
$site-nav-color-background-active-mobile:       $color-background-gray-lighter !default;

$site-nav-username-font-size-mobile:            $font-size-h5 !default;
$site-nav-username-font-weight-mobile:          $font-weight-light !default;
$site-nav-username-text-color-mobile:           $color-text-primary !default;

$site-nav-align-items:                          center !default; // [ flex-start, flex-end, center ]
$site-nav-position:                             left !default;  // [ left for Provider, gight for Patient ]

$site-nav-branding-flex-grow:                   1 !default;
$site-nav-list-flex-grow:                       1 !default;

$site-nav-logo-spacing-inline-mobile:           $spacing-small !default;
$site-nav-logo-spacing-inline:                  $spacing-default !default;

$site-nav-branding-spacing-inline:              $spacing-default !default;
$site-nav-margin-inline-tablet:                 $spacing-small !default;
$site-nav-margin-inline-desktop:                $spacing-small !default;
$site-nav-padding-mobile:                       $spacing-small !default;
$site-nav-padding-block:                        0 !default;
$site-nav-padding-inline:                       $spacing-default !default;
$site-nav-spacing-inline-icon:                  40px !default;
$site-nav-min-height:                           $header-min-height !default;

$site-nav-text-color-link-mobile:               $color-text-default !default;
$site-nav-text-color-link-active-mobile:        $color-black !default;
$site-nav-text-color-link:                      $color-white !default; // $color-text-default !default;
$site-nav-text-color-link-hover:                $color-white !default; // $color-black !default;
$site-nav-text-color-link-active:               $color-white !default; // $color-text-link !default;
$site-nav-color-background-hover:               rgba($color-white, 0.1) !default; // transparent !default;
$site-nav-color-background-active:              rgba($color-white, 0.1) !default; // transparent !default;

// Main menu dropdowns
$site-nav-submenu-font-size-mobile:             $font-size-small !default;
$site-nav-submenu-padding-block-mobile:         $spacing-tiny !default;
$site-nav-submenu-padding-inline-mobile:        $spacing-small !default;
$site-nav-submenu-padding-block:                $spacing-small !default;
$site-nav-submenu-padding-inline:               $spacing-small !default;
$site-nav-submenu-min-width:                    160px !default;
$site-nav-submenu-max-width:                    260px !default;
$site-nav-submenu-left-position:                -$spacing-micro !default;
$site-nav-submenu-border-radius:                $border-radius-small !default;
$site-nav-submenu-color-background:             $color-white !default;
$site-nav-submenu-color-border:                 $color-border-lighter !default;
$site-nav-submenu-text-color-link:              $color-text-default !default;
$site-nav-submenu-text-color-link-hover:        $color-black !default;
$site-nav-submenu-text-color-link-active:       $color-text-primary !default;
$site-nav-submenu-color-background-hover:       $color-background-gray-lighter !default;
$site-nav-submenu-color-background-active:      transparent !default;


// Menu item is icon only
$site-nav-icon-size:                            $icon-size-default !default;

// Stacked or side nav
$stacked-nav-margin-block-end:                  $spacing-large !default;
$stacked-nav-padding-block-mobile:              $spacing-tiny !default;
$stacked-nav-padding-inline-mobile:             $spacing-small !default;
$stacked-nav-padding-inline-end:                $spacing-default !default;
$stacked-nav-item-show-border-bottom:           false !default;  // show the bottom border
$stacked-nav-text-link-color:                   $color-text-default !default;
$stacked-nav-text-link-color-hover:             $color-black !default;
$stacked-nav-text-link-color-active:            $color-text-primary !default;
$stacked-nav-link-color-background-active-mobile: $color-background-gray-light !default;
$stacked-nav-link-color-background-active:        transparent !default;

// Tabs style
$tabs-nav-margin-block-end:                     $spacing-large !default;
$tabs-nav-padding-inline-mobile:                $spacing-small !default;
$tabs-nav-padding-inline:                       $spacing-default !default;
$tabs-nav-border-bottom-sizing:                 $border-width-x-thick !default;
$tabs-nav-border-color-active:                  $color-border-primary !default;
$tabs-nav-border-color-hover:                   $resmed-brand-gray-palette-400 !default;
$tabs-nav-text-color-link:                      $color-text-default !default;
$tabs-nav-text-color-active:                    $color-text-primary !default;
$tabs-nav-height:                               60px !default;
$tabs-nav-pipe-dividers:                        false !default;  // Show a small vertical dividing line between tabs @tablet and above

// Using h1 as wordmark or title
$site-nav-title-font-size:                      $font-size-h5 !default;
$site-nav-title-font-weight:                    $font-weight-medium !default;