/* ========================================================================
   #TEXT-COLOR
   ======================================================================== */
/**
 * Text color tokens

  type: color
  category: text-color
  cssProperties:
    - color

 */

/**
 * ATTENTION
 *
 * Usage:
 * Until the design token pipeline is in place, find the file
 *  `_themes.custom-tokens.scss` 
 * in the themes folder and rename it to 
 *  `_themes.custom-{product-name}-tokens.scss`
 *
 * You will use this file to override !default token values as needed.
 */


/**
 * DO NOT EDIT ANYTHING BELOW 
 * Override default token values in YOUR custom tokens file.
 */

$color-text-default:                $resmed-brand-gray-palette-800 !default;
$color-text-secondary:              $color-black-85 !default;
$color-text-disabled:               $color-black-40 !default;
$color-text-light-secondary:        $color-white-80 !default;
$color-text-light-disabled:         $color-white-50 !default;
$color-text-inverse:                $color-white !default;

$color-text-selection:              $palette-light-yellow-contrast-500 !default;

$color-text-primary:                $theme-primary-color !default;
$color-text-primary-dark:           $theme-primary-color-700 !default;
$color-text-danger:                 $resmed-brand-022-palette-600 !default;
$color-text-success:                $palette-mod-green-500 !default;
$color-text-warning:                $palette-light-orange-500 !default;
$color-text-dark:                   $resmed-brand-gray-palette-900 !default;

$color-text-primary-contrast:       $theme-primary-color-contrast !default;
$color-text-danger-contrast:        $color-white !default;
$color-text-success-contrast:       $color-white !default;
$color-text-warning-contrast:       $color-black-85 !default;
$color-text-dark-contrast:          $color-white !default;
$color-text-light-contrast:         $color-black-85 !default;

$color-text-icon-default:           $resmed-brand-gray-palette-700 !default;
$color-text-icon-hover:             $color-black !default;
$color-text-icon-active:            $theme-primary-color !default;
$color-text-icon-disabled:          $color-black-20 !default;

$color-text-link:                   $theme-primary-color !default;
$color-text-link-visited:           $resmed-brand-007 !default;
$color-text-link-hover:             $theme-primary-color-hover !default;
$color-text-link-active:            $theme-primary-color-active !default;

$color-text-link-inverse:           $color-white !default;
$color-text-link-inverse-visited:   $color-white-85 !default;
$color-text-link-inverse-hover:     $color-white-75 !default;
$color-text-link-inverse-active:    $color-white-60 !default;

$color-text-label:                  $color-text-default !default;
$color-text-required:               $resmed-brand-022-palette-500 !default;
$color-text-placeholder:            $color-black-20 !default;
$color-text-form-hint:              $color-text-secondary !default;
$color-text-form-error:             $color-text-danger !default;

$badge-color-text-primary:          $theme-primary-color-700 !default;
$badge-color-text-secondary:        $resmed-brand-gray-palette-800 !default;
$badge-color-text-success:          $palette-dark-lime-400 !default;
$badge-color-text-warning:          $palette-vivid-orange-600 !default;
$badge-color-text-danger:           $resmed-brand-022-palette-600 !default;
$badge-color-text-error:            $color-white !default;
$badge-color-text-dark:             $resmed-brand-gray-palette-100 !default;
$badge-color-text-light:            $resmed-brand-gray-palette-600 !default;

$button-color-text:                 $theme-primary-color !default;
$button-color-text-hover:           $theme-primary-color-hover !default;
$button-color-text-active:          $theme-primary-color-active !default;

$button-primary-color-text:         $color-text-primary-contrast !default;
$button-primary-color-text-hover:   $color-text-primary-contrast !default;
$button-primary-color-text-active:  $color-text-primary-contrast !default;

$button-outline-color-text:         $theme-primary-color !default;
$button-outline-color-text-hover:   $theme-primary-color !default;
$button-outline-color-text-active:  $theme-primary-color !default;

$button-neutral-color-text:                 $color-white !default;
$button-neutral-color-text-hover:           $color-white !default;
$button-neutral-color-text-active:          $color-white !default;

$button-outline-neutral-color-text:         $resmed-brand-gray-palette-700 !default;
$button-outline-neutral-color-text-hover:   $resmed-brand-gray-palette-700 !default;
$button-outline-neutral-color-text-active:  $resmed-brand-gray-palette-700 !default;

$button-inverse-color-text:                 $color-white !default;
$button-inverse-color-text-hover:           $resmed-brand-gray-palette-200 !default;
$button-inverse-color-text-active:          $color-white !default;

$button-primary-inverse-color-text:         $resmed-brand-gray-palette-900 !default;
$button-primary-inverse-color-text-hover:   $resmed-brand-gray-palette-900 !default;
$button-primary-inverse-color-text-active:  $resmed-brand-gray-palette-900 !default;

$button-outline-inverse-color-text:         $color-white !default;
$button-outline-inverse-color-text-hover:   $color-white !default;
$button-outline-inverse-color-text-active:  $resmed-brand-gray-palette-900 !default;

$button-neutral-inverse-color-text:         $resmed-brand-gray-palette-900 !default;
$button-neutral-inverse-color-text-hover:   $resmed-brand-gray-palette-900 !default;
$button-neutral-inverse-color-text-active:  $resmed-brand-gray-palette-900 !default;

$button-text-inverse-color-text:            $color-white !default;
$button-text-inverse-color-text-hover:      $resmed-brand-gray-palette-200 !default;
$button-text-inverse-color-text-active:     $color-white !default;


$card-heading-color-text:           $color-text-default !default;
$card-color-text:                   $color-text-default !default;
$footer-color-text:                 $color-white !default;
$footer-color-link-text:            $color-white !default;
$tooltip-color-text:                $resmed-brand-gray-palette-300 !default;
$expander-label-color-text:         $color-text-default !default;
$expander-label-color-text-active:  $color-text-primary !default;
$pagination-color-text:             $color-text-default !default;
$pagination-color-text-hover:       $color-black !default;
$pagination-color-text-current:     $color-black !default;
$popover-dark-color-text:           $color-white-80 !default;
$chip-color-text:                   $color-text-default !default;
