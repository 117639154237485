/* ========================================================================
   #BREAKPOINTS
   ======================================================================== */
// The breakpoints map we are using for media queries.
// This map will override the !default values in `/sass-mq/_mq.scss`.
$breakpoints: (
  mobile:   $bp-320,    // default, mobile-portrait
  tablet:   $bp-768,    // tablet-portrait
  desktop:  $bp-992,    // tablet-landscape
  wide:     $bp-1200,   // laptops
  laptop:   $bp-1366,   // HD laptops, non-responsive sites max-width [ Provider sites ]
  x-wide:   $bp-1600    // desktop displays, wider than default max-width of 1440px [ Patient sites ]
);


/**
 * If you want to display the currently active breakpoint in the top
 * right corner of your site during development, add the breakpoints
 * to this list, ordered by width, e.g. (mobile, tablet, desktop).
 */
// $show-breakpoints: (mobile, tablet, desktop, wide, x-wide);
