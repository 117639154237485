/* ========================================================================
   #DURATION
   ======================================================================== */
/**
 * Timing tokens

  type: time
  category: time
  cssProperties:
  - 'animation*'
  - 'transition*'y

 */

/**
 * ATTENTION
 *
 * Usage:
 * Until the design token pipeline is in place, find the file
 *  `_themes.custom-tokens.scss`
 * in the themes folder and rename it to
 *  `_themes.custom-{product-name}-tokens.scss`
 *
 * You will use this file to override !default token values as needed.
 */


/**
 * DO NOT EDIT ANYTHING BELOW
 * Override default token values in YOUR custom tokens file.
 */
$duration-immediately:    0.05s !default;
$duration-instantly:      0s !default;
$duration-paused:         3.2s !default;
$duration-promptly:       0.2s !default;
$duration-quickly:        0.1s !default;
$duration-slowly:         0.4s !default;
