/* ==========================================================================
   #PROPERTIES
   ========================================================================== */
/**
 * Webkit font-smoothing
 * Values: none, antialiased (default), subpixel-antialiased
 */
@mixin font-smoothing($value: antialiased) {
  @if $value == antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}


// Mixin to quickly apply accessible hiding to elements.
@mixin hide-from-screen() {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}


/**
 * Make `a` selector a block element and make it fill the width and height of
 * its parent container
 */
@mixin a-block() {
  display: block;
  width: 100%;
  height: 100%;
}


// Generate `:hover` and `:focus` styles in one go.
@mixin hocus() {
  &:hover,
  &:focus {
    @content;
  }
}

// Generate all anchor states in one go.
@mixin all-anchor-states() {
  &,
  &:link,
  &:visited,
  &:hover,
  &:active,
  &:focus {
    @content;
  }
}

@mixin plain-hover-focus() {
  &,
  &:hover,
  &:focus {
    @content;
  }
}

@mixin hover-focus-active() {
  &:hover,
  &:focus,
  &:active {
    @content;
  }
}



/* Use ellipsis to truncate text that overflows its container
 * .simple-usage {
 *   @include ellipsis();
 * }
 *
 * .detailed-usage {
 *   @include ellipsis(<value>);
 * }
 */
@mixin ellipsis($width: 100%) {
  max-width: $width;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


@mixin display-flex() {
  display: -webkit-box;  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -moz-box;
  display: -ms-flexbox;  /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;         /* NEW, Spec - Firefox, Chrome, Opera */
}

@mixin flex-direction-column() {
  /* Your grandparents flexbox for Webkit */
  -webkit-box-orient: vertical;
  /* Prefixed new syntax: */
  -webkit-flex-direction: column;
  /* Same syntax for weird IE tweener */
  -ms-flex-direction: column;
  /* unprefixed new syntax */
  flex-direction: column;
}

@mixin flex-direction-row() {
  /* Your grandparents flexbox for Webkit */
  -webkit-box-orient: horizontal;
  /* Prefixed new syntax: */
  -webkit-flex-direction: row;
  /* Same syntax for weird IE tweener */
  -ms-flex-direction: row;
  /* unprefixed new syntax */
  flex-direction: row;
}

@mixin appearance($value, $important: false) {
  @if ($important == true) {
    $important: !important;
  } @else if ($important == false) {
    $important: null;
  } @else {
    @error "`#{$important}` needs to be `true` or `false`.";
  }

  -webkit-appearance: $value $important;
  -moz-appearance:    $value $important;
  appearance:         $value $important;
}


/**
 * Create a text highlighting style
 *
 *   @include selection {
 *     background-color: #value;
 *     color: #value;
 *     text-shadow: none;
 *   }
 */
@mixin selection {
  ::-moz-selection {
    @content;
  }
  ::selection {
    @content;
  }
}


// Calc mixin
@mixin calc($property, $variable, $operation, $value, $fallback) {
  #{$property}: $fallback; //for browsers that don't support calc function
  #{$property}: -mox-calc(#{$variable} #{$operation} #{$value});
  #{$property}: -o-calc(#{$variable} #{$operation} #{$value});
  #{$property}: -webkit-calc(#{$variable} #{$operation} #{$value});
  #{$property}: calc(#{$variable} #{$operation} #{$value});
}


// clip-path
@mixin clip-path($clip) {
  -webkit-clip-path: $clip;
  -moz-clip-path: $clip;
  -ms-clip-path: $clip;
  clip-path: $clip;
}


/**
 * Force wrap a long string of text that breaks the layout, like a URL. 
 * Can also use utility classes `.u-text-wrap` and `.u-text-wrap-nohyphen`
 * in your markup.
 */
@mixin word-wrap($hyphen: auto) {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: $hyphen;
  -moz-hyphens: $hyphen;
  -webkit-hyphens: $hyphen;
  hyphens: $hyphen;
}


/* CSS hacks to do some browser specific styles */
@mixin target-browser($browser) {
  @if $browser == null {
    @error "You must specify a browser target [ IE | Edge | FF | Safari | Chromium ]";
  }
  @else {
  
    // Internet Explorer
    @if $browser == IE  {
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        @content;
      }
    }

    // Microsoft Edge
    @else if $browser == Edge  {
      @supports (-ms-ime-align: auto) {
        @content;
      }
    }
    
    // Mozilla Firefox
    @else if $browser == FF  {
      @-moz-document url-prefix() {
        @content;
      }
    }
    
    // Safari
    @else if $browser == Safari  {
      @media not all and (min-resolution: 0.001dpcm) {
        @content;
      }
    }
    
    // Chrominum
    @else if $browser == Chrominum  {
      @media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
        *:not(*:root) {
          @content;
        }
      }
    }

    @else {
      @error "You must specify one of the following browser targets [ IE | Edge | FF | Safari | Chromium ]";
    }

  }
}
