/* ========================================================================
   #OPACITY
   ======================================================================== */
/**
 * Opacity tokens

  type: opacity
  category: opacity
  cssProperties:
  - opacity

 */

/**
 * ATTENTION
 *
 * Usage:
 * Until the design token pipeline is in place, find the file
 *  `_themes.custom-tokens.scss` 
 * in the themes folder and rename it to 
 *  `_themes.custom-{product-name}-tokens.scss`
 *
 * You will use this file to override !default token values as needed.
 */


/**
 * DO NOT EDIT ANYTHING BELOW 
 * Override default token values in YOUR custom tokens file.
 */
$opacity-3:           0.3 !default;
$opacity-5:           0.5 !default;
$opacity-8:           0.8 !default;
$opacity-9:           0.9 !default;

$opacity-disabled:    $opacity-3 !default;
$opacity-hover:       $opacity-8 !default;