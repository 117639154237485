/* ==========================================================================
   #ROBOTO
   ========================================================================== */
// Load Roboto @font-face rules if it is the chosen font-family for the theme.
// Font-family set in theme file or default setting in `settings.typography`.

$fonts-folder: "../fonts/" !default;
$roboto-folder: "roboto/";

@mixin load-roboto-fonts {
  @font-face {
    font-family: 'Roboto';
    src: url($fonts-folder + $roboto-folder + 'Roboto-Thin-webfont.woff') format('woff');
    font-weight: 100;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Roboto';
    src: url($fonts-folder + $roboto-folder + 'Roboto-ThinItalic-webfont.woff') format('woff');
    font-weight: 100;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Roboto';
    src: url($fonts-folder + $roboto-folder + 'Roboto-Light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Roboto';
    src: url($fonts-folder + $roboto-folder + 'Roboto-LightItalic-webfont.woff') format('woff');
    font-weight: 300;
    font-style: italic;
  } 

  // @font-face {
  //   font-family: 'Roboto';
  //   src: url($fonts-folder + $roboto-folder + 'RobotoCondensed-Light-webfont.woff') format('woff');
  //   font-weight: 300;
  //   font-style: normal;
  // }
  
  // @font-face {
  //   font-family: 'Roboto';
  //   src: url($fonts-folder + $roboto-folder + 'RobotoCondensed-LightItalic-webfont.woff') format('woff');
  //   font-weight: 300;
  //   font-style: italic;
  // }

  
  @font-face {
    font-family: 'Roboto';
    src: url($fonts-folder + $roboto-folder + 'Roboto-Regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Roboto';
    src: url($fonts-folder + $roboto-folder + 'Roboto-Italic-webfont.woff') format('woff');
    font-weight: 400;
    font-style: italic;
  }
  
  // @font-face {
  //   font-family: 'Roboto';
  //   src: url($fonts-folder + $roboto-folder + 'RobotoCondensed-Regular-webfont.woff') format('woff');
  //   font-weight: 400;
  //   font-style: normal;
  // }
  
  // @font-face {
  //   font-family: 'Roboto';
  //   src: url($fonts-folder + $roboto-folder + 'RobotoCondensed-Italic-webfont.woff') format('woff');
  //   font-weight: 400;
  //   font-style: italic;
  // }
  
  @font-face {
    font-family: 'Roboto';
    src: url($fonts-folder + $roboto-folder + 'Roboto-Medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Roboto';
    src: url($fonts-folder + $roboto-folder + 'Roboto-MediumItalic-webfont.woff') format('woff');
    font-weight: 500;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Roboto';
    src: url($fonts-folder + $roboto-folder + 'Roboto-Bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Roboto';
    src: url($fonts-folder + $roboto-folder + 'Roboto-BoldItalic-webfont.woff') format('woff');
    font-weight: 700;
    font-style: italic;
  } 
  
  // @font-face {
  //   font-family: 'Roboto';
  //   src: url($fonts-folder + $roboto-folder + 'RobotoCondensed-Bold-webfont.woff') format('woff');
  //   font-weight: 700;
  //   font-style: normal;
  // }
  
  // @font-face {
  //   font-family: 'Roboto';
  //   src: url($fonts-folder + $roboto-folder + 'RobotoCondensed-BoldItalic-webfont.woff') format('woff');
  //   font-weight: 700;
  //   font-style: italic;
  // }

  
  @font-face {
    font-family: 'Roboto';
    src: url($fonts-folder + $roboto-folder + 'Roboto-Black-webfont.woff') format('woff');
    font-weight: 900;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Roboto';
    src: url($fonts-folder + $roboto-folder + 'Roboto-BlackItalic-webfont.woff') format('woff');
    font-weight: 900;
    font-style: italic;
  }
}