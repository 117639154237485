/* ==========================================================================
   #PERCENTAGE WIDTHS
   ========================================================================== */
/**
 * Re-purposing the `utilities/utilities.widths.scss` function to
 * generate a different series of utility classes that give a fluid width to
 * whichever element they’re applied, e.g.:
 *
 *   <div class="u-35/percent"></div>
 *
 * These classes are most commonly used in conjunction with a grid view control,
 * such as PrimeNG tables. Setting widths inline can break the responsive layout,
 * so we're making these utility classes instead.
 * e.g.:
 *
 *   <p-column styleClass="u-35/percent" field="name" ...></p-column>
 *
 *
 * Which fractions would you like in your grid system(s)?
 * By default, we provide you the percentages below,
 * e.g.:
 *
 *   .u-5/percent
 *   .u-6/percent
 *   .u-15/percent
 *   .u-45/percent
 *
 * You can overwrite these values by modifying the variables in '_setting.global.scss`.
 * DO NOT CHANGE THE VALUES HERE.
 */
$use-res-percentages: false !default;
$res-percentages: 5 6 7 8 9 10 11 12 13 14 15 16 17 18 19 20 25 30 35 40 45 50 55 60 65 70 !default;



// By default, we use fractions-like classes like `<div class="u-35/percent">`.
// You can change the `/` to whatever you fancy with this variable.

$res-delimiter: \/ !default;

// If the site is responsive, you probably want to make these classes be 100%
// width below a certain breakpoint. Below tablet is the default.

$res-breakpoint: $bp-768 !default;


// A mixin to spit out our width classes.

@mixin res-widths($percentages) {

  // Loop through the number of percentages.
  @each $width in $percentages {

    // Build a class in the format `.u-5/percent`.
    .u-#{$width}#{$res-delimiter}percent {
      width: $width * 1% !important;

      // If this is responsive, set the width to 100% below chosen breakpoint
      @if (variable-exists(mq-breakpoints)) {

        @include mq($until: $res-breakpoint) {
          width: 100% !important;
        }

      }

    }

  }

}




/**
 * A series of width helper classes that you can use to assign columns widths to a gridview
 * table. Classes take a fraction-like format (e.g. `.u-5/percent`). Use these in
 * your markup:
 *
 * <p:column class="u-35/percent">
 * <td class="u-15/percent">
 *
 * The following will generate widths helper classes based on the percentages
 * defined in the `$res-percentages` list.
 */

@if ($use-res-percentages == true) {
  @include res-widths($res-percentages);
}
