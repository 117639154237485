/* ==========================================================================
   #PAGE HEADER
   ========================================================================== */

/** 
 * 1. Need to force the `o-wrapper` element to expand to the max-width setting
 *    because of flexbox.
 */
.page-head {
  position: relative;
  @include display-flex();
  margin: 0;
  padding: 0;
  flex-shrink: 0;
  flex-direction: row !important;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  min-height: var(--aura-c-page-head-min-height, $header-min-height);
  background: var(--aura-c-page-head-color-background-fill, $page-head-color-background-fill);
  line-height: 0;
  z-index: $z-index-default;
  
  @include clearfix();

  .o-wrapper {
    width: 100%; /* [1] */
    background: var(--aura-c-page-head-color-background, $page-head-color-background);
  }

}


// .page-head__tagline {
//   margin: 0;
//   margin-bottom: 0;
//   text-align: right;
//   color: $resmed-brand-gray-palette-100;
//   @include font-size(12px);

//   @include mq($from: tablet) {
//     @include font-size(14px);
//   }

//   @include mq($from: desktop) {
//     @include font-size(18px);
//   }

// }




/* Aligment variants
   ========================================================================== */
.page-head--align-start {
  align-items: flex-start;
}

.page-head--align-end {
  align-items: flex-end;
}




/* Style variants
   ========================================================================== */
/**
 * 1. Override the max-width on the `.o-wrapper` element so the header will span
 *    the width of the viewport while keeping the outer gutters.
 * 2. Keep the ouside gutters in case it is being removed on the `.o-wrapper` 
 *    element at x-wide breakpoint.
 */
.page-head--full {

  .o-wrapper {
    width: 100% !important;
    max-width: 100% !important;  /* [1] */
    padding-right: $spacing-default; /* [2] */
    padding-left:  $spacing-default; /* [2] */
  }

}


.page-head--sticky {
  position: sticky;
  top: var(--aura-c-page-head-position-sticky-top, 0);
  z-index: 1071;

  .modaal-noscroll & {
    z-index: $z-index-sticky;
  }
  
  // Used for smooth transitions when a site wide global notification is 
  // displayed above the site header.
  transition: $scroll-top-transition;

}




/* Decoration variants
   ========================================================================== */
.page-head--border-bottom {
  border-bottom-style: solid;
  border-bottom-width: var(--aura-c-page-head-border-width, $page-head-border-width);
  border-bottom-color: var(--aura-c-page-head-border-color, $page-head-color-border);
}

.page-head--box-shadow {
  box-shadow: var(--aura-c-page-head-shadow, $page-head-shadow);
}