/* ==========================================================================
   #FORMS
   ========================================================================== */

/** Private framework-only tokens **/
$c-form-input-padding-block:          0 !default;
$c-form-input-padding-inline-start:   $spacing-small !default;
$c-form-input-padding-inline-end:     $spacing-small !default;
$c-form-input-padding-inline-icon:    $spacing-default !default;

$c-form-label-line-height:            1.25 !default;
$c-form-hint-line-height:             1.25 !default;
$c-form-error-line-height:            1.125 !default;

// Form input border styles
$c-form-input-border-width:           if($aura-theme-type == patient, 0 0 $form-input-border-width 0, $form-input-border-width) !default;

$c-form-input-number-max-width:       120px !default;
$c-form-input-textarea-min-height:    120px !default;
$c-form-input-textarea-width-tablet:  600px !default;

$c-form-input-md-height:              calc($input-field-height - 6px) !default;
$c-form-input-md-font-size:           13px !default;


/**
 * Define custom styles for our form inputs.
 *
 * Related files:
 * `elements/elements.forms.scss`
 */

// Break a form up into sections or wrap the whole form in this container if you
// want a bottom border.
.c-form-section {
  border-bottom-style: solid;
  border-bottom-width: var(--aura-c-form-section-sizing-border-bottom, 1px);
  border-bottom-color: var(--aura-c-form-section-color-border-bottom, $color-border);
  margin-bottom: var(--aura-c-form-section-margin-block-end, $spacing-default);
  @include clearfix();
}

// Standardize form column widths rather than using `u-` spacing classes,
// so we can control column widths without needing to change the markup.
// Must use pixel widths so the form fields will have a consistent length
// regardless of where it is in the markup, such as nested `o-layout` or
// within a container that is not full page width.
.c-form-column {
  width: 100%;

  @include mq($from: tablet) {
    width: 33.33333%;
  }

  /**
   * Form layout columns
   *
   * 24px of the width account's for `o-layout` gutter width.
   * Base unit is 88px, each size up adds 88px.
   * This way we have a measured length that allows the form inputs to
   * line-up vertically where possible or wanted.
   */

  // Normal or standard width
  @include mq($from: desktop) {
    width: 352px;
  }

  &--micro {
    @include mq($from: desktop) {
      width: 88px;
    }
  }

  &--tiny {
    @include mq($from: desktop) {
      width: 176px;
    }
  }

  &--small {
    @include mq($from: desktop) {
      width: 264px;
    }
  }

  &--large {
    @include mq($from: desktop) {
      width: 440px;
    }
  }

  &--huge {
    @include mq($from: desktop) {
      width: 528px;
    }
  }

  &--gigantic {
    @include mq($from: desktop) {
    width: 904px;
    }
  }

}

/**
 * Wrapper for a form component. A form component is comprised at minimum of a
 * label and an input field. Other elements include hint text, error messages,
 * links or buttons.
 *
 * HTML

    <div class="c-form-field">
      <div class="c-form-field__label">Label</div>
      <div class="c-form-field__input">
        <input type="text" value="" class="c-input" />
      </div>
    </div>

 *
 */
.c-form-field {
  position: relative;
  box-sizing: border-box;
  vertical-align: top;
  margin-bottom: var(--aura-c-form-margin-block-end, $spacing-default);

  @include mq($from: tablet) {
    display: inline-block;
    width: 100%;
  }

  &.has-left-label {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 0;

    > .c-form-field__label {
      margin-bottom: 0;
      margin-right: var(--aura-c-form-left-aligned-label-spacing-inline-end, $spacing-tiny);
      text-align: var(--aura-c-form-left-aligned-label-text-align, right);
    }

    > .c-form-field__input {
      flex: 1;
    }
  }

}



/**
 * Form field labels.
 *
 * 1. We don't want the label to be a block element but we want to add spacing
 *    between the label and the input.
 * 2. Force a line-break after the label.
 * 3. That time when you have a form field with no label, but need it to line up
 *    horizontally with other form fields in a row. Include an empty `.c-form-field__label`
 *    with a `&nbsp;` in it and add this class to the `.c-form-field` container.
 *
 * HTML

    <div class="c-form-field c-form--nolabel">
      <div class="c-form-field__label">&nbsp;</div>
      <div class="c-form-field__input"><input type="text" value="" class="c-input" /></div>
    </div>

 *
 */
.c-form-field__label {
  position: relative;
  display: block;
  color: var(--aura-c-form-label-text-color, $color-text-label);
  @include font-size($font-size-form-label, $c-form-label-line-height);
  font-weight: var(--aura-c-form-label-font-weight, $font-weight-form-label);
  margin-bottom: var(--aura-c-form-label-margin-block-end, $spacing-tiny);

  /* [3] */
  .c-form--nolabel & {
    display: block;
    // width: 1px;
  }

  .is-required {
    color: var(--aura-c-form-required-text-color, $color-text-form-error);
  }

  .is-optional {
    @include font-size(14px, inherit);
    color: var(--aura-c-form-hint-text-color, $color-text-form-hint);
  }

  &.text-sm {
    @include font-size($font-size-small, inherit);
  }

  @include mq($from: tablet) {
    display: inline-block; /* [1] */

    &::after { /* [2] */
      content: "\A";
      white-space: pre;
    }
  }

}


// Hint text for a form field.
.c-form-field__hint {
  display: block;
  margin: 0;
  padding-top: var(--aura-c-form-hint-spacing-block-start, $spacing-tiny);
  @include font-size($font-size-form-hint, $c-form-hint-line-height);
  color: var(--aura-c-form-hint-text-color, $color-text-form-hint);
  font-weight: var(--aura-c-form-hint-font-weight, $font-weight-form-hint);
}


// Inline error messaging on a form field.
.c-form-field__error {
  position: relative;
  padding: 0;
  padding-top: $spacing-tiny;
  @include font-size($font-size-form-error, $c-form-error-line-height);
  color: var(--aura-c-form-error-text-color, $color-text-form-error);
  font-weight: var(--aura-c-form-error-font-weight, $font-weight-form-error);

  a {
    @include all-anchor-states() {
      color: var(--aura-c-form-error-text-color, $color-text-form-error) !important;
      text-decoration: underline !important;
    }
  }

  /*
    Use absolute positioning to display the inline error message below the form input
    without it affecting the vertical spacing. Make sure there is room below the form input
    for the error message to be displayed properly.
  */
  &.is-pos-bottom {
    position: absolute;
    top: 100%;
    left: 0;
  }

  /*
    Depending on the dev platform, we may need to hide the error message element via CSS.
    Use this data attribute to hide the error message element and toggle visibility using
    JS or backend logic [ true | false ].
  */
  &[data-hidden="true"] {
    display: none;
  }
  &[data-hidden="false"] {
    display: block;
  }

}


// Display a read-only value under a label
.c-form-field__read-only {
  font-weight: var(--aura-c-form-readonly-font-weight, $font-weight-bold);
}

.c-form-field__icon {
  display: inline-block;
  position: relative;
  padding-left: 0.25rem;
  vertical-align: top;
  line-height: 1;
  z-index: 1;
}


/* Form input components
   ========================================================================== */
// Container for the input field
.c-form-field__input {
  position: relative;
  margin: 0;

  // If container is a <ul> for radio and checkbox lists
  @at-root ul#{&} {
    margin: 0;
    padding: 0;
    list-style: none;

    &.o-list-inline {
      @include mq($until: tablet) {
        margin-left: -$spacing-default; /* [3] */
      }

      > li {
        margin-bottom: $spacing-default;

        @include mq($until: tablet) {
          display: inline-block;
          text-align: left;
          padding-left: $spacing-default;
        }
      }
    }

  }

  // Position the validation icons
  // &::before {
  //   position: absolute;
  //   left: -20px;
  //   width:  20px;
  //   height: 20px;
  // }

}

/**
 * 1. Adding a height because some browsers, looking at you IE, don't add up
 *    the line-height and padding properly, so inputs can vary slightly in height.
 * 2. Remove any default browser stuff.
 */
.c-input {
  display: inline-block;
  width: 100%;
  height: var(--aura-c-form-input-height, $input-field-height); /* [1] */
  padding-top:    var(--aura-c-form-input-padding-block-start, $c-form-input-padding-block);
  padding-right:  var(--aura-c-form-input-padding-inline-end, $c-form-input-padding-inline-start);
  padding-bottom: var(--aura-c-form-input-padding-block-end, $c-form-input-padding-block);
  padding-left:   var(--aura-c-form-input-padding-inline-start, $c-form-input-padding-inline-end);

  // @if ($aura-theme-type == patient) {
  //   padding-right: var(--aura-c-form-input-padding-inline-end, 0);
  //   padding-left:  var(--aura-c-form-input-padding-inline-start, 0);
  // } @else {
  //   padding-right: var(--aura-c-form-input-padding-inline-end, $spacing-small);
  //   padding-left:  var(--aura-c-form-input-padding-inline-start, $spacing-small);
  // }

  @include font-size($font-size-input-mobile);
  color: var(--aura-c-form-input-text-color, $color-text-default);
  background: var(--aura-c-form-input-color-background, $color-background-form-input);
  background-color: var(--aura-c-form-input-color-background, $color-background-form-input);
  border-style: solid;
  border-width: var(--aura-c-form-input-border-width, $c-form-input-border-width);
  border-color: var(--aura-c-form-input-color-border, $color-border-input);
  border-radius: var(--aura-c-form-input-radius-border, $form-input-border-radius);
  box-shadow: none;
  @include appearance(none); /* [2] */
  transition: $border-color-transition, $text-color-transition;

  &.is-focus,
  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    border-color: var(--aura-c-form-input-color-border-focus, $color-border-input-focus);
  }

  // @if ($aura-theme-type == provider) {
  //   border: $form-input-border-width solid $color-border-input;
  //   border-radius: $form-input-border-radius;

  //   &.is-focus,
  //   &:focus,
  //   &:active {
  //     outline: none;
  //     box-shadow: none;
  //     border-color: $color-border-input-focus;
  //   }

  // }

  &:disabled,
  &[disabled="disabled"],
  &.is-disabled {
    cursor: default;
    background-color: var(--aura-c-form-input-color-background-disabled, $color-background-input-disabled);
    opacity: var(--aura-c-form-input-opacity-disabled, $opacity-disabled);
  }

  @include mq($from: tablet) {
    @include font-size($font-size-input);
  }

}



/* Input type modifiers
   ========================================================================== */
// Modifactions to the `.c-input` styles for individual field types.
.c-input--text {
  @if ($aura-theme-type == provider) {
    --aura-c-form-input-color-background: white;
    background: var(--aura-c-form-input-color-background, white);
    background-color: var(--aura-c-form-input-color-background, white);
  }

  // &.is-focus,
  // &:focus,
  // &:active {
  //   outline: none;
  //   box-shadow: none;
  // }

}

@mixin file-button {
  margin-right: 4px;
  padding: 0 $spacing-default;
  color: white;
  background-color: $resmed-brand-gray-palette-800;
  border-radius: $border-radius-small;
  border: 0;
  line-height: $input-field-height - 10;
  transition: $background-color-transition;

  &:hover {
    background-color: $resmed-brand-gray-palette-700;
    cursor: pointer;
  }

  &:active {
    background-color: $resmed-brand-gray-palette-900;
    cursor: pointer;
  }
}

input[type="file"] {

  &.c-input--file {
    padding: 4px 12px 4px 4px;

    &:hover,
    &:active {
      cursor: pointer;
    }

    &::-ms-browse {
      @include file-button();
    }

    &::-webkit-file-upload-button {
      @include file-button();
    }

    &::file-selector-button {
      @include file-button();
    }

  }

}


.c-input--number {
  max-width: var(--aura-c-form-input-number-max-width, $c-form-input-number-max-width);
}

.c-input--textarea {
  width: 100%;
  min-height: var(--aura-c-form-input-textarea-min-height, $c-form-input-textarea-min-height);
  padding: var(--aura-c-form-input-textarea-padding, $spacing-tiny);
  background-color: var(--aura-c-form-input-textarea-color-background, $color-background-textarea) !important;
  border-width: var(--aura-c-form-textarea-border-width, $form-input-border-width);

  @include mq($from: tablet) {
    &:not(.is-full-width) {
      max-width: var(--aura-c-form-input-textarea-width-tablet, $c-form-input-textarea-width-tablet);
    }
  }

  // &.is-focus,
  // &:focus,
  // &:active {
  //   border-color: $color-border-input-focus;
  // }

}

.c-input--full-width {
  width: 100% !important;
  max-width: 100% !important;
}


/**
 * We need to wrap select boxes so we can style them properly across browsers.
 * The select input needs to be inside a container with the `.c-input` class and
 * this modifier.
 *
 * HTML

    <div class="c-input c-input--select">
      <select>
        <option selected>Please select</option>
        <option>Option 1</option>
      </select>
    </div>

 *
 * 1. Override the padding of the `.c-input` class since we're using it as a wrapper.
 *    Padding gets applied to select element instead.
 * 2. Make our custom drop-down arrow pseudo-element.
 * 3. Font-family and icon for the select input arrow.
 */
.c-input--select,
.c-input--combobox {
  @include set-svg-bg-css-var("select-box-arrow-icon-hover", $svg-ddlb-arrow, $color-border-input-focus);

  position: relative;
  padding: 0 !important; /* [1] */
  overflow: hidden;
  vertical-align: bottom; // to counter extra space created by overflow hidden

  &::before { /* [2] */
    @include position-y-center();
    right:  4px;
    width:  var(--aura-c-form-input-select-arrow-size, $select-arrow-icon-sizing);
    height: var(--aura-c-form-input-select-arrow-size, $select-arrow-icon-sizing);
    transition: $background-color-transition;
    z-index: 0;
  }

  &:hover,
  &:focus,
  &:active,
  &:focus-within {
    cursor: pointer;
    background-color: var(--aura-c-form-input-color-background, $color-background-form-input);
    border-color: var(--aura-c-form-input-color-border-focus, $color-border-input-focus);

    &::before {
      @include svg-css-var-bg-img("select-box-arrow-icon-hover");
    }

  }
}

.c-input--combobox {
  @include set-svg-bg-css-var("combo-box-arrow-icon", $svg-ddlb-arrow, white);
  position: relative;

  &::before {
    @include svg-css-var-bg-img("combo-box-arrow-icon");
  }

  input {
    position: relative;
    display: block;
    width: 100%;
    height: var(--aura-c-select-height, $input-field-height - 2);
    padding-top:    var(--aura-c-form-select-padding-block-start, $c-form-input-padding-block);
    padding-right:  var(--aura-c-form-select-padding-inline-end, $c-form-input-padding-inline-icon); // space for arrow icon
    padding-bottom: var(--aura-c-form-select-padding-block-end, $c-form-input-padding-block);
    padding-left:   var(--aura-c-form-select-padding-inline-start, $c-form-input-padding-inline-start);
    color: var(--aura-c-form-input-text-color, $color-text-default);
    background-image: none; /* [1] */
    background-color: transparent; /* [1] */
    border: 0; /* [1] */
    border-color: transparent; /* [1] */
    @include appearance(none); /* [1] */
    -webkit-text-size-adjust: none; /* [1] */
    cursor: pointer; /* [1] */
    @include font-size($font-size-input-mobile);
    z-index: 1;

    &:focus,
    &:active {
      outline: none;
      box-shadow: none;
    }

    &::-webkit-calendar-picker-indicator {
      display: none !important;
    }

    @include mq($from: tablet) {
      @include font-size($font-size-input);
    }

  }
}


.c-input--select {
  @include set-svg-bg-css-var("select-box-arrow-icon", $svg-ddlb-arrow, $color-text-default);

  &::before { /* [2] */
    @include svg-css-var-bg-img("select-box-arrow-icon");
  }

  /**
   * 1. Reset some defaults on select just in case.
   * 2. Height of our `c-input` minus the top and bottom border height.
   * 3. Hide the native drop-down button arrow in IE10+.
   */
  select {
    position: relative;
    display: block;
    width: 100%;
    height: var(--aura-c-select-height, $input-field-height - 2); /* [2] */
    padding-top:    var(--aura-c-form-select-padding-block-start, $c-form-input-padding-block);
    padding-right:  var(--aura-c-form-select-padding-inline-end, $c-form-input-padding-inline-icon); // space for arrow icon
    padding-bottom: var(--aura-c-form-select-padding-block-end, $c-form-input-padding-block);
    padding-left:   var(--aura-c-form-select-padding-inline-start, $c-form-input-padding-inline-start);

    // @if ($aura-theme-type == patient) {
    //   padding: $c-form-input-padding-patient;
    // } @else {
    //   padding: $c-form-input-padding-provider;
    // }

    color: var(--aura-c-form-input-text-color, $color-text-default);
    background-image: none; /* [1] */
    background-color: transparent; /* [1] */
    border: 0; /* [1] */
    border-color: transparent; /* [1] */
    @include appearance(none); /* [1] */
    -webkit-text-size-adjust: none; /* [1] */
    cursor: pointer; /* [1] */
    @include font-size($font-size-input-mobile);
    z-index: 1;

    &::-ms-expand {
      display: none; /* [3] */
    }

    &:disabled,
    &[disabled="disabled"],
    &.is-disabled {
      cursor: default;
      opacity: var(--aura-c-form-input-opacity-disabled, $opacity-disabled);
    }

    &:focus,
    &:active {
      outline: none;
      box-shadow: none;
    }

    @include mq($from: tablet) {
      @include font-size($font-size-input);
    }

    option {
      max-width: 92%;
      font-size: inherit;
      line-height: inherit;
      background-color: transparent;
    }

  }

}



/**
 * Custom number picker
 * HTML:

    <div class="c-form-field u-1/3@tablet" data-validation="default" data-role="group" aria-labelledby="demoNumSpinnerLabel demoNumSpinnerCurrValue">
      <label class="c-form-field__label" id="demoNumSpinnerLabel">Label</label>
      <div class="c-form-field__input">

        <div class="c-num-spinner">
          <div hidden id="demoNumSpinnerCurrValue">The current value is <span data-num-spinner="current-value">0</span></div>
          <div hidden class="js-numSpinnerStrings" data-num-spinner-decrease="Decrease amount by" data-num-spinner-increase="Increase amount by" aria-hidden="true"></div>
          <button class="c-icon-btn c-num-spinner__btn"
                  tabindex="-1"
                  data-num-spinner="decrease"
                  type="button">
            <svg class="c-svg c-svg--remove-24"><use xlink:href="#c-svg--remove-24"></use></svg>
          </button>

          <input  type="number"
                  id="demoFormInputNumperPicker1"
                  class="c-input"
                  value="0"
                  tabindex="0"
                  aria-label="Label of what this input is for" />

          <button class="c-icon-btn c-num-spinner__btn"
                  tabindex="-1"
                  data-num-spinner="increase"
                  type="button">
            <svg class="c-svg c-svg--add-24"><use xlink:href="#c-svg--add-24"></use></svg>
          </button>

        </div>

      </div>
    </div>

 */
.c-num-spinner {
  white-space: nowrap;
  font-size: 0;

  .c-input,
  .c-num-spinner__btn {
    vertical-align: middle;
  }

  .c-num-spinner__btn {
    display: none;
    width:  var(--aura-c-form-input-num-spinner-button-width, 32px);
    height: var(--aura-c-form-input-num-spinner-button-height, $input-field-height);
    padding-left: 0;
    padding-right: 0;
    color: var(--aura-c-form-input-num-spinner-text-color, $color-text-default);
    text-align: center;
    border-radius: var(--aura-c-form-input-num-spinner-radius-border, $border-radius-small);

    &:hover,
    &:focus,
    &:active {
      color: var(--aura-c-form-input-num-spinner-text-color-active, $color-text-primary);
    }

    .c-svg {
      width:  var(--aura-c-form-input-num-spinner-button-icon-sizing, $icon-size-default);
      height: var(--aura-c-form-input-num-spinner-button-icon-sizing, $icon-size-default);
    }

    &.is-active {
      display: inline-block;
    }

  }

  .c-input {
    width: auto;
    min-width: var(--aura-c-form-input-num-spinner-input-width, 50px);
    max-width: var(--aura-c-form-input-num-spinner-max-width, 120px);
    padding-left:  var(--aura-c-form-input-num-spinner-padding-inline-start, $spacing-micro);
    padding-right: var(--aura-c-form-input-num-spinner-padding-inline-end, $spacing-micro);
    border-style: solid;
    border-width: var(--aura-c-form-input-border-width, $c-form-input-border-width);
    border-color: var(--aura-c-form-input-color-border, $color-border-input);
    border-radius: var(--aura-c-form-input-num-spinner-radius-border, $border-radius-small);

    &.is-focus {
      border-color: var(--aura-c-form-input-color-border-focus, $color-border-input-focus);
    }

    // Remove the native up/down controls from number inputs
    // so that we don't have double controls going on here.
    &.c-input--num-spinner {
      text-align: center;

      &,
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        @include appearance(none);
        -moz-appearance: textfield;
      }

    }

  }

}




/**
 * Fancy radio buttons and checkboxes
 * 1. Undo settings from our `.c-input` class and make our radio
 *    buttons and checkboxes stack as a list by default.
 */
.c-input--radio,
.c-input--checkbox {
  --aura-c-form-choice-sizing: 22;
  --aura-c-form-choice-sizing-small: 16;

  display: block; /* [1] */
  width: auto; /* [1] */
  height: fit-content; /* [1] */
  border: 0; /* [1] */
  margin:  0;
  padding: 0;
  // margin-bottom: $spacing-small;

  @at-root li#{&} {
    height: unset;
  }

  input[type="radio"],
  input[type="checkbox"] {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;

    + label,
    + .c-form-field__label,
    + input[type="hidden"] + label {
      position: relative;
      display: inline-block;
      color: var(--aura-c-form-input-text-color, $color-text-default);
      @include font-size($font-size-input-mobile);
      font-weight: var(--aura-c-form-choice-font-weight, $font-weight-normal);
      margin-bottom: 0;
      // Width of radio or checkbox plus padding to make space between the input and the label
      padding-left: var(--aura-c-form-choice-spacing-inline, calc((var(--aura-c-form-choice-sizing) * #{1px}) + #{$spacing-small}));
      vertical-align: middle;
      user-select: none;
      cursor: pointer;

      &::before {
        @include position-y-center();
        left: 0;
        width:  calc(var(--aura-c-form-choice-sizing) * 1px);
        height: calc(var(--aura-c-form-choice-sizing) * 1px);
        content: '';
        color: var(--aura-c-form-label-text-color, $color-text-label);
        text-align: center;
        background-color: var(--aura-c-form-choice-color-background, white);
        border-style: solid;
        border-width: var(--aura-c-form-choice-border-width, $border-width-thin);
        border-color: var(--aura-c-form-choice-color-border, $color-border-input);
        box-sizing: border-box;
        transition: all 0.4s ease;
      }

    }

    &:focus,
    &:hover {

      + label,
      + .c-form-field__label,
      + input[type="hidden"] + label {

        &::before {
          border-color: var(--aura-c-form-choice-color-border-focus, $color-border-input-focus);
          background-color: var(--aura-c-form-choice-color-background-focus, $color-background-primary-lightest);
        }

      }

    }

    &:active {

      + label,
      + .c-form-field__label,
      + input[type="hidden"] + label {

        &::before {
          transition-duration: 0s;
        }

      }

    }

  }

  // Smaller checkbox/radio and text when it is paired with a text input
  &.is-field-check {
    padding-top: $spacing-tiny;
    margin-bottom: 0;

    input[type="radio"],
    input[type="checkbox"] {

      + label,
      + .c-form-field__label,
      + input[type="hidden"] + label {

        padding-left: calc(var(--aura-c-form-choice-sizing) + #{$spacing-small});
        @include font-size($font-size-form-hint, 1.35);

        &::before {
          width:  var(--aura-c-form-choice-sizing-small);
          height: var(--aura-c-form-choice-sizing-small);
        }

      }

    }

  }

  &.align-top {
    input[type="radio"],
    input[type="checkbox"] {

      + label,
      + .c-form-field__label,
      + input[type="hidden"] + label {
        &::before {
          top: 3px;
          @include translateY(0);
        }
      }

    }

    input[type="radio"],
    input[type="checkbox"] {

      + label,
      + .c-form-field__label,
      + input[type="hidden"] + label {
        &::after {
          top: 14px;
        }
      }

    }

  }

}


/* Checkboxes */
.c-input--checkbox {
  --aura-c-form-checkmark-sizing-width: 11;
  --aura-c-form-checkmark-sizing-height: 5;
  --aura-c-form-checkmark-left-pos: 2;

  input[type="checkbox"] {

    + label,
    + .c-form-field__label,
    + input[type="hidden"] + label {

      &::before {
        border-radius: 4px;
      }
      // checkmark
      &::after {
        position: absolute;
        top: 50%;
        left: calc(var(--aura-c-form-checkmark-left-pos) * 1px);
        width:  calc(var(--aura-c-form-checkmark-sizing-width) * 1px);
        height: calc(var(--aura-c-form-checkmark-sizing-height) * 1px);
        border-width: 2px;
        border-style: solid;
        border-color: var(--aura-c-form-checkbox-color-check, $color-white);
        border-top-style: none;
        border-right-style: none;
        box-sizing: content-box;
        content: '';
        @include transform(scale(0) translateY(-50%) rotate(0deg));
        @include transform-origin(50% 0);
        transition: transform 200ms ease-out;
        z-index: 1;
      }

    }

    &:disabled,
    &[disabled="disabled"],
    &.is-disabled {

      + label,
      + .c-form-field__label,
      + input[type="hidden"] + label {
        color: var(--aura-c-form-choice-color-background-disabled, $color-background-checkbox-disabled);
        cursor: default;

        &::before {
          border-color: var(--aura-c-form-choice-color-background-disabled, $color-background-checkbox-disabled);
        }

        &:focus,
        &:hover {
          &::before {
            background-color: inherit;
          }
        }

      }


      &:checked {

        + label,
        + .c-form-field__label,
        + input[type="hidden"] + label {
          &::before {
            border-color: var(--aura-c-form-choice-color-background-disabled, $color-background-checkbox-disabled);
            background-color: var(--aura-c-form-choice-color-background-disabled, $color-background-checkbox-disabled);
          }
          &::after {
            border-color: var(--aura-c-form-checkbox-color-check, $color-white);
          }
        }

      }

    }

    &:checked {

      + label,
      + .c-form-field__label,
      + input[type="hidden"] + label {
          // color: $color-text-primary;

        &::before {
          @include animation(borderscale 300ms ease-in);
          border-color: var(--aura-c-form-choice-color-border-focus, $color-border-input-focus);
          background-color: var(--aura-c-form-choice-color-background-focus, $color-border-input-focus);
        }

        &::after {
          @include transform(scale(1) translateY(-50%) rotate(-45deg));
        }

      }

    }

  }

  // Smaller checkbox and text when it is paired with a text input
  &.is-field-check {

    input[type="checkbox"] {

      + label,
      + .c-form-field__label,
      + input[type="hidden"] + label {

        &::after {
          width:  9px;
          height: 3px;
        }

      }

    }

  }

  &.has-no-label {

    input[type="checkbox"] {
        + label,
        + .c-form-field__label,
        + input[type="hidden"] + label {
          padding-left: var(--aura-c-form-choice-sizing);
        }
    }

  }

}


/* Radio buttons */
.c-input--radio {
  --aura-c-form-radio-button-sizing: 12;
  --aura-c-form-radio-left-pos: 5;

  input[type="radio"] {

    + label,
    + .c-form-field__label,
    + input[type="hidden"] + label {

      &::before {
        border-radius: $border-radius-circle;
      }


      &::after {
        position: absolute;
        top: 50%;
        left:   calc(var(--aura-c-form-radio-left-pos) * 1px);
        width:  calc(var(--aura-c-form-radio-button-sizing) * 1px);
        height: calc(var(--aura-c-form-radio-button-sizing) * 1px);
        background-color: var(--aura-c-form-choice-color-background-focus, $color-border-input-focus);
        border-radius: $border-radius-circle;
        content: '';
        box-sizing: border-box;
        @include transform(scale(0) translateY(-50%));
        @include transform-origin(50% 0);
        transition: transform 200ms ease-out;
      }

    }

    &:disabled,
    &[disabled="disabled"],
    &.is-disabled {

      + label,
      + .c-form-field__label,
      + input[type="hidden"] + label {
        color: var(--aura-c-form-choice-color-background-disabled, $color-background-checkbox-disabled);
        cursor: default;

        &::before {
          border-color: var(--aura-c-form-choice-color-background-disabled, $color-background-checkbox-disabled);
        }

        &:focus,
        &:hover {
          &::before {
            background-color: inherit;
          }
        }

      }


      &:checked {

        + label,
        + .c-form-field__label,
        + input[type="hidden"] + label {
          &::after {
            background-color: var(--aura-c-form-choice-color-background-disabled, $color-background-checkbox-disabled);
          }
        }

      }

    }

    &:checked {

      + label,
      + .c-form-field__label,
      + input[type="hidden"] + label {
          // color: $color-text-primary;

        &::before {
          @include animation(borderscale 300ms ease-in);
          // border-color: $color-border-primary;
          background-color: var(--aura-c-form-radio-color-background-checked, $color-white);
        }

        &::after {
          @include transform(scale(1) translateY(-50%));
        }

      }

    }

  }

  // Smaller checkbox and text when it is paired with a text input
  &.is-field-check {

    input[type="radio"] {

      + label,
      + .c-form-field__label,
      + input[type="hidden"] + label {

        &::after {
          left: 4px;
          width:  10px;
          height: 10px;
        }

      }

    }

  }

}


/**
 * Combo field input and button, like a search field with an icon button next
 * to it.
 *
 *
 * 1. Add classes to target PrimeNG controls. Remove if not using PrimeNG.
 * 2. The field is probably in a colum or container with a width, make the
 *    field full width minus the width of your button. Default is 40px for
 *    the search icon.
 * 3. Remove the border radii on the right side of the field.
 *
 * HTML:

    <div class="c-form-field">
      <label class="c-form-field__label">Search</label>
      <div class="c-form-field__input c-form-field--btn-combo">
        <input type="text" name="text" value="" class="c-input c-input--btn-combo" />

        <label class="c-btn c-btn--primary c-btn--input-combo" for="btn-search">
          <svg class="c-svg c-svg--search-24" aria-labelledby="svg-title">
            <title id="svg-title">Search</title>
            <use xlink:href="#c-svg--search-24"></use>
          </svg>
          <input id="btn-search" type="submit" value="Search" class="c-btn__input" />
        </label>
      </div>
    </div>

 */
.c-form-field--btn-combo {
  @include display-flex();
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.c-input--btn-combo {

  &,
  &.ui-inputtext { /* [1] */
    padding-left:  $spacing-small;
    padding-right: $spacing-small;
    flex-grow: 1;
    // @include calc(width, 100%, "-", 40px, 76%); /* [2] */
    // max-width: 100%;
    border-style: solid;
    border-width: var(--aura-c-form-input-border-width, $c-form-input-border-width);
    border-color: var(--aura-c-form-input-color-border, $color-border-input);
    border-radius: var(--aura-c-form-input-radius-border, $form-input-border-radius);
    border-top-right-radius:    0 !important; /* [3] */
    border-bottom-right-radius: 0 !important; /* [3] */

    &.is-focus,
    &:focus,
    &:active {
      outline: none;
      box-shadow: none;
      border-color: var(--aura-c-form-input-color-border-focus, $color-border-input-focus);;
    }

  }

}




/* Validation styles
   ========================================================================== */
/**
 * Modify form fields to show inline validation. Requires js or back-end code
 * to update the `data-validation` property on the `.c-input--validation`
 * container.
 *
 * HTML

    <div class="c-form-field" data-validation="default | success | error | warning">
      <label class="c-form-field__label">Name</label>
      <div class="c-form-field__input">
        <input type="text" name="name" class="c-input" value="" />
      </div>
      <div class="c-form-field__hint">Hint: It's your name</div>
    </div>

 */

// 1. Make room for the validation icons that appear left of the field.
// .c-form--has-validation {
//   padding-left: $spacing-tiny; /* [1] */

//   @include mq($from: desktop) {
//       padding-left: 0;
//   }

// }

.c-form-field {

  &[data-validation="completed"] {

    .c-form-field__input:before {
      top: 13px;
      @include svg-bg-img($svg-field-success, $color-text-success, 41, 32);
    }

    .c-input {
      border-color: var(--aura-c-form-input-color-border-success, $color-border-success);

      &.c-input--radio,
      &.c-input--checkbox {

        label,
        .c-form-field__label {
          &::before {
            border-color: var(--aura-c-form-input-color-border-success, $color-border-success);
          }
        }

      }

    }

  }


  &[data-validation="error"] {

    // .c-form-field__label {
    //   color: var(--aura-c-form-error-text-color, $color-text-form-error);
    // }

    // .c-form-field__input:before {
    //   top: 13px;
    //   @include svg-bg-img($svg-field-error, 'da1f3d');
    // }

    .c-input {
      border-color: var(--aura-c-form-input-color-border-error, $color-border-error);

      &.c-input--radio,
      &.c-input--checkbox {

        label,
        .c-form-field__label {
          &::before {
            border-color: var(--aura-c-form-input-color-border-error, $color-border-error);
          }
        }

      }

    }

    // Show the error message
    .c-form-field__error {
      display: inline-block;
    }

  }


  &[data-validation="warning"] {

    // .c-form-field__input:before {
    //   top: 8px;
    //   left: -24px;
    //   @include svg-bg-img($svg-field-warning, 'ffc344');
    // }

    .c-input {
      border-color: var(--aura-c-form-input-color-border-warning, $color-border-warning);
      box-shadow: var(--aura-c-form-input-boc-shadow-warning, 0 0 4px $color-border-warning);
    }

    .c-input--radio [type="radio"],
    .c-input--checkbox [type="checkbox"] {
      &::before{
        border-color: var(--aura-c-form-input-color-border-warning, $color-border-warning);
      }
    }

  }

}




/* Form buttons
   ========================================================================== */
/**
 * Group your Save and Cancel buttons inline.
 * {Button} can be an anchor tag, button tag, or input.
 *
 * HTML

    <div class="c-form-buttons  [ o-layout o-layout--auto ]">
      <div class="o-layout__item">
        < {button} class="c-btn c-btn--primary" [...]>Save</>
      </div>
      <div class="o-layout__item">
        < {button} class="c-btn c-btn--secondary" [...]>Cancel</>
      </div>
    </div>

 *
 */
.c-form-buttons {
  margin-bottom: 0;
  padding-top: $spacing-default;

  @include mq($until: tablet) {
    text-align: center;
  }

  @include mq($from: tablet) {
    padding-top: $spacing-large;
  }

  &.o-layout--auto > .o-layout__item {
    @include mq($until: tablet) {
      width: 100% !important;
    }
  }

  &.o-layout > .o-layout__item {
    @include mq($until: tablet) {
      > .c-btn--primary {
        margin-bottom: $spacing-default;
      }
    }
  }

}




/* Hidden form control
   ========================================================================== */
/**
 * Form input that is hidden from view until a user interaction occurs.
 * Such as showning a text input field when the user selects `Other` from a
 * radio button list. Works with JS to toggle data-state.
 */
.c-hidden-field {

  .c-hidden-field__item {
    transition: max-height 500ms ease-out, opacity 500ms ease-out 150ms;
  }

  &[data-state="is-hidden"] {

    .c-hidden-field__item {
      max-height: 0;
      opacity: 0;
      overflow: hidden;
    }

  }

  &[data-state="is-animating"] {

    .c-hidden-field__item {
      opacity: 0;
    }

  }

  &[data-state="is-active"] {

    .c-hidden-field__item {
      margin-top: 0;
      max-height: 400px;
      overflow: visible;
      opacity: 1;
      will-change: opacity;
    }

  }

}

/* Size variants
   ========================================================================== */
.c-input--md {
  height: var(--aura-c-form-input-md-height, $c-form-input-md-height);

  &,
  select {
    @include font-size($c-form-input-md-font-size);
  }

  select {
    height: var(--aura-c-select-md-height, $c-form-input-md-height - 2);
  }

}
