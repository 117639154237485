/* ==========================================================================
   #PROGRESS
   ========================================================================== */

$progress-bar-height:               8px !default;
$progress-bar-font-size:            $font-size-caption !default;
$progress-bar-text-color:           $color-white !default;
$progress-bar-color-background:     $color-background-gray-light !default;
$progress-bar-color-fill:           $color-background-primary !default;
$progress-bar-border-radius:        $border-radius-pill !default;
$progress-bar-shadow:               inset 0 .1rem .1rem rgba($color-black, .1) !default;
$progress-bar-transition:           width $duration-slowly ease !default;
$progress-bar-stepper-max-width:    32px !default;

/** Private framework-only tokens **/
// $c-progress-bar-color-gradient-fill:  linear-gradient(to right, #1788c3 0, #6f2c91 50%, #da1f3d 100%) left/var(--p,100%) fixed, $progress-bar-color-background;

/**
 * A progress bar for showing `progress`.
 *
 * HTML:

    <div class="c-progress">
      <div class="c-progress__bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
    </div>

 *
 * 1. Force rounded corners by cropping it.
 * 2. In case we display the value within the progress bar. e.g.: '25%'
 */
.c-progress {
  position: relative;
  @include display-flex();
  height: var(--aura-progress-bar-height, $progress-bar-height);
  overflow: hidden; /* [1] */
  @include font-size($progress-bar-font-size); /* [2] */
  font-weight: var(--aura-progress-bar-font-weight, $font-weight-medium);
  background-color: var(--aura-progress-bar-color-background, $progress-bar-color-background);
  border-radius: var(--aura-progress-bar-border-radius, $progress-bar-border-radius);
  box-shadow: var(--aura-progress-bar-shadow, $progress-bar-shadow);
}


.c-progress__bar {
  @include display-flex();
  @include flex-direction-column();
  justify-content: center;
  color: var(--aura-c-progress-bar-text-color, $progress-bar-text-color);
  text-align: center;
  background-color: var(--aura-c-progress-bar-color-fill, $progress-bar-color-fill);
  border-radius: var(--aura-c-progress-bar-border-radius, $progress-bar-border-radius);
  transition: $progress-bar-transition;
}



/* Variants
========================================================================== */
/** Progress stepper
 *
 * HTML:

    <div class="c-progress-step-indicator" aria-label="progress">
      <ol id="demoProgressStepper" class="c-progress c-progress--stepper">
        <li class="c-progress__bar" data-step="1" data-state="is-completed">
          <span class="u-sr-only">Step 1 of 5: Completed</span>
        </li>
        <li class="c-progress__bar" data-step="2" data-state="is-current" aria-current="step">
          <span class="u-sr-only">Step 2 of 5: Summary text explaining this step to the user</span>
        </li>
        <li class="c-progress__bar" data-step="3" data-state="">
          <span class="u-sr-only">Step 3 of 5: Not completed</span>
        </li>
        <li class="c-progress__bar" data-step="4" data-state="">
          <span class="u-sr-only">Step 4 of 5: Not completed</span>
        </li>
        <li class="c-progress__bar" data-step="5" data-state="">
          <span class="u-sr-only">Step 5 of 5: Not completed</span>
        </li>
      </ol>
    </div>

 */
.c-progress--stepper {
  list-style: none;
  margin: 0;
  padding: 0;
  justify-content: center;
  background-color: transparent;
  border-radius: 0;
  box-shadow: none;

  .c-progress__bar {
    width: 20%;
    max-width: var(--aura-c-progress-bar-stepper-max-width, $progress-bar-stepper-max-width);
    margin: 0 var(--aura-c-progress-bar-stepper-margin-inline, $spacing-tiny);
    background-color: var(--aura-progress-bar-color-background, $progress-bar-color-background);
    box-shadow: var(--aura-progress-bar-shadow, $progress-bar-shadow);

    &[data-state="is-current"],
    &[data-state="is-completed"] {
      background-color: var(--aura-c-progress-bar-color-fill, $progress-bar-color-fill);
      box-shadow: none;
    }

  }

}


.c-progress__step-link {
  @include a-block();
  @include all-anchor-states() {
    text-decoration: none;
  }
}




/* Style variants
========================================================================== */
// Remove the border-radius when the progress bar is going edge to edge.
.c-progress--blunt {

  &,
  .c-progress__bar {
    --aura-progress-bar-border-radius: 0;
  }

}



/* Colors
========================================================================== */
// .c-progress--primary {

//   .c-progress__bar {
//     background: unset;
//     background-color: var(--aura-c-progress-bar-color-gradient-fill, $progress-bar-color-gradient-fill);
//   }

// }
