﻿/* ==========================================================================
   #OPENSANS
   ========================================================================== */
// Load Open Sans @font-face rules if it is the chosen font-family for the theme.
// Font-family set in theme file or default setting in `settings.typography`.

$fonts-folder: "../fonts/" !default;
$open-sans-folder: "open-sans/";

@mixin load-opensans-fonts {
  @font-face {
    font-family: 'Open Sans';
    src: url($fonts-folder + $open-sans-folder + 'OpenSans-Light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Open Sans';
    src: url($fonts-folder + $open-sans-folder + 'OpenSans-LightItalic-webfont.woff') format('woff');
    font-weight: 300;
    font-style: italic;
  }

  @font-face {
    font-family: 'Open Sans';
    src: url($fonts-folder + $open-sans-folder + 'OpenSans-Regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Open Sans';
    src: url($fonts-folder + $open-sans-folder + 'OpenSans-Italic-webfont.woff') format('woff');
    font-weight: 400;
    font-style: italic;
  }

  @font-face {
    font-family: 'Open Sans';
    src: url($fonts-folder + $open-sans-folder + 'OpenSans-Semibold-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'Open Sans';
    src: url($fonts-folder + $open-sans-folder + 'OpenSans-SemiboldItalic-webfont.woff') format('woff');
    font-weight: 600;
    font-style: italic;
  }

  @font-face {
    font-family: 'Open Sans';
    src: url($fonts-folder + $open-sans-folder + 'OpenSans-Bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Open Sans';
    src: url($fonts-folder + $open-sans-folder + 'OpenSans-BoldItalic-webfont.woff') format('woff');
    font-weight: 700;
    font-style: italic;
  }

  @font-face {
    font-family: 'Open Sans';
    src: url($fonts-folder + $open-sans-folder + 'OpenSans-ExtraBold-webfont.woff') format('woff');
    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: 'Open Sans';
    src: url($fonts-folder + $open-sans-folder + 'OpenSans-ExtraBoldItalic-webfont.woff') format('woff');
    font-weight: 800;
    font-style: italic;
  }
}