/* ========================================================================
   #BREAKPOINTS
   ======================================================================== */
/**
 * Breakpoint tokens

  type: meida-query
  category: breakpoint

 */

/**
 * ATTENTION
 *
 * Usage:
 * Until the design token pipeline is in place, find the file
 *  `_themes.custom-tokens.scss` 
 * in the themes folder and rename it to 
 *  `_themes.custom-{product-name}-tokens.scss`
 *
 * You will use this file to override !default token values as needed.
 */


/**
 * DO NOT EDIT ANYTHING BELOW 
 * Override default token values in YOUR custom tokens file.
 */

/**
 * A list of breakpoint sizes to use in media queries.
 * Use the ones you need as defaults in the $breakpoints array for sass-mq.
 * Others can be used as custom breakpoints in any media query declaration.
 */

// Mobile, portrait
$bp-320:    320px;      // default - mobile first, iPhone SE, small phones
$bp-375:    375px;      // iPhone 6, 7, 8, X
$bp-414:    414px;      // iPhone 8 Plus

// Mobile, landscape
$bp-568:    568px;      // iPhone SE
$bp-667:    667px;      // iPhone 6, 7, 8
$bp-736:    736px;      // iPhone 8 Plus
$bp-812:    812px;      // iPhone X

// Tablet, portrait
$bp-768:    768px;      // iPad Air, iPad Mini, iPad Pro 9"
$bp-834:    834px;      // iPad Pro 10"

// Tablet, landscape
$bp-992:    992px;      // desktop - Starting point for landscape layouts, so we call it `desktop`
$bp-1024:   1024px;     // iPad Air, iPad Mini, iPad Pro 9", iPad Pro 12" (portrait)
$bp-1112:   1112px;     // iPad Pro 10"

// Laptop displays
$bp-1200:   1200px;     // wide - Starting point for laptops and up
$bp-1366:   1366px;     // HD laptops (768p), iPad Pro 12" (landscape)
$bp-1440:   1440px;     // 13" MacBook Pro (2x scaling)

// Desktop displays
$bp-1600:   1600px;     // x-wide - wide enough to remove the left/right gutter on
// the `.o-wrapper` element if your max-width is 1440px if you want.
// Set $remove-gutter to true in `/objects/objects.wrapper.scss`
$bp-1680:   1680px;     // 13" MacBook Pro (1.5x scaling)
$bp-1740:   1740px;     // xx-wide - 
$bp-1920:   1920px;     // 1080p displays
