/* ==========================================================================
   #INDICATORS
   ========================================================================== */

/**
 * Some round dots that you use to indicate the status of something.

    <span class="c-indicator c-indicator--normal">Normal</span>

 */

.c-indicator {
  display: inline-block;
  border-radius: var(--aura-c-indicator-border-radius, $border-radius-circle);
  width:  var(--aura-c-indicator-size, 10px);
  height: var(--aura-c-indicator-size, 10px);
  @include hide-text();
}

.c-indicator--normal {
  background-color: var(--aura-c-indicator-normal-color-background, $color-background-success);
}

.c-indicator--high {
  background-color: var(--aura-c-indicator-high-color-background, $color-background-warning);
}

.c-indicator--critical {
  background-color: var(--aura-c-indicator-critical-color-background, $color-background-danger);
}