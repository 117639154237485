/* ========================================================================
   #FONT-SIZE
   ======================================================================== */
/**
 * Tokens for font-sizes.

  category: font-size
  type: font-size
  cssProperties:
  - font
  - font-size

 */

/**
 * ATTENTION
 *
 * Usage:
 * Until the design token pipeline is in place, find the file
 *  `_themes.custom-tokens.scss` 
 * in the themes folder and rename it to 
 *  `_themes.custom-{product-name}-tokens.scss`
 *
 * You will use this file to override !default token values as needed.
 */


/**
 * DO NOT EDIT ANYTHING BELOW 
 * Override default token values in YOUR custom tokens file.
 */
// Heading font sizes
$font-size-h1:              50px !default;
$font-size-h2:              36px !default;
$font-size-h3:              28px !default;
$font-size-h4:              24px !default;
$font-size-h5:              20px !default;
$font-size-h6:              17px !default;


// UI font sizes
$font-size-site-nav:        16px !default;
$font-size-body:            16px !default;
$font-size-button:          16px !default;
$font-size-tooltip:         14px !default;
$font-size-form-label:      16px !default;
$font-size-form-hint:       14px !default;
$font-size-form-error:      14px !default;
$font-size-small:           14px !default;
$font-size-smaller:         12px !default;
$font-size-badge:           11px !default;
$font-size-caption:         12px !default;
$font-size-chip:            12px !default;
$font-size-footer:          14px !default;
$font-size-table:           14px !default;
$font-size-toast:           16px !default;
$font-size-input:           21px !default;
$font-size-input-mobile:    18px !default;
$font-size-larger:          22px !default;

$font-size-page-heading:    $font-size-h1 !default;
$font-size-card-heading:    $font-size-h5 !default;

// Line heights
$line-height-heading:       1.25 !default;
$line-height-text:          1.5 !default;
