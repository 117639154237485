/* ==========================================================================
   #SPACINGS
   ========================================================================== */

/**
 * Utility classes to put specific spacing values onto elements. The below loop
 * will generate us a suite of classes like:
 *
 *   .u-margin-top {}
 *   .u-padding-left-large {}
 *   .u-margin-right-small {}
 *   .u-padding {}
 *   .u-padding-right-none {}
 *   .u-padding-horizontal {}
 *   .u-padding-vertical-small {}
 */


@each $property-namespace, $property in $global-spacing-properties {

  @each $direction-namespace, $direction-rules in $global-spacing-directions {

    @each $size-namespace, $size in $global-spacing-sizes {

      .u-#{$property-namespace}#{$direction-namespace}#{$size-namespace} {

        @each $direction in $direction-rules {
          #{$property}#{$direction}: $size !important;
        }

      }

    }

  }

}
