/* ==========================================================================
   #VISIBILITY
   ========================================================================== */
/**
 * Utility classes to put specific visibility values onto elements. The below loops
 * will generate us a suite of classes from the active breakpoint values found in
 * the $breakpoints map in the auracss.scss file, such as:
 *
 *   .u-show-only-@mobile {}
 *   .u-show-only-@mini {}
 *   .u-hide-only-@tablet {}
 *   .u-show-only-@desktop {}
 *   .u-show-@x-wide {}
 *   .u-show-below-xx-wide {}
 *   .u-hide-below-desktop{}
 *
 */


/* Show element only at a specific breakpoint but not before or after.
   ========================================================================== */
/**
 * 1. Get the current index as we iterate through the list of breakpoints so we 
 *    can use it to check for first and last, and to get the next breakpoint up 
 *    in the list.
 * 2. Set $next to the current breakpoint by default, so we only need to change
 *    it until we hit the last item in the list.
 * 3. Get the next breakpoint up from the current breakpoint unless we're at the 
 *    end of the list
 * 4. For the first breakpoint, we only need to set a media query for 
 *    current to next, and for next.
 * 5. For the last breakpoint we only to set a media query for until we hit that 
 *    breakpoint and from that breakpoint.
 * 6. Set the until, to, and from media queries for the breakpoints in between 
 *    first and last.
 */
$use-show-only: $bp-key-names !default;

@if ($use-show-only != null) {
  .u-show-only {
    @each $bp-name in $use-show-only {
      $index:   index($use-show-only, $bp-name); /* [1] */
      $last: 	  length($use-show-only);
      $next-bp: $index + 1;
      $next: 	  $bp-name; /* [2] */
      
      /* [3] */ 
      @if $index < $last {
        $next: nth($use-show-only, $next-bp);
      }

      @if ($index == 1) { /* [4] */
        &#{$bp-separator}#{$bp-name} {
          @include mq($bp-name, $next) {
            display: inherit !important;
          }
          @include mq($from: $next) {
            display: none !important;
          }
        }
      } @else if ($index == $last) { /* [5] */
        &#{$bp-separator}#{$bp-name} {
          @include mq($until: $bp-name) {
            display: none !important;
          }
          @include mq($from: $bp-name) {
            display: inherit !important;
          }
        }
      } @else { /* [6] */
        &#{$bp-separator}#{$bp-name} {
          @include mq($until: $bp-name) {
            display: none !important;
          }
          @include mq($bp-name, $next) {
            display: inherit !important;
          }
          @include mq($from: $next) {
            display: none !important;
          }
        }
      }
    }
  }
}


/* Hide element only at specific breakpoint.
   ========================================================================== */
$use-hide-only: $bp-key-names !default;

@if ($use-hide-only != null) {
  .u-hide-only {
    @each $bp-name in $use-hide-only {
      $index:   index($use-hide-only, $bp-name); /* [1] */
      $last: 	  length($use-hide-only);
      $next-bp: $index + 1;
      $next: 	  $bp-name; /* [2] */

      /* [3] */ 
      @if $index < $last {
        $next: nth($use-hide-only, $next-bp);
      }

      @if ($index == 1) { /* [4] */
        &#{$bp-separator}#{$bp-name} {
          @include mq($bp-name, $next) {
            display: none !important;
          }
          @include mq($from: $next) {
            display: inherit !important;
          }
        }
      } @else if ($index == $last) { /* [5] */
        &#{$bp-separator}#{$bp-name} {
          @include mq($until: $bp-name) {
            display: inherit !important;
          }
          @include mq($from: $bp-name) {
            display: none !important;
          }
        }
      } @else { /* [6] */
        &#{$bp-separator}#{$bp-name} {
          @include mq($until: $bp-name) {
            display: inherit !important;
          }
          @include mq($bp-name, $next) {
            display: none !important;
          }
          @include mq($from: $next) {
            display: inherit !important;
          }
        }
      }
    }
  }
}




/* Show element at a specific breakpoint and beyond.
   ========================================================================== */
$use-show-at: $bp-key-names !default;

@if ($use-show-at != null) {
  .u-show {
    @each $bp-name in $use-show-at {
      $index: index($use-show-at, $bp-name); /* [1] */
      $is-mobile: nth($use-show-at, $index);

      // We dont need this class for the first breakpoint.
      @if ($is-mobile != mobile) {
        &#{$bp-separator}#{$bp-name} {
          @include mq($until: $bp-name) {
            display: none !important;
          }
          @include mq($from: $bp-name) {
            display: inherit !important;
          }
        }
      }
    }
  }
}



/* Show element below a specific breakpoint only.
   ========================================================================== */
$use-show-below: $bp-key-names !default;

@if ($use-show-below != null) {
  .u-show-below {
    @each $bp-name in $use-show-below {
      $index: index($use-show-below, $bp-name); /* [1] */
      $is-mobile: nth($use-show-below, $index);

      // We dont need this class for the first breakpoint.
      @if ($is-mobile != mobile) {
        &-#{$bp-name} {
          @include mq($until: $bp-name) {
            display: inherit !important;
          }
          @include mq($from: $bp-name) {
            display: none !important;
          }
        }
      }
    }
  }
}



/* Hide element below specific breakpoint.
   ========================================================================== */
$use-hide-below: $bp-key-names !default;

@if ($use-hide-below != null) {
  .u-hide-below {
    @each $bp-name in $use-hide-below {
      $index: index($use-hide-below, $bp-name); /* [1] */
      $is-mobile: nth($use-hide-below, $index);

      // We dont need this class for the first breakpoint.
      @if ($is-mobile != mobile) {
        &-#{$bp-name} {
          @include mq($until: $bp-name) {
            display: none !important;
          }
        }
      }
    }
  }
}


/* Misc
   ========================================================================== */
// Force an element to be visible. Probably not useful, but whoop, there it is.
.u-show-all {
	display: block !important;
	overflow: visible !important;
}

 
// This is to address [hidden] not being supported in IE9.
[data-hidden="true"] {
	display: none !important;
}

/**
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.u-hidden-visually {
  @include hide-from-screen();
}


/**
 * Hide visually and from screen readers.
 */
.u-hidden {
  display: none !important;
}
