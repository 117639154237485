/* ========================================================================
   #THEME
   ======================================================================== */
/**
 * ATTENTION
 * Theme settings and theme primary/brand color tokens.
 *
 * Usage:
 * Until the design token pipeline is in place, find the file
 *  `_themes.custom-tokens.scss` 
 * in the themes folder and rename it to 
 *  `_themes.custom-{product-name}-tokens.scss`
 *
 * You will use this file to override !default token values as needed.
 */


/**
 * DO NOT EDIT ANYTHING BELOW 
 * Override default token values in YOUR custom tokens file.
 */

$theme-type:            provider !default; // [patient, provider]
$theme-page-width:      1366px !default;  // [1440, 1366]
$theme-is-responsive:   true !default;    // [true, false]


/* Theme colors
   ========================================================================== */
// Primary brand color for the theme.
// Default theme color is the $resmed-brand-005 based color palette.
$theme-primary-color:             $resmed-brand-005-palette-500 !default;
$theme-primary-color-hover:       $resmed-brand-005-palette-700 !default;
$theme-primary-color-active:      $resmed-brand-005-palette-500 !default;
$theme-primary-color-contrast:    $color-white !default;

// Rest of the tonal range
$theme-primary-color-50:          $resmed-brand-005-palette-50  !default;
$theme-primary-color-100:         $resmed-brand-005-palette-100 !default;
$theme-primary-color-200:         $resmed-brand-005-palette-200 !default;
$theme-primary-color-300:         $resmed-brand-005-palette-300 !default;
$theme-primary-color-400:         $resmed-brand-005-palette-400 !default;
$theme-primary-color-600:         $resmed-brand-005-palette-600 !default;
$theme-primary-color-700:         $resmed-brand-005-palette-700 !default;
$theme-primary-color-800:         $resmed-brand-005-palette-800 !default;
$theme-primary-color-900:         $resmed-brand-005-palette-900 !default;


// $theme-primary-color-lightest:       $resmed-brand-005-palette-50  !default;
// $theme-primary-color-lighter:        $resmed-brand-005-palette-100 !default;
// $theme-primary-color-light:          $resmed-brand-005-palette-200 !default;
// $theme-primary-color-:          $resmed-brand-005-palette-300 !default;
// $theme-primary-color-:          $resmed-brand-005-palette-400 !default;

// $theme-primary-color-:          $resmed-brand-005-palette-600 !default;
// $theme-primary-color-dark:           $resmed-brand-005-palette-700 !default;
// $theme-primary-color-darker:         $resmed-brand-005-palette-800 !default;
// $theme-primary-color-darkest:        $resmed-brand-005-palette-900 !default;


