/* Z-Index
   ========================================================================== */
$z-index-deepdive:    -99999 !default;
$z-index-default:     1 !default;
$z-index-dialog:      6000 !default;
$z-index-dropdown:    7000 !default;
$z-index-modal:       9000 !default;
$z-index-overlay:     8000 !default;
$z-index-popup:       5000 !default;
$z-index-spinner:     9050 !default;
$z-index-sticky:      100 !default;
$z-index-toast:       10000 !default;
$z-index-zenith:      99998 !default;