/* ==========================================================================
   #BORDER
   ========================================================================== */
// A few CSS border property utility classes.

/* Borders
   ========================================================================== */
// Solid border class for each direction [ top, right, bottom, left, all ]
// e.g.: .u-border-solid, .u-border-solid-top, .u-border-solid-right
.u-border-solid {
  @each $direction-namespace, $direction-rules in $global-spacing-directions {
    &#{$direction-namespace} {
      border#{$direction-namespace}: $border-width-thin solid $color-border !important;
    }
  }
}




/* Border radii
   ========================================================================== */
// Border radii based on the global units and spacing directions. 
// e.g.: .u-border-radius, .u-border-radius-small, .u-border-radius-large
// .u-border-radius-top, .u-border-radius-top-small, .u-border-radius-right-small
.u-border-radius {
  @each $direction-namespace, $direction-rules in $global-spacing-directions {
    @each $name, $size in $global-spacing-sizes {
      @if ($name != '-flush') {
        &#{$direction-rules}#{$name} {
          @if ($direction-rules == "-top") {
            border-top-left-radius:     $size !important;
            border-top-right-radius:    $size !important;
            border-bottom-left-radius:  0 !important;
            border-bottom-right-radius: 0 !important;
          }
          @if ($direction-rules == "-right") {
            border-top-left-radius:     0 !important;
            border-top-right-radius:    $size!important;
            border-bottom-left-radius:  0 !important;
            border-bottom-right-radius: $size !important;
          }
          @if ($direction-rules == "-bottom") {
            border-top-left-radius:     0 !important;
            border-top-right-radius:    0 !important;
            border-bottom-left-radius:  $size !important;
            border-bottom-right-radius: $size !important;
          }
          @if ($direction-rules == "-left") {
            border-top-left-radius:     $size !important;
            border-top-right-radius:    0 !important;
            border-bottom-left-radius:  $size !important;
            border-bottom-right-radius: 0 !important;
          }
          @else {
            border-radius: $size !important;
          }
        }
      }
    }
  }
}

.u-border-radius-none {
  border-radius: 0 !important;
}
