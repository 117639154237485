/* ========================================================================
   #COLOR
   ======================================================================== */


/* Messaging component colors
   ========================================================================== */
$aura-message-types: primary, secondary, success, warning, danger, error, dark, light !default;

$aura-alerts: (
  primary:     $color-background-primary-messaging,
  secondary:   $color-background-gray-light,
  success:     $color-background-success,
  warning:     $color-background-warning,
  danger:      $color-background-danger,
  error:       $color-background-danger,
  dark:        $color-background-gray-darker,
  light:       $color-background-gray-lighter,
  contrast: (
    primary:   $color-text-primary-contrast,
    secondary: $color-text-default,
    success:   $color-text-success-contrast,
    warning:   $color-text-warning-contrast,
    danger:    $color-text-danger-contrast,
    error:     $color-text-danger-contrast,
    dark:      $color-text-dark-contrast,
    light:     $color-text-light-contrast,
  )
) !default;


// $aura-badge-types: primary, secondary, success, warning, danger, dark, light !default;

$aura-badges: (
  primary:     $color-background-primary-lightest,
  secondary:   $color-background-gray-light,
  success:     $color-background-success-lightest,
  warning:     $color-background-warning-lightest,
  danger:      $color-background-danger-lightest,
  error:       $color-background-danger,
  dark:        $color-background-gray-darker,
  light:       $color-background-gray-lighter,
  contrast: (
    primary:   $badge-color-text-primary,
    secondary: $badge-color-text-secondary,
    success:   $badge-color-text-success,
    warning:   $badge-color-text-warning,
    danger:    $badge-color-text-danger,
    error:     $badge-color-text-error,
    dark:      $badge-color-text-dark,
    light:     $badge-color-text-light,
  )
) !default;




/* Color maps
   ========================================================================== */
// Color maps are useful for creating class names dynamically

// UI colors
$aura-theme-colors: (
  'primary':   $color-background-primary,
  'secondary': $color-background-gray-light,
  'success':   $color-background-success,
  'warning':   $color-background-warning,
  'danger':    $color-background-danger,
) !default;

// Grayscale colors
$aura-grayscale: (
  'gray-900': $resmed-brand-gray-palette-900,
  'gray-800': $resmed-brand-gray-palette-800,
  'gray-700': $resmed-brand-gray-palette-700,
  'gray-600': $resmed-brand-gray-palette-600,
  'gray-500': $resmed-brand-gray-palette-500,
  'gray-400': $resmed-brand-gray-palette-400,
  'gray-300': $resmed-brand-gray-palette-300,
  'gray-200': $resmed-brand-gray-palette-200,
  'gray-100': $resmed-brand-gray-palette-100,
  'gray-50':  $resmed-brand-gray-palette-50,
) !default;

// Neutral gray colors
$aura-neutral: (
  'neutral-00':  $palette-neutral-00, // black
  'neutral-10':  $palette-neutral-10,
  'neutral-15':  $palette-neutral-15,
  'neutral-20':  $palette-neutral-20,
  'neutral-30':  $palette-neutral-30,
  'neutral-40':  $palette-neutral-40,
  'neutral-50':  $palette-neutral-50,
  'neutral-60':  $palette-neutral-60,
  'neutral-65':  $palette-neutral-65,
  'neutral-70':  $palette-neutral-70,
  'neutral-80':  $palette-neutral-80,
  'neutral-90':  $palette-neutral-90,
  'neutral-95':  $palette-neutral-95,
  'neutral-100': $palette-neutral-100, // white
) !default;