/* ==========================================================================
   #WRAPPERS
   ========================================================================== */

$remove-gutter: false !default;

/**
 * Page-level constraining and wrapping elements.
 */
.o-wrapper {
  @include clearfix();
  padding-right: $spacing-default;
  padding-left:  $spacing-default;
  margin-right: auto;
  margin-left:  auto;
  max-width: var(--aura-theme-page-width, $aura-theme-page-width);
}

.o-wrapper {
  &,
  &.o-wrapper--tiny,
  &.o-wrapper--small,
  &.o-wrapper--large,
  &.o-wrapper--huge {
    /**
      * 1. Since the browser viewport is well beyond the max-width of our site,
      *    we can remove the left and right buffer from the `o-wrapper` object
      *    and go full max-width.
      */
    @if ($remove-gutter == true) {
      @include mq($from: x-wide) {
        padding-right: 0; /* [1] */
        padding-left:  0; /* [1] */
      }
    }
  }
}


// No left or right padding ever.
.o-wrapper--flush {
  padding-right: 0 !important;
  padding-left:  0 !important;
}


// Some items inside of an `o-wrapper--flush` container,
// might still need padding @mobile sizes.
.o-wrapper--buffer {

  @include mq($until: desktop) {
    padding-right: $spacing-small;
    padding-left:  $spacing-small;
  }

}


.o-wrapper--full\@mobile {

  @include mq($until: tablet) {
    padding-right: 0;
    padding-left:  0;
  }

}

.o-wrapper--full-width {
  margin-left:  -$spacing-default;
  margin-right: -$spacing-default;
  padding-left:  $spacing-large;
  padding-right: $spacing-large;

  @include mq($from: tablet) {
    width: 100%;
    max-width: 100% !important;
    margin-left: 0;
    margin-right: 0;
  }

  @include mq($from: desktop) {
    padding-left:  $spacing-large + $spacing-default;
    padding-right: $spacing-large + $spacing-default;
  }

}


.o-wrapper--positioning { /* Deprecated class - use .u-position-relative */
  position: relative;
}


/* Size variants
   ========================================================================== */
.o-wrapper--tiny {
  padding-left: $spacing-tiny;
  padding-right: $spacing-tiny;
}

.o-wrapper--small {
  padding-left: $spacing-small;
  padding-right: $spacing-small;
}

.o-wrapper--large {
  padding-left: $spacing-large;
  padding-right: $spacing-large;
}

.o-wrapper--huge {
  padding-left: $spacing-xx-large;
  padding-right: $spacing-xx-large;
}
