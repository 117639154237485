/* ==========================================================================
  #COLOR-FILL
  ========================================================================== */
// Some classes to use our colors as fills.
$fill-theme-colors: $aura-theme-colors !default;
$fill-greyscale-colors: $aura-grayscale !default;
$fill-neutral-colors: $aura-neutral !default;

.u-fill {

  /**
   * Iterate over color maps and autogenerate helper classes.
   */
  @if ($fill-theme-colors != null) {

    @each $name, $value in $fill-theme-colors {

      &--#{$name} {
        background-color: $value !important;
      }

    }

  }

  @if ($fill-greyscale-colors != null) {

    @each $name, $value in $fill-greyscale-colors {

      &--#{$name} {
        background-color: $value !important;
      }

    }

  }

  @if ($fill-neutral-colors != null) {

    @each $name, $value in $fill-neutral-colors {

      &--#{$name} {
        background-color: $value !important;
      }

    }

  }

}