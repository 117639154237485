/* ========================================================================
   #CORE
   ======================================================================== */
////////////////////////////////////////////////////////////////////////////////
//                                                                            //
//                               W A R N I N G                                //
//                                                                            //
////////////////////////////////////////////////////////////////////////////////

/**
 * DO NOT EDIT ANYTHING BELOW 
 * Private/framework-only reassignment.
 */
// @import "../tools/tools.custom-props";

$aura-theme-types: patient, provider;

@if(variable-exists(theme-type)) {

  $this-type: $theme-type;

  @each $type, $this-type in $aura-theme-types {
    @if not index($aura-theme-types, $theme-type) {
      @error "#{$theme-type} is not a valid theme. Expected one of #{$aura-theme-types}.";
    }
  }

}

@if(variable-exists(theme-page-width)) {
  @if (type-of($theme-page-width) != number) {
    @error "#{$theme-page-width} needs to be a number.";
  }
}

$aura-theme-type: if(variable-exists(theme-type), $theme-type, provider) !default;
$aura-theme-is-responsive: if(variable-exists(theme-is-responsive), $theme-is-responsive, true) !default;
$aura-theme-page-width: if(variable-exists(theme-page-width), $theme-page-width, 1440px) !default;

// @debug "$aura-theme-type = #{$aura-theme-type}";
// @debug "$aura-theme-is-responsive = #{$aura-theme-is-responsive}";
// @debug "$aura-theme-page-width = #{$aura-theme-page-width}";

:root {
  --aura-theme-page-width: #{$aura-theme-page-width};
}

$global-baseline:     6px !default;
$global-font-size:    $font-size-body !default;
$global-line-height:  $spacing-default !default;
$use-markup-fix:      false !default;


// At breakpoints which are wider than $theme-page-width, you can remove 
// the left and right page gutters from the `o-wrapper` element by setting 
// this variable to `true` in your custom theme tokens file.
$remove-gutter:       false !default;


// An array of our global spacing suffixes used to auto-generate class names.
$global-spacing-sizes: (
  null:         $spacing-default,
  '-micro':     $spacing-micro,
  '-tiny':      $spacing-tiny,
  '-small':     $spacing-small,
  '-medium':    $spacing-medium,
  '-large':     $spacing-large,
  '-x-large':   $spacing-x-large,
  '-xx-large':  $spacing-xx-large,
  '-none':      0px,
  '-flush':     0px,
) !default;

$global-spacing-directions: (
  null: null,
  "-top": "-top",
  "-right": "-right",
  "-bottom": "-bottom",
  "-left": "-left",
  "-horizontal": "-left" "-right",
  "-vertical": "-top" "-bottom",
) !default;

$global-spacing-properties: (
  "padding": "padding",
  "margin": "margin",
) !default;
