﻿/* ==========================================================================
   #CERN
   ========================================================================== */
// Load Cern @font-face rules if Cern is the chosen font-family for the theme.
// Font-family set in theme file or default setting in `settings.typography`.

$fonts-folder: "../fonts/" !default;
$cern-folder: "cern/";

@mixin load-cern-fonts {
  // 100 - Ultra Light 
  @font-face {
    font-family: 'Cern';
    src:  url($fonts-folder + $cern-folder + 'CernUltraLight-webfont.eot'); /* IE9+ Compat Modes */
    src:  url($fonts-folder + $cern-folder + 'CernUltraLight-webfont.eot?#iefix') format('embedded-opentype'),  /* IE6-IE8 */
          url($fonts-folder + $cern-folder + 'CernUltraLight-webfont.woff') format('woff'), /* Modern browsers */
          url($fonts-folder + $cern-folder + 'CernUltraLight-webfont.ttf') format('truetype'), /* Safari, Android, iOS */
          url($fonts-folder + $cern-folder + 'CernUltraLight-webfont.svg#cern') format('svg'); /* Legacy iOS */
    font-weight: 100;
    font-style: normal;
  }

  // 300 - Light
  @font-face {
    font-family: 'Cern';
    src:  url($fonts-folder + $cern-folder + 'CernLight-webfont.eot');
    src:  url($fonts-folder + $cern-folder + 'CernLight-webfont.eot?#iefix') format('embedded-opentype'),
          url($fonts-folder + $cern-folder + 'CernLight-webfont.woff') format('woff'),
          url($fonts-folder + $cern-folder + 'CernLight-webfont.ttf') format('truetype'),
          url($fonts-folder + $cern-folder + 'CernLight-webfont.svg#cern') format('svg');
    font-weight: 300;
    font-style: normal;
  }

  // 600 - Medium or Normal
  @font-face {
    font-family: 'Cern';
    src:  url($fonts-folder + $cern-folder + 'CernMed-webfont.eot');
    src:  url($fonts-folder + $cern-folder + 'CernMed-webfont.eot?#iefix') format('embedded-opentype'),
          url($fonts-folder + $cern-folder + 'CernMed-webfont.woff') format('woff'),
          url($fonts-folder + $cern-folder + 'CernMed-webfont.ttf') format('truetype'),
          url($fonts-folder + $cern-folder + 'CernMed-webfont.svg#cern') format('svg');
    font-weight: 500;
    font-style: normal;
  }

  // 700 - Bold
  @font-face {
    font-family: 'Cern';
    src:  url($fonts-folder + $cern-folder + 'CernBold-webfont.eot');
    src:  url($fonts-folder + $cern-folder + 'CernBold-webfont.eot?#iefix') format('embedded-opentype'),
          url($fonts-folder + $cern-folder + 'CernBold-webfont.woff') format('woff'),
          url($fonts-folder + $cern-folder + 'CernBold-webfont.ttf') format('truetype'),
          url($fonts-folder + $cern-folder + 'CernBold-webfont.svg#cern') format('svg');
    font-weight: 700;
    font-style: normal;
  }
}